import { Badge, Box, Divider, Flex, Icon, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { RiRoadMapLine } from 'react-icons/ri'
import parseCurrency from '../../../../../../services/utils/parseCurrency'
import { formatRouteDurationToReadable } from '../../../services/format-duration'
import { InitialRouteType } from '../../reducer'
import { RoutePlannerContext } from '../../ShowRoutePlanner'

type InitialRouteProps = {
  route: InitialRouteType
}

export function InitialRoute({ route }: InitialRouteProps): JSX.Element {
  const { state } = useContext(RoutePlannerContext)

  return (
    <>
      <Box
        key={route?.distance}
        bg={state.theme.cardBackground}
        mt={4}
        p={4}
        rounded="md"
        cursor="pointer"
        borderWidth="2px"
        borderColor="orange.500"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="row" alignItems="center">
            <Flex>
              <Icon
                as={RiRoadMapLine}
                bg={state.theme.iconBackground}
                p={2}
                fontSize={40}
                borderRadius="base"
              />
            </Flex>

            <Flex ml={4} flexDirection="column">
              <Text fontWeight="bold">#1 - {route?.distance.toLocaleUpperCase()}</Text>
              <Text fontSize="md" textColor="gray.400">
                {route.duration ? formatRouteDurationToReadable(route.duration) : 'Não informado'}
              </Text>
            </Flex>
          </Flex>

          <Box>
            <Badge fontSize="large" colorScheme="green">
              {route?.toll_value ? parseCurrency(route.toll_value) : 'R$ 0,00'}
            </Badge>
          </Box>
        </Flex>

        {route.tags && route.tags.length > 0 && (
          <Flex flexDirection="column">
            <Divider mt={1} mb={2} />
            <Flex>
              {route.tags.map(tag => (
                <Text mr={2}>
                  <Badge fontSize="12px" colorScheme={tag.color} py={1} px={2}>
                    {tag.label}
                  </Badge>
                </Text>
              ))}
            </Flex>
          </Flex>
        )}
      </Box>
    </>
  )
}
