import { FormattedOneFreight } from '../../../../../services/endpoints/freights'
import { QualpDataResponseType, QualpRoute } from '../../../../../services/endpoints/qualp/postRoutePlanner'

export type RoutePlannerCity = {
  label: string
  value: number
  stateName: string
  uf: string
  lat: number
  lng: number
  cityName: string
}

type Location = {
  lat: number
  lng: number
}

type Form = {
  axes_number?: number
}

export type TagType = {
  label: string
  color: string
}

type RouteFormatted = QualpRoute & {
  tags: TagType[]
}

type QualpDataResponseFormattedType = QualpDataResponseType & {
  routes: RouteFormatted[]
}

type RouteType = {
  id?: number
  route?: RouteFormatted
}

export type InitialRouteType = {
  id: string
  latitude: number
  longitude: number
  provider?: string
  freight_id: string
  toll_value: number
  distance: string
  duration?: string
  stops?: string
  polyline: Location[]
  tags: TagType[]
}

export type RoutePlannerState = {
  freight: FormattedOneFreight
  steps: RoutePlannerCity[]
  qualpResult: QualpDataResponseFormattedType | null
  initialRoute: InitialRouteType | null
  route: RouteType
  form: Form
  theme: {
    cardBackground: string
    iconBackground: string
  }
}

export const initialValueRoutePlannerState: RoutePlannerState = {
  freight: {} as FormattedOneFreight,
  steps: [],
  qualpResult: null,
  route: {},
  initialRoute: null,
  form: {
    axes_number: 0,
  },
  theme: {
    cardBackground: '#fff',
    iconBackground: '#fff',
  },
}
export type RoutePlannerAction =
  | { type: 'set_freight'; payload: FormattedOneFreight }
  | { type: 'set_card_background'; payload: string }
  | { type: 'set_icon_background'; payload: string }
  | { type: 'set_steps'; payload: RoutePlannerCity[] }
  | { type: 'set_qualp_result'; payload: QualpDataResponseFormattedType | null }
  | { type: 'set_form'; payload: Form }
  | { type: 'set_route'; payload: RouteType }
  | { type: 'set_initial_route'; payload: InitialRouteType | null }

export const routePlannerReducer = (
  state: RoutePlannerState,
  action: RoutePlannerAction,
): RoutePlannerState => {
  switch (action.type) {
    case 'set_freight':
      return {
        ...state,
        freight: action.payload,
      }

    case 'set_card_background':
      return {
        ...state,
        theme: {
          ...state.theme,
          cardBackground: action.payload,
        },
      }

    case 'set_icon_background':
      return {
        ...state,
        theme: {
          ...state.theme,
          iconBackground: action.payload,
        },
      }

    case 'set_steps':
      return {
        ...state,
        steps: action.payload,
      }

    case 'set_qualp_result':
      return {
        ...state,
        qualpResult: action.payload,
      }

    case 'set_form':
      return {
        ...state,
        form: {
          ...state.form,
          ...action.payload,
        },
      }

    case 'set_route':
      return {
        ...state,
        route: action.payload,
      }

    case 'set_initial_route':
      return {
        ...state,
        initialRoute: action.payload,
      }

    default:
      return state
  }
}
