import { localStorageKeys } from '../contexts/AuthContext'
import { Authorization } from '../services/types'
import { UserAuthorization } from '../services/types/Authorization'
import { ActionsForModule, Modules } from '../services/types/AuthorizationModulesTypes'

function can<S extends Modules[0]>(module: S, action: ActionsForModule<S>): boolean {
  // Obtemos as permissões do usuário e das roles do localStorage
  const permissionsByUsers: UserAuthorization = JSON.parse(
    localStorage.getItem(localStorageKeys.permissionsByUsers) || '{}',
  )
  const permissionsByRoles: Authorization = JSON.parse(
    localStorage.getItem(localStorageKeys.permissionsByRoles) || '{}',
  )

  // Se o cargo for "super", permite tudo independentemente das permissões do usuário
  if (permissionsByRoles && (permissionsByRoles as any).role === 'super') {
    return true
  }

  // Avaliação das permissões do usuário
  let userDeny = false
  let userAllow = false
  if (permissionsByUsers && Object.keys(permissionsByUsers).length > 0) {
    if (permissionsByUsers.cannot) {
      userDeny = !!permissionsByUsers.cannot.find(
        c => (c.action === action || c.action === '*') && (c.module === module || c.module === '*'),
      )
    }
    if (permissionsByUsers.can) {
      userAllow = permissionsByUsers.can.some(
        c => (c.action === action || c.action === '*') && (c.module === module || c.module === '*'),
      )
    }
  }

  // Avaliação das permissões da role (além da checagem de cargo "super")
  let roleDeny = false
  let roleAllow = false
  if (permissionsByRoles && Object.keys(permissionsByRoles).length > 0) {
    if (permissionsByRoles.cannot) {
      roleDeny = !!permissionsByRoles.cannot.find(
        c => (c.action === action || c.action === '*') && (c.module === module || c.module === '*'),
      )
    }
    if (permissionsByRoles.can) {
      roleAllow = permissionsByRoles.can.some(
        c => (c.action === action || c.action === '*') && (c.module === module || c.module === '*'),
      )
    }
  }

  // Lógica de combinação com prioridade para o usuário:
  // 1. Se o usuário permite, retorna true (mesmo que a role negue)
  if (userAllow) {
    return true
  }
  // 2. Se o usuário nega, retorna false (mesmo que a role permita)
  if (userDeny) {
    return false
  }
  // 3. Se não há permissão explícita do usuário, usa as regras da role
  if (roleDeny) {
    return false
  }
  return roleAllow
}

export function useAbility<S extends Modules[0]>(module: S, action: ActionsForModule<S>): boolean {
  return can(module, action)
}
