import { Box, Flex, FlexProps, Heading, Icon, Text } from '@chakra-ui/react'
import { ElementType } from 'react'

export interface PageHeaderProps extends FlexProps {
  title: string
  rightContent?: React.ReactNode
  icon?: ElementType
}

export const PageHeader = ({ title, rightContent, icon, ...rest }: PageHeaderProps): JSX.Element => {
  return (
    <Flex {...rest} justify="space-between" alignItems="center" gridGap="2" pb="4" pt="4" borderRadius="8">
      <Box>
        <Heading fontSize="xl" fontWeight="medium" pl="2">
          <Flex>
            <Icon as={icon} fontSize="20" mr="4" />
            <Text>{title}</Text>
          </Flex>
        </Heading>
      </Box>
      <Flex {...rest} alignItems="center" gridGap="2">
        {rightContent}
      </Flex>
    </Flex>
  )
}
