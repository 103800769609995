import { Grid, GridItem } from '@chakra-ui/react'
import { DeepMap, FieldError, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form'
import { Autocomplete, Input, SelectOption } from '../../../components/form'
import { getUserOperation } from '../../../services/endpoints/users/getUserOperation'
import { firstFeedbackDate } from './MotoristsFeedbacksReports'

export interface FiltersMotoristsFeedbacksForm {
  analyst?: SelectOption
  initial_date: string
  final_date: string
  freight_number?: string
  page?: number
}

interface FiltersInput {
  getValues?: UseFormGetValues<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  errors: DeepMap<FieldValues, FieldError>
}

export function FiltersMotoristsFeedbacks({ setValue, errors, getValues }: FiltersInput): JSX.Element {
  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="3">
      <GridItem colSpan={[12, 6, 3]}>
        <Input
          min={firstFeedbackDate}
          label="Data inicial"
          name="initial_date"
          type="date"
          error={errors.initial_date}
          initialValue={getValues?.('initial_date')}
          setValue={setValue}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Data final"
          name="final_date"
          type="date"
          setValue={setValue}
          initialValue={getValues?.('final_date')}
          error={errors.final_date}
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Input
          label="Número do frete"
          name="freight_number"
          setValue={setValue}
          initialValue={getValues?.('freight_number')}
          error={errors.freight_number}
          placeholder="Número do frete"
        />
      </GridItem>

      <GridItem colSpan={[12, 6, 3]}>
        <Autocomplete
          name="analyst"
          label="Analista"
          setValue={setValue}
          loadOptions={getUserOperation}
          initialValue={getValues?.('analyst')}
        />
      </GridItem>
    </Grid>
  )
}
