import { Flex, Box, Text, Table, Tbody, Td, Tr, Alert, AlertIcon, Spinner } from '@chakra-ui/react'
import { UseQueryResult } from 'react-query'
import { Link } from 'react-router-dom'
import { Ability } from '../../../../components/ability'
import { GetClientFeedbackResponse } from '../../../../services/endpoints/clients/reportsFeedbacksClient'
import { IMotoristFeedback } from '../../../../services/endpoints/motorists/reportsFeedbacksMotorist'

type FreightsMonitoredProps = {
  bg: string
  countFeedbackMotorist?: UseQueryResult<IMotoristFeedback, unknown>
  countFeedbackClient?: UseQueryResult<GetClientFeedbackResponse, unknown>
}

export function FeedbacksByMotoristAndClientCard({
  countFeedbackMotorist,
  countFeedbackClient,
  bg,
}: FreightsMonitoredProps): JSX.Element {
  return (
    <>
      {countFeedbackMotorist?.isLoading || countFeedbackClient?.isLoading ? (
        <Flex justify="center">
          <Spinner ml={2} />
        </Flex>
      ) : countFeedbackMotorist?.error || countFeedbackClient?.error ? (
        <Alert status="warning">
          <AlertIcon />
          Falha ao carregar dados dos fretes Ativos. Verifique a conexão com a ou entre em contato com a
          equipe de suporte do sistema.
        </Alert>
      ) : (
        (countFeedbackMotorist?.data || countFeedbackClient?.data) && (
          <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md">
            <Flex
              justifyContent="space-between"
              pl="5"
              pr="5"
              mt={1}
              alignItems="baseline"
              mb="4"
              fontWeight="bold"
            >
              <Text fontSize="lg">RELATÓRIOS DE FEEDBACK</Text>
            </Flex>
            <Flex mt="8" justify="flex-end">
              <Table size="sm">
                <Tbody>
                  <Ability module="people" action="list-client-feedbacks">
                    <Tr>
                      <Td as={Link} to="/clients-feedbacks-reports" fontSize="md" color="gray.500">
                        Feedbacks do Cliente
                      </Td>
                      <Td isNumeric>
                        <Text fontSize="md" color="green.500">
                          {countFeedbackClient?.data?.total}
                        </Text>
                      </Td>
                    </Tr>
                  </Ability>

                  <Ability module="people" action="list-nps-motorist">
                    <Tr>
                      <Td>
                        <Text fontSize="md" color="gray.500" as={Link} to="/motorists-feedbacks-reports">
                          Feedbacks do Motorista
                        </Text>
                      </Td>
                      <Td fontSize="md" color="green.500" isNumeric>
                        {countFeedbackMotorist?.data?.data?.total}
                      </Td>
                    </Tr>
                  </Ability>
                </Tbody>
              </Table>
            </Flex>
          </Box>
        )
      )}
    </>
  )
}
