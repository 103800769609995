import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FaRegFile } from 'react-icons/fa'
import { FiExternalLink, FiUpload } from 'react-icons/fi'
import { toastError } from '../../../../config/error/toastError'
import useSearchMotorist from '../../../../hooks/useSearchMotorist'
import { usePostDriversLicenseInformations } from '../../../../services/endpoints/documents/ocr/get-drivers-license-informations'
import { convertPdfToImage } from '../../../../services/endpoints/documents/pdf/post-convert-pdf-to-image'
import { MotoristType, ResponseOcrCnh } from '../../../../services/types'
import { parseBase64ToImage } from '../../../../services/utils/parseImageToBase64'
import { isValidateWidthImage } from '../../../../services/utils/validateWidthImage'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  setDriverLicenseFile,
  setDriverLicenseImg,
  setIsNewImage,
} from '../../../../store/slices/ocrCnhSlice'
import { CNHinformations } from '../components/CNHinformations'

type UploadCnhModalProps = {
  onSaveInformations?: (motorist: Partial<ResponseOcrCnh>) => void
  onMotoristFound?: (motorist: MotoristType) => void // motoristFound
}

const UploadCNHModal = ({ onSaveInformations, onMotoristFound }: UploadCnhModalProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [fileConvertLoading, setFileConvertLoading] = useState(false)
  const [driverLicense, setDriverLicense] = useState<ResponseOcrCnh | null>(null)
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [motoristFound, setMotoristAlreadyFound] = useState<MotoristType | null>()
  const { driverLicenseImg, driverLicenseFile } = useAppSelector(state => state.ocrCnhSlice)
  const borderColor = useColorModeValue('gray.100', 'gray.500')
  const hasDriverLicenseAttachment = driverLicenseFile.name || driverLicenseImg

  const { search, loading } = useSearchMotorist({
    onSuccess: result => {
      if (result.motorist) {
        setMotoristAlreadyFound(result.motorist)
      }
    },
  })

  /**
   * funcao responsavel por mostrar toast
   * @param title
   * @param status
   */

  const showToast = useCallback(
    (title: string, status: 'info' | 'success' | 'error', description = '') => {
      toast({
        title,
        status,
        description,
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
    },
    [toast],
  )

  /**
   * funcao responsavel por converter o pdf para imagem
   * @param file
   *
   * faz a conversao do pdf para imagem e faz o dispatch para o reducer ocrCnh
   * setando a imagem e o arquivo convertido em base64
   *
   */
  const convertPdf = useCallback(
    async (file: File) => {
      setFileConvertLoading(true)
      showToast('Convertendo PDF para imagem', 'info')
      try {
        const resultConvertPdf = await convertPdfToImage({ file, slicePdf: true })
        if (resultConvertPdf) {
          const imageConvertedToFile = await parseBase64ToImage(resultConvertPdf.imageConverted)
          dispatch(setDriverLicenseImg(resultConvertPdf.imageConverted))
          dispatch(setDriverLicenseFile(imageConvertedToFile))
          showToast('Imagem carregada com sucesso!', 'success')
        }
      } catch (error) {
        showToast('Erro ao converter PDF para imagem, por favor, tente novamente', 'error')
      } finally {
        setFileConvertLoading(false)
      }
    },
    [showToast, dispatch],
  )

  const { mutate: postDriverLicenseInformations, isLoading: isDriverLicenseInformationsLoading } =
    usePostDriversLicenseInformations({
      onSuccess: data => {
        setDriverLicense(data)
        search(data.cpf)
      },
      onError: errorReq => {
        toastError({
          error: errorReq,
          toast,
        })
      },
    })

  const handleChangeFile = useCallback(
    async (file: File) => {
      const acceptedFormats = ['pdf', 'jpg', 'jpeg', 'png']
      const fileExtension = file.name.split('.').pop() as string
      if (!acceptedFormats.includes(fileExtension)) {
        showToast('Formato de imagem inválido', 'error')
        return
      }
      if (fileExtension === 'pdf') {
        convertPdf(file)
        return
      }
      isValidateWidthImage(file)
        .then(() => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64Image = reader.result

            dispatch(setDriverLicenseImg(base64Image as string))
            dispatch(setDriverLicenseFile(file))
          }

          reader.readAsDataURL(file)
        })
        .catch(() => {
          showToast(
            'Imagem inválida',
            'error',
            'Imagem pequena, tente carregar uma imagem com pelo menos 800px de largura',
          )
        })
      dispatch(setIsNewImage(true))
    },
    [dispatch, convertPdf, showToast],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/*': ['.pdf', '.jpg', '.jpeg', '.png'] },
    onDrop: acceptedFiles => handleChangeFile(acceptedFiles[0]),
    maxFiles: 1,
  })

  const handleGetDriverLicenseInformations = async () => {
    if (!driverLicenseFile || !driverLicenseImg) {
      showToast('Selecione uma imagem', 'error')
      return
    }
    postDriverLicenseInformations(driverLicenseImg)
  }

  const handleSave = () => {
    if (!hasDriverLicenseAttachment) {
      showToast('Selecione uma imagem', 'error')
      return
    }
    if (motoristFound) {
      onMotoristFound?.(motoristFound)
    }

    if (driverLicense) {
      onSaveInformations?.(driverLicense)
    }
    onClose()
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems={{ base: 'flex-start', md: 'center' }}
      flexDir={{ base: 'column', md: 'row' }}
      bg={hasDriverLicenseAttachment ? 'green.50' : 'red.50'}
      p="2"
      rounded="md"
      border="1px solid"
      borderColor={hasDriverLicenseAttachment ? 'green.500' : 'red.300'}
    >
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader py="2" fontWeight="bold" fontSize="sm">
            {hasDriverLicenseAttachment ? 'Carregar nova CNH' : 'Carregar CNH'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justifyContent="space-between" gridGap="4" flexDir={{ base: 'column', md: 'row' }}>
              <Flex flex={1} flexDir="column" gridGap="2">
                <Flex
                  {...getRootProps()}
                  borderRadius="md"
                  textAlign="center"
                  cursor="pointer"
                  _hover={{ borderColor: 'blue.400' }}
                  transition="border 0.2s"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  height="400px"
                  borderWidth="2px"
                  borderColor={borderColor}
                  borderStyle="dashed"
                  role="group"
                  position="relative"
                >
                  <input {...getInputProps()} />
                  {fileConvertLoading && (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      position="absolute"
                      w="99%"
                      h="99%"
                      mt={2}
                      p={3}
                      rounded="md"
                      sx={{
                        backdropFilter: 'blur(2px)',
                      }}
                    >
                      <Spinner />
                    </Flex>
                  )}
                  {!driverLicenseImg && (
                    <Box fontSize="14px">
                      <Icon as={FiUpload} boxSize={8} mb={4} />
                      <Text size="xs">Solte a CNH aqui ...</Text>
                      <Text size="xs">ou</Text>
                      <Button leftIcon={<FaRegFile />} size="sm" fontWeight="bold">
                        clique para selecionar
                      </Button>
                    </Box>
                  )}
                  <Box fontSize="14px" position="absolute" opacity={0} _groupHover={{ opacity: 1 }}>
                    <Icon as={FiUpload} boxSize={8} mb={4} />
                    <Text size="xs">Solte a CNH aqui ...</Text>
                    <Text size="xs">ou</Text>
                    <Button leftIcon={<FaRegFile />} size="sm" fontWeight="bold">
                      clique para selecionar
                    </Button>
                  </Box>
                  {driverLicenseImg && (
                    <Image
                      _groupHover={{ opacity: 0.1 }}
                      w="300px"
                      h="400px"
                      objectFit="cover"
                      src={driverLicenseImg}
                      alt={driverLicenseImg}
                    />
                  )}
                  {isDragActive && <Text>Solte o arquivo aqui ...</Text>}
                </Flex>
                <Button
                  size="sm"
                  colorScheme="blue"
                  onClick={() => {
                    window.open(driverLicenseImg, '_blank')
                  }}
                  isLoading={isDriverLicenseInformationsLoading}
                  disabled={!driverLicenseImg}
                  leftIcon={<Icon as={FiExternalLink} />}
                >
                  Abrir em nova aba
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={handleGetDriverLicenseInformations}
                  isLoading={isDriverLicenseInformationsLoading}
                  disabled={!driverLicenseImg}
                >
                  Carregar dados via OCR
                </Button>
              </Flex>
              <Box flex={1}>
                <Box p="4" mb="8" border="1px" borderColor="gray.200" rounded="md">
                  <Heading fontSize="sm">Obter dados da CNH via OCR</Heading>
                  <Text fontSize="sm">Por favor, Verifique se os dados da CNH estão corretos.</Text>
                </Box>
                <Heading fontSize="sm">Informações da CNH via OCR</Heading>
                <SimpleGrid mt="4" gridGap="2" opacity={driverLicense ? 1 : 0.5}>
                  <CNHinformations driverLicense={driverLicense || {}} />
                </SimpleGrid>
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme="gray" variant="outline" onClick={onClose} mr={3}>
              Fechar
            </Button>
            <Button
              size="sm"
              colorScheme="green"
              variant="solid"
              disabled={!hasDriverLicenseAttachment}
              onClick={handleSave}
              isLoading={loading}
            >
              {driverLicense ? 'Atualizar dados do formulário' : 'OK'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex
        gridGap="2"
        alignItems={{ base: 'flex-start', md: 'flex-end' }}
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="2"
          w="100px"
          h="150px"
          bg={hasDriverLicenseAttachment ? 'green.200' : 'red.100'}
          rounded="md"
          borderColor={hasDriverLicenseAttachment ? 'green.500' : 'red.500'}
        >
          <Image src={driverLicenseImg} alt={driverLicenseImg} w="100%" h="100%" objectFit="cover" />
        </Box>
        <Box flex={1}>
          <Box color="gray.800">
            <Heading fontSize="sm">{hasDriverLicenseAttachment ? 'CNH anexada' : 'CNH não anexada'}</Heading>
            <Text fontSize="sm">Carregue a CNH para obter os dados automaticamente e anexar.</Text>
          </Box>
          <Button
            mt="4"
            size="sm"
            colorScheme={hasDriverLicenseAttachment ? 'green' : 'red'}
            onClick={onOpen}
          >
            {hasDriverLicenseAttachment ? 'Visualizar CNH' : 'Anexar CNH'}
          </Button>
        </Box>
      </Flex>
    </Flex>
  )
}

export default UploadCNHModal
