import { VStack, Alert, Flex, AlertIcon, AlertTitle } from '@chakra-ui/react'
import { ChecklistRiskAnalysisOutOfRangeAlert } from '../../../components/checklist-risk-analysis/ChecklistRiskAnalysisOutOfRangeAlert'
import { AlertCardCostRiskAnalysis } from '../../../components/CostRiskAnalysis/AlertCardCostRiskAnalysis'
import { AlertNoHasVpo } from '../../../components/mdfe-vpo/AlertNoHasVpo'
import { AlertIsNeedTag } from '../../../components/tool-tags/AlertIsNeedTag'
import { AlertAboutVehicleAge } from '../../../components/vehicles/AlertAboutVehicleAge'
import { useCheckCostRiskAnalysis } from '../../../services/endpoints/risk-analysis/cost-risk-analysis/checkCostRiskAnalysis'
import { RiskAnalysisOneFormatted } from '../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { BuonnyOutOfRangeAlert } from '../../freights/show/cards/freight-info/BuonnyOutOfRangeAlert'

type Props = {
  riskAnalysis: RiskAnalysisOneFormatted | null
}
export function RiskAnalysisAlerts({ riskAnalysis }: Props): JSX.Element {
  const { data: checkCostRiskAnalysis } = useCheckCostRiskAnalysis({
    risk_analysis_id: riskAnalysis?.id,
  })
  return (
    <>
      {riskAnalysis && (
        <VStack spacing="4" aria-label="Alertas" align="stretch" my="4">
          {checkCostRiskAnalysis && checkCostRiskAnalysis.length > 0 && (
            <AlertCardCostRiskAnalysis
              risk_manager={checkCostRiskAnalysis[0].cost_risk_analysis_risk_manager}
              data={checkCostRiskAnalysis}
            />
          )}

          <AlertNoHasVpo freight={riskAnalysis?.freight} />

          {riskAnalysis?.vehicle?.release_year && (
            <AlertAboutVehicleAge release_year={Number(riskAnalysis.vehicle.release_year)} />
          )}

          <AlertIsNeedTag vehicle={riskAnalysis?.vehicle} freight={riskAnalysis?.freight} />

          {riskAnalysis.freight?.buonny_value_out_of_range && <BuonnyOutOfRangeAlert />}

          {riskAnalysis.freight.origin_id === riskAnalysis.freight.destination_id && (
            <Alert variant="subtle" status="error" borderRadius="md" justifyContent="space-between" mb={4}>
              <Flex>
                <AlertIcon />
                <AlertTitle mr={2}>
                  Realizar averbação de forma manual. Este frete dispensa a emissão de CTe. Deve circular com
                  a NFe da carga e o frete ser faturado via NFS-e.
                </AlertTitle>
              </Flex>
            </Alert>
          )}

          {riskAnalysis.motorist?.total_freights < 1 && riskAnalysis.status !== 'Aprovado' && (
            <ChecklistRiskAnalysisOutOfRangeAlert />
          )}
        </VStack>
      )}
    </>
  )
}
