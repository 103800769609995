import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input as ChakraInput,
  InputGroup,
  InputProps as ChakraInputProps,
  InputRightElement,
  useColorModeValue,
} from '@chakra-ui/react'
import { forwardRef, ForwardRefRenderFunction, useEffect, useRef } from 'react'
import { FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { MdClear } from 'react-icons/md'

interface InputProps extends ChakraInputProps {
  name: string
  uppercaseFirst?: boolean
  uppercaseAll?: boolean
  label?: string
  setValue?: UseFormSetValue<FieldValues>
  initialValue?: string | number | Date
  error?: FieldError
  clearState?: (value: any | undefined) => void
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    name,
    label,
    setValue,
    initialValue,
    uppercaseFirst = false,
    uppercaseAll,
    error,
    onChange,
    focusBorderColor = 'orange.500',
    bgColor,
    variant = 'outline',
    size = 'lg',
    isDisabled,
    isRequired,
    autoComplete = 'nope',
    _hover,
    clearState,
    ...rest
  },
  ref,
) => {
  const bg = useColorModeValue('white', 'gray.900')
  const inputRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (initialValue) {
      setValue?.(name, initialValue)
      if (inputRef.current) {
        const inputValue = inputRef.current.querySelector('input')
        if (inputValue) {
          inputValue.value = initialValue.toString()
        }
      }
    }
  }, [setValue, name, initialValue])

  return (
    <FormControl isDisabled={isDisabled} ref={inputRef} isInvalid={!!error} isRequired={isRequired}>
      {label && (
        <FormLabel htmlFor={name} _disabled={{ opacity: 0.7 }}>
          {label}
        </FormLabel>
      )}
      <InputGroup size="xs">
        <ChakraInput
          ref={ref}
          id={name}
          name={name}
          focusBorderColor={focusBorderColor}
          bgColor={bgColor || bg}
          variant={variant}
          size={size}
          _hover={_hover || { bgColor: bg }}
          isDisabled={isDisabled}
          _disabled={{
            opacity: 0.7,
            cursor: 'not-allowed',
          }}
          onFocus={e => {
            let { value } = e.target
            if (uppercaseFirst) {
              value = value.toLowerCase().replace(/(?:^|\s)\S/g, a => {
                return a.toUpperCase()
              })
              setValue?.(name, value)
              if (inputRef.current) {
                const inputDOM = inputRef.current.querySelector('input')
                if (inputDOM) {
                  inputDOM.value = value
                }
              }
            }

            if (uppercaseAll) {
              value = value.toUpperCase()
              setValue?.(name, value)
              if (inputRef.current) {
                const inputDOM = inputRef.current.querySelector('input')
                if (inputDOM) {
                  inputDOM.value = value
                }
              }
            }
          }}
          onChange={e => {
            let { value } = e.target
            const { files } = e.target

            if (uppercaseFirst) {
              value = value.toLowerCase().replace(/(?:^|\s)\S/g, a => {
                return a.toUpperCase()
              })
              setValue?.(name, value)
              if (inputRef.current) {
                const inputDOM = inputRef.current.querySelector('input')
                if (inputDOM) {
                  inputDOM.value = value
                }
              }
            }

            if (uppercaseAll) {
              value = value.toUpperCase()
              setValue?.(name, value)
              if (inputRef.current) {
                const inputDOM = inputRef.current.querySelector('input')
                if (inputDOM) {
                  inputDOM.value = value
                }
              }
            }

            if (rest.type === 'file' && files) {
              setValue?.(name, files[0])
            } else {
              const valueToRegister = value || undefined
              setValue?.(name, valueToRegister)
            }

            if (onChange) onChange(e)
          }}
          disableAutocomplete
          disableGoogleAutocomplete
          autoComplete={autoComplete}
          {...rest}
        />
        {!isDisabled && (
          <InputRightElement mt={3} mr={1} cursor={isDisabled ? 'not-allowed' : 'pointer'}>
            <Icon
              fontSize="md"
              onClick={() => {
                setValue?.(name, undefined)
                if (inputRef.current) {
                  const inputDOM = inputRef.current.querySelector('input')
                  if (inputDOM) {
                    inputDOM.value = ''
                  }
                }
                if (clearState) clearState(undefined)
              }}
              as={MdClear}
            />
          </InputRightElement>
        )}
      </InputGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  )
}

export const Input = forwardRef(InputBase)
