import { Button, Divider, Flex, Heading, HStack, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { Layout } from '../../../layout'
import { apiServer } from '../../../services/api'
import { useAppSelector } from '../../../store'
import { setIsQuotation } from '../../../store/slices/freightSlice'
import { FreightAndQuotationForm, FreightFormData } from '../../operations/forms/FreightAndQuotationForm'
import { freightAndQuotationValidateSchema } from '../../operations/forms/FreightAndQuotationValidation'

export function CreateQuotation(): JSX.Element {
  const toast = useToast()
  const history = useHistory()
  const dispatch = useDispatch()
  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(freightAndQuotationValidateSchema),
  })
  const { client_business_group_id } = useAppSelector(state => state.freightSlice)

  const handleCreateQuotation = useCallback<SubmitHandler<FreightFormData>>(
    async data => {
      const quotationFormData = {
        type: data.type,
        transporter: data.transporter,
        client_id: data.client_id?.value,
        client_contact_id:
          !data.client_contact?.switch_add_contact && data.client_contact?.client_contact_id?.value,
        received_at: data.received_at,
        delivered_cargo_at: data.delivered_cargo_at,
        collect_cargo_at: data.collect_cargo_at,
        creator_id: data.creator_id?.value,
        seller_id: data.seller_id?.value,
        origin_id: data.origin_id?.value,
        destination_id: data.destination_id?.value,
        suggested_price: data.suggested_price,
        suggested_price_type: data.suggested_price_type,
        service_price: data.service_price,
        has_toll: Boolean(data.has_toll),
        description: data.description,
        origin_zipcode: data.address_origin?.zipcode,
        origin_address: data.address_origin?.address,
        origin_number: data.address_origin?.address_number,
        origin_district: data.address_origin?.district,
        origin_complement: data.address_origin?.label,
        destination_zipcode: data.address_destination?.zipcode,
        destination_address: data.address_destination?.address,
        destination_number: data.address_destination?.address_number,
        destination_district: data.address_destination?.district,
        destination_complement: data.address_destination?.label,
        origin_coords: data.address_origin?.coords,
        destination_coords: data.address_destination?.coords,
        required_items: data.required_items,
        sector: data.sector,
        vehicle_categories_id: data.vehicle_categories?.map(i => i.value),
        vehicle_bodies_id: data.vehicle_bodies?.map(i => i.value),
        publish_on_fretebras: data.publish_on_fretebras,
        client_contact: data.client_contact?.switch_add_contact && {
          id: data.client_contact?.switch_add_contact ? undefined : data.client_contact_id?.value,
          client_id: data.client_id?.value,
          name: data.client_contact?.name,
          email: data.client_contact?.email,
          phone: data.client_contact?.phone,
          phone2: data.client_contact?.phone2,
          role: data.client_contact?.role,
          client_business_group_id,
        },
        cargo: data.cargo && {
          name: data.cargo.name,
          cargo_category_fretebras_id: data.cargo.cargo_category_fretebras_id,
          width: data.cargo.width,
          height: data.cargo.height,
          depth: data.cargo.depth,
          weight: data.cargo.weight,
          value: data.cargo.value,
          require_tracker: Boolean(data.cargo.require_tracker),
          has_complement: Boolean(data.cargo.has_complement),
        },
      } as FreightFormData

      try {
        const { data: res } = await apiServer.post('/quotations', quotationFormData)
        const { data: newQuotation } = res

        toast({
          title: 'Cotação criada com sucesso!',
          status: 'success',
          position: 'top-right',
        })
        history.push(`/quotations/show/${newQuotation.id}`)
      } catch (error) {
        toastError({ toast, error })
      }
    },
    [client_business_group_id, history, toast],
  )

  useEffect(() => {
    dispatch(setIsQuotation(true))
  }, [dispatch])

  return (
    <Layout hasBackground>
      <Heading size="lg" fontWeight="normal">
        Cadastrar cotação
      </Heading>

      <Divider my="6" />

      <form onSubmit={handleSubmit(handleCreateQuotation)} noValidate>
        <FreightAndQuotationForm setValue={setValue} formState={formState} />

        <Flex mt="8" justify="flex-end">
          <HStack spacing="4">
            <Link to="/quotations">
              <Button>Cancelar</Button>
            </Link>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={formState.isSubmitting}
              onClick={() => {
                // eslint-disable-next-line no-console
                console.log(formState.errors)
              }}
            >
              Salvar
            </Button>
          </HStack>
        </Flex>
      </form>
    </Layout>
  )
}
