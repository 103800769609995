import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { RiCheckLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { AlertNoHasVpo } from '../../../components/mdfe-vpo/AlertNoHasVpo'
import { AlertIsNeedTag } from '../../../components/tool-tags/AlertIsNeedTag'
import { FormattedOneFreight } from '../../../services/endpoints/freights'
import { useHasPendencies } from '../../../services/endpoints/freights/hasPendencies'
import { isFreightWithoutCte } from '../../../services/endpoints/freights/utils/isFreightWithoutCte'
import { useAppDispatch, useAppSelector } from '../../../store'
import {
  sendingInitialRequestMonitoringWhatsAppByZApi,
  setDisabledBtnSendingMsgWhatsAppByZApi,
  setInitialBtnWhatsAppByZApi,
} from '../../../store/slices/freightSlice'

interface FreightProps {
  freight: FormattedOneFreight
}

const FreightAlerts = ({ freight }: FreightProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const toast = useToast()
  const {
    statusHistory,
    waypointsZApi,
    initialBtnWppMonitoringDisabled,
    initialResStatusWppMonitoring,
    btnSendingMsgToMotoristDisabled,
  } = useAppSelector(state => state.freightSlice)

  const { riskAnalysis } = useAppSelector(state => state.riskAnalysisSlice)

  const { data: has_pendencies, isLoading: isLoadingPendencies } = useHasPendencies({
    freight_id: freight?.id,
    motorist_id: String(freight?.motorist_id),
    vehicle_id: String(freight?.vehicle_id),
  })

  useEffect(() => {
    setTimeout(() => {
      dispatch(setInitialBtnWhatsAppByZApi(false))
    }, 30 * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    waypointsZApi,
    initialBtnWppMonitoringDisabled,
    initialResStatusWppMonitoring,
    dispatch,
    freight?.status,
  ])

  useEffect(() => {
    setTimeout(() => {
      dispatch(setDisabledBtnSendingMsgWhatsAppByZApi(false))
    }, 30 * 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, btnSendingMsgToMotoristDisabled])

  return (
    <VStack spacing="4" aria-label="Alertas" align="stretch" mt="2">
      <AlertNoHasVpo freight={freight} />

      <AlertIsNeedTag freight={freight} vehicle={freight.vehicle} />

      {freight.origin_id === freight.destination_id && (
        <Alert status="error" borderRadius="md" variant="top-accent">
          <AlertIcon />
          <Box>
            <AlertTitle mr={2}>Realizar averbação de forma manual.</AlertTitle>
            <AlertDescription>
              Este frete dispensa a emissão de CTe. Deve circular com a NFe da carga e o frete ser faturado
              via NFS-e.
            </AlertDescription>
          </Box>
        </Alert>
      )}

      {isFreightWithoutCte(freight) && (
        <Alert status="error" borderRadius="md" variant="top-accent">
          <Box>
            <AlertIcon />
            <AlertTitle mr={2}>
              Frete sem CTE! O frete só dispensa CTE quando origem e destino são iguais
            </AlertTitle>
          </Box>
        </Alert>
      )}

      {!freight.motorist_id && (
        <Alert status="warning" borderRadius="md" variant="top-accent">
          <AlertIcon />
          <Box>
            <AlertTitle mr={2}>Nenhum motorista vinculado ao frete</AlertTitle>
            <AlertDescription>Selecione um motorista para o frete na aba de motoristas</AlertDescription>
          </Box>
        </Alert>
      )}

      {freight?.status === 'disabled' && (
        <Alert status="error" colorScheme="red" flexDirection="column" borderRadius="md" my="4">
          <Flex mb="3">
            <AlertIcon />
            <Heading fontSize="lg">Frete desativado</Heading>
          </Flex>
          {statusHistory && statusHistory.length > 0 && (
            <SimpleGrid gridGap="5" spacingX="-19" columns={2}>
              <Flex gridGap="1">
                <Text color="gray.500">Desativado em:</Text>
                <Text>{statusHistory[0]?.created_at}</Text>
              </Flex>
              <Flex gridGap="1">
                <Text color="gray.500">Desativado por:</Text>
                <Text>{statusHistory[0]?.user?.name}</Text>
              </Flex>
              <Flex gridGap="1">
                <Text color="gray.500">Motivo:</Text>
                <Text>{statusHistory[0]?.disabled_reason}</Text>
              </Flex>
              <Flex gridGap="1">
                <Text color="gray.500">Descrição:</Text>
                <Text>{statusHistory[0]?.description}</Text>
              </Flex>
            </SimpleGrid>
          )}
        </Alert>
      )}
      {freight?.motorist?.id && (
        <Flex gridGap={2} mb="4" direction={['column']}>
          {isLoadingPendencies ? (
            <Flex justify="center" my="2" gridGap={3}>
              Checando pendências... <Spinner size="md" />
            </Flex>
          ) : has_pendencies ? (
            <Alert
              variant="subtle"
              status="warning"
              borderRadius="md"
              justifyContent="space-between"
              flexDirection={['column', 'row']}
              gridGap="2"
            >
              <Flex direction={['column', 'row']} justify="center">
                <AlertIcon />
                <AlertTitle mr={2}>O Motorista ou veículo possuem pendências!</AlertTitle>
                <AlertDescription>Por favor, complete as informações necessárias.</AlertDescription>
              </Flex>
              <Button
                colorScheme="orange"
                ml="4"
                minW={150}
                size="sm"
                onClick={() => {
                  history.push(
                    `/freights/show/${freight.id}/pendencies?motorist_id=${freight.motorist_id}&vehicle_id=${freight.vehicle_id}`,
                  )
                }}
              >
                Resolver pendências
              </Button>
            </Alert>
          ) : (
            <Alert
              variant="subtle"
              status="success"
              borderRadius="md"
              justifyContent="space-between"
              flexDirection={['column', 'row']}
              gridGap="2"
            >
              <Flex alignItems="center">
                <AlertIcon />
                <AlertTitle mr={2} fontSize="lg">
                  Parabéns. Este frete não possui pendências.
                </AlertTitle>
              </Flex>
              <AlertDescription>
                <Button
                  variant="ghost"
                  colorScheme="green"
                  ml="4"
                  size="sm"
                  leftIcon={<Icon as={RiCheckLine} fontSize="x-large" />}
                  onClick={() => {
                    history.push(
                      `/freights/show/${freight.id}/pendencies?motorist_id=${freight.motorist_id}&vehicle_id=${freight.vehicle_id}`,
                    )
                  }}
                >
                  Verificar
                </Button>
              </AlertDescription>
            </Alert>
          )}

          {/* <Alert variant="subtle" status="info" borderRadius="md" justifyContent="space-between">
                <Flex alignItems="center">
                  <AlertIcon />
                  <AlertTitle mr={2}>Atualize o monitoramento de modo manual</AlertTitle>
                  <AlertDescription>
                    Atualize a localização do motorista de modo manual(Telefone ou Dispositivos) clicando no
                    botão.
                  </AlertDescription>
                </Flex>
                <Tooltip label="O motorista irá receber uma mensagem solicitando e informando que ele precisa enviar a localização dele via WhatsApp.">
                  <Popover
                    closeOnBlur
                    onClose={onCloseCreateManualMonitoring}
                    isOpen={isOpenCreateManualMonitoring}
                    onOpen={onOpenCreateManualMonitoring}
                  >
                    <PopoverTrigger>
                      <Button colorScheme="orange" ml="4" size="sm" minW={150}>
                        Informar localização
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverHeader>Escolha a cidade abaixo:</PopoverHeader>
                        <PopoverCloseButton />
                        <PopoverBody>
                          <CreateManualMonitoring freight={freight} close={onCloseCreateManualMonitoring} />
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Tooltip>
              </Alert> */}

          {freight.status !== 'delivered' && freight.has_monitoring_whatsapp === false && (
            <Alert
              variant="subtle"
              status="info"
              borderRadius="md"
              justifyContent="space-between"
              flexDirection={['column', 'row']}
              gridGap="2"
            >
              <Flex alignItems="center" gridGap="2">
                <AlertIcon />
                <Flex flexDirection={['column']}>
                  <AlertTitle mr={2}>Monitoramento via WhatsApp!</AlertTitle>
                  <AlertDescription>
                    Inicie o monitoramento via WhatsApp com o motorista (O monitoramento será feito pelo
                    WhatsApp do Bot).
                  </AlertDescription>
                </Flex>
              </Flex>
              <Tooltip label="O motorista irá receber uma mensagem solicitando e informando que ele precisa enviar a localização dele via WhatsApp.">
                <Button
                  disabled={initialBtnWppMonitoringDisabled}
                  colorScheme="orange"
                  ml="4"
                  size="sm"
                  minW={150}
                  onClick={() => {
                    dispatch(setInitialBtnWhatsAppByZApi(true))
                    dispatch(sendingInitialRequestMonitoringWhatsAppByZApi(freight?.id))
                    toast({
                      title: 'Sucesso!',
                      description: 'Foi enviado com sucesso a mensagem para o motorista.',
                      status: 'success',
                      position: 'top-right',
                      isClosable: true,
                    })
                  }}
                >
                  {initialBtnWppMonitoringDisabled ? 'Por favor, espere...' : 'Iniciar monitoramento'}
                </Button>
              </Tooltip>
            </Alert>
          )}

          {freight.motorist_id && riskAnalysis && riskAnalysis.status === 'A Checar' && (
            <Alert variant="subtle" status="warning" borderRadius="md" justifyContent="space-between">
              <Flex alignItems="center">
                <AlertIcon />
                <AlertTitle mr={2}>
                  Atenção! Existem pendências de checagem para o motorista vinculado, aguarde o processo de
                  análise finalizar para emitir os documentos!
                </AlertTitle>
              </Flex>
            </Alert>
          )}
        </Flex>
      )}
    </VStack>
  )
}

export default FreightAlerts
