import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import { TextArea } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { usePutBalanceRequest } from '../../../../../services/endpoints/freights/putBalanceRequest'

type BlockMotoristBalanceRequestModalProps = {
  isOpen: boolean
  onClose: () => void
}

type RequestParams = {
  id: string
}

type UpdateBalanceRequestFormData = {
  block_motorist_reason?: string
}

const UpdateBalanceRequestFormSchema = yup.object().shape({
  block_motorist_reason: yup.string().required('Campo obrigatório'),
})

export function BlockMotoristBalanceRequestModal({
  isOpen,
  onClose,
}: BlockMotoristBalanceRequestModalProps): JSX.Element {
  const toast = useToast()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateBalanceRequestFormSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateBalanceRequestStatus, isLoading } = usePutBalanceRequest({
    onSuccess: () => {
      toast({
        title: 'Alterar aqui',
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUpdateBalanceRequest: SubmitHandler<UpdateBalanceRequestFormData> = async data => {
    const uploadFormData = {
      block_motorist_reason: data.block_motorist_reason,
    }

    await updateBalanceRequestStatus({
      id,
      data: uploadFormData,
    })

    onClose()
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(handleUpdateBalanceRequest)} noValidate>
            <Text fontWeight="bold" fontSize="20">
              Deseja realmente adicionar um aviso de restrição ao motorista?
            </Text>
            <Text color="gray.400" mt={2} mb={2}>
              Ao adicionar um aviso de restrição para o motorista, o operador responsável será notificado para
              incluí-lo na lista de restrições.
            </Text>

            <TextArea
              label="Motivo da restrição"
              name="block_motorist_reason"
              error={errors.block_motorist_reason}
              height="120px"
              setValue={setValue}
              isRequired
            />

            <Flex justifyContent="flex-end" mt={2}>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button type="submit" colorScheme="red" isLoading={isLoading}>
                Adicionar aviso
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
