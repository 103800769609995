import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'

export interface ICountFreightsResponse {
  pending_freights: {
    total: number
    pending_freights_service_price: number
    pending_freights_expired: number
    pending_freights_to_match: number
  }
  current_freights: {
    total: number
    phases: {
      in_origin: number
      collecting: number
      ready: number
      on_road: number
      in_destination: number
      delivered: number
    }
    current_freights_service_price: number
    current_freights_expired: number
    current_freights_to_match: number
    current_monitored: number
  }
  finished_freights: {
    total: number
    finished_freights_service_price: number
  }
  aerial_freights: number
  no_show: number
  disabled: number
  monitored_freights: number
}

interface CountFreightsParams {
  initial_date?: string
  final_date?: string
}

export async function fetchCountFreights(params: CountFreightsParams): Promise<ICountFreightsResponse> {
  const { data: res } = await apiServer.get<ResponseApiType<ICountFreightsResponse>>('/count-freights', {
    params: {
      initial_date: `${params.initial_date}T00:00:00`,
      final_date: `${params.final_date}T23:59:59`,
      ...params,
    },
  })
  return res.data
}

export function useGetCountFreights(params: CountFreightsParams): UseQueryResult<ICountFreightsResponse> {
  return useQuery(['count_total_freights', params], () => fetchCountFreights(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
  })
}
