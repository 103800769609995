/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react'
import { BsX } from 'react-icons/bs'
import { RouteAnalysisMap } from '../components/Map'
import { LocationRadiusType } from '../RouteAnalysis'

type SetLocationRadiusModalProps = {
  isOpen: boolean
  onClose: () => void
  locations: LocationRadiusType
  setLocations: React.Dispatch<React.SetStateAction<LocationRadiusType>>
  labelMap: 'origin' | 'destination'
}

export function SetLocationRadiusModal({
  isOpen,
  onClose,
  locations,
  setLocations,
  labelMap,
}: SetLocationRadiusModalProps): JSX.Element {
  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalBody p={4}>
          <Flex alignItems="center" justifyContent="space-between">
            <Heading size="md">
              Raio de pesquisa - Cidade de {labelMap === 'origin' ? 'origem' : 'destino'}
            </Heading>

            <Button size="sm" onClick={onClose} p={2}>
              <Icon fontSize={20} as={BsX} />
            </Button>
          </Flex>

          <Divider my={2} />

          <Flex flexDirection="column">
            <Text color="gray.400" mb={2} display="flex">
              Defina o raio de busca ao redor da cidade selecionada:{' '}
            </Text>

            <Slider
              defaultValue={locations?.[labelMap]?.radius}
              min={10}
              max={100}
              onChange={value => {
                setLocations(prevState => ({
                  ...prevState,
                  [labelMap]: {
                    ...prevState[labelMap],
                    radius: value,
                  },
                }))
              }}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>

              <SliderThumb />
            </Slider>

            <Flex justifyContent="space-between" mt={1}>
              <Text color="gray.400" fontSize="sm">
                10 Km
              </Text>

              <Text color="gray.400" fontSize="md" fontWeight="bold">
                {locations?.[labelMap]?.radius} Km
              </Text>

              <Text color="gray.400" fontSize="sm">
                100 Km
              </Text>
            </Flex>
          </Flex>

          <Flex mt={2}>
            <RouteAnalysisMap
              coordinates={[locations?.[labelMap]?.lat ?? 0, locations?.[labelMap]?.lng ?? 0]}
              radius={locations?.[labelMap]?.radius ?? 0}
            />
          </Flex>

          <Flex justifyContent="flex-end" mt={4}>
            <Button size="sm" onClick={onClose}>
              Fechar
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
