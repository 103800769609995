import { Box, Flex, useColorModeValue } from '@chakra-ui/react'
import { Header } from './Header'
import { SimpleHeader } from './Header/SimpleHeader'
import { Sidebar } from './Sidebar'

interface LayoutProps {
  children: React.ReactNode
  titleSimpleHeader?: string
  hasBackground?: boolean
  isFullScreen?: boolean
  SidebarMobile?: boolean
  isFilters?: boolean
  initialDate?: string
  finalDate?: string
  showSidebar?: boolean
  showHeader?: boolean

  setInitialDate?: (value: React.SetStateAction<string>) => void
  setFinalDate?: (value: React.SetStateAction<string>) => void
}

export function Layout({
  children,
  titleSimpleHeader,
  hasBackground = false,
  isFullScreen = false,
  SidebarMobile = false,
  isFilters = false,
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
  showSidebar = true,
  showHeader = true,
}: LayoutProps): JSX.Element {
  const bg = useColorModeValue('white', 'gray.800')
  const overFlowY = isFullScreen && titleSimpleHeader ? 'scroll' : undefined
  return (
    <Box>
      {!isFullScreen && showHeader ? (
        <Header
          SidebarMobile={SidebarMobile}
          isFullScreen={isFullScreen}
          isFilters={isFilters}
          initialDate={initialDate}
          finalDate={finalDate}
          setInitialDate={setInitialDate}
          setFinalDate={setFinalDate}
        />
      ) : (
        titleSimpleHeader && <SimpleHeader title={titleSimpleHeader} />
      )}

      <Flex
        position={isFullScreen ? 'fixed' : undefined}
        w="100%"
        h={isFullScreen ? '96.5%' : undefined}
        my={['4', '6']}
        maxW={!isFullScreen ? 1420 : undefined}
        mx="auto"
        px={isFullScreen ? undefined : ['4', '6']}
        overflowX={isFullScreen ? 'visible' : undefined}
        overflowY={overFlowY}
      >
        {showSidebar && <Sidebar SidebarMobile={SidebarMobile} />}

        <Box
          flex="1"
          w="100%"
          h="100%"
          borderRadius={8}
          bg={hasBackground ? bg : undefined}
          p={hasBackground ? ['6', '8'] : undefined}
          shadow={hasBackground ? 'md' : undefined}
        >
          {children}
        </Box>
      </Flex>
    </Box>
  )
}
