import { Alert, AlertIcon, AlertTitle } from '@chakra-ui/react'
import { useAppSelector } from '../../../../store'
import { SendToBuonny } from '../components/send-to-buonny/SendToBuonny'
import { DrawerWrapper } from '../components/DrawerWrapper'

type AnttModalProps = {
  isOpen: boolean
  onClose: () => void
}

const BuonnyModalForm = ({ isOpen, onClose }: AnttModalProps): JSX.Element => {
  const { freight, motorist, vehicle } = useAppSelector(state => state.freightPendenciesSlice)
  return (
    <>
      <DrawerWrapper
        isOpen={isOpen}
        onForceClose={onClose}
        onClose={onClose}
        title="Criar análise de risco (Enviar dados para GR)"
        onSaveLabel="Ok"
        hiddenSaveButton
      >
        {!freight ||
          !motorist ||
          (!vehicle && (
            <Alert variant="subtle" status="error" borderRadius="md">
              <AlertIcon />
              <AlertTitle mr={2} fontSize="lg">
                existe algumas informações pendentes para serem sincronizadas e envias para o Buonny.
              </AlertTitle>
            </Alert>
          ))}
        {freight && motorist && vehicle && (
          <SendToBuonny tabIndex={0} freight={freight} motorist={motorist} vehicle={vehicle} />
        )}
      </DrawerWrapper>
    </>
  )
}

export default BuonnyModalForm
