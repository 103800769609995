import { Button, Flex, Heading, Icon, Tag, Text } from '@chakra-ui/react'
import { RiArrowLeftLine } from 'react-icons/ri'
import { Link, useHistory } from 'react-router-dom'

type HeaderProps = {
  freightId?: string
  freightNumber?: string

  motoristId?: string
  vehicleId?: string
}

export const Header = ({ freightId, freightNumber, motoristId, vehicleId }: HeaderProps): JSX.Element => {
  const history = useHistory()
  return (
    <Flex justify="space-between" gridGap="4" flexDir={['column', 'column', 'row', 'row']}>
      <div>
        <Heading fontSize="lg">
          Acompanhamento de cadastros Frete{' '}
          <Tag size="md" colorScheme="green">
            #{freightNumber}
          </Tag>
        </Heading>
        <Text fontSize="small" color="gray.500">
          Etapas necessárias para o frete.
        </Text>
      </div>
      <Flex gridGap="2">
        <Button
          as={Link}
          to={`/freights/show/${freightId}`}
          leftIcon={<Icon as={RiArrowLeftLine} fontSize="2xl" />}
          size="sm"
          colorScheme="green"
        >
          Ver detalhes do frete
        </Button>
        <Button
          variant="outline"
          size="sm"
          colorScheme="blue"
          onClick={() => {
            history.push(
              `/freights/v2/show/${freightId}/pendencies?motorist_id=${motoristId}&vehicle_id=${vehicleId}`,
            )
          }}
        >
          Fluxo antigo
        </Button>
      </Flex>
    </Flex>
  )
}
