/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Thead,
  Table,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Tooltip,
  useColorModeValue,
  Text,
  Flex,
  Spinner,
  useToast,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { toastError } from '../../../../../../config/error/toastError'
import { Pagination } from '../../../../../../layout/Pagination'
import {
  useGetAllCteIssuanceRequest,
  FilterCteIssuanceRequestsType,
} from '../../../../../../services/endpoints/freights/GetAllCteIssuanceRequest'
import { cteIssuanceRequestStatusLegend } from '../../../../../../services/utils/cteIssuanceRequestStatusLegend'
import { getCteIssuanceRequestColorByStatus } from '../../../../../../services/utils/getCteIssuanceRequestColorByStatus'

type CteIssuanceRequestTableListProps = {
  handleChangePage?: (newPage: number) => void
  filters?: FilterCteIssuanceRequestsType
  initialDate?: string | undefined
  finalDate?: string | undefined
  hasPagination?: boolean
}

export function CteIssuanceRequestTableList({
  handleChangePage,
  filters,
  initialDate,
  finalDate,
  hasPagination,
}: CteIssuanceRequestTableListProps): JSX.Element {
  const toast = useToast()
  const hoverBgLineTable = useColorModeValue('gray.200', 'gray.700')

  const { data, isLoading, isError, error } = useGetAllCteIssuanceRequest({
    initial_date: filters?.initial_date || initialDate,
    final_date: filters?.final_date || finalDate,
    ...filters,
  })

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  return (
    <>
      {!isLoading ? (
        data && data.data && data.data.length > 0 ? (
          <>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Frete</Th>
                  <Th>Status</Th>
                  <Th>Motorista</Th>
                  <Th>Reponsável</Th>
                  <Th>Data</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.data.map(cteIssuanceRequest => (
                  <Tr
                    key={cteIssuanceRequest.id}
                    cursor="pointer"
                    _hover={{ bg: hoverBgLineTable, transition: '0.3s' }}
                    onClick={() => {
                      window.open(`/cte-issuance-requests/show/${cteIssuanceRequest.id}`, '_blank')?.focus()
                    }}
                  >
                    <Td>#{cteIssuanceRequest?.freight?.freight_number}</Td>
                    <Td>
                      <Tooltip
                        label={
                          cteIssuanceRequest.reject_reason ||
                          cteIssuanceRequestStatusLegend(cteIssuanceRequest.status)
                        }
                        hasArrow
                        placement="top"
                        arrowSize={15}
                      >
                        <Badge
                          px="2"
                          py="1"
                          colorScheme={getCteIssuanceRequestColorByStatus(cteIssuanceRequest.status)}
                          w="100%"
                          textAlign="center"
                        >
                          {cteIssuanceRequest.formatted_status}
                        </Badge>
                      </Tooltip>
                    </Td>
                    <Td>{cteIssuanceRequest.motorist?.name}</Td>
                    <Td>{cteIssuanceRequest.creator?.name}</Td>
                    <Td>{cteIssuanceRequest.created_at}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {hasPagination && handleChangePage && (
              <Pagination
                currentPage={filters?.page || 1}
                totalCountOfRegisters={data?.total ?? 0}
                registersPerPage={15}
                onPageChange={handleChangePage}
                pt="4"
                pb="1"
              />
            )}
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar as solicitações de emissão de CTe</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de emissão de CTe encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}
    </>
  )
}
