import { AutocompleteOption } from '../../../../components/form'
import { apiServer } from '../../../api'
import { FreightType, ResponseApiType, TollTagsTypes, VehicleType } from '../../../types'
import { FormattedOneFreight } from '../../freights'
import { VehicleResponseFormData } from '../getVehicle'

export type InputIsNeedTag = {
  vehicle: VehicleType | VehicleResponseFormData | null | undefined
  freight: FormattedOneFreight | FreightType | undefined
}
export const isNeedTag = (input: InputIsNeedTag): boolean => {
  return !!input.vehicle?.toll_tags && input.vehicle?.toll_tags.length < 1 && !!input.freight?.toll_value
}

export const formatBrandTags = (brand: string): string => {
  return brand
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export async function tollTagsAutocomplete(brand?: string): Promise<AutocompleteOption[]> {
  const { data: res } = await apiServer.get<ResponseApiType<TollTagsTypes[]>>('/vehicles/toll-tags', {
    params: {
      brand,
    },
  })

  return res.data.map(tag => {
    const label = formatBrandTags(tag.brand)
    const value = tag.id

    return { label, value }
  })
}
