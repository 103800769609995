import { Badge, Box, Button, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react'
import { useContext } from 'react'
import { BiSolidFlagCheckered, BiTrash } from 'react-icons/bi'
import { IoLocationOutline } from 'react-icons/io5'
import { TbBusStop } from 'react-icons/tb'
import { CityType } from '../../../../../../services/types'
import { RoutePlannerCity } from '../../reducer'
import { RoutePlannerContext } from '../../ShowRoutePlanner'

type TimelineStepCityProps = {
  label: 'origin' | 'destination' | 'step'
  city?: CityType
  stepCity?: RoutePlannerCity
}

export function TimelineStepCity({ label, city, stepCity }: TimelineStepCityProps): JSX.Element {
  const { state, dispatch } = useContext(RoutePlannerContext)
  const anyBG = useColorModeValue('gray.100', 'gray.700')

  const icon =
    label === 'origin' ? IoLocationOutline : label === 'destination' ? BiSolidFlagCheckered : TbBusStop

  function handleDeleteCity(value: number): void {
    dispatch({
      type: 'set_steps',
      payload: state.steps.filter(step => step.value !== value),
    })
  }

  return (
    <Flex
      position="relative"
      alignItems={label === 'origin' ? 'start' : label === 'destination' ? 'end' : 'center'}
      ml={1}
      rounded="md"
    >
      <Box position="absolute" left="-30px">
        <Icon as={icon} bg={state.theme.iconBackground} p={1} fontSize={25} borderRadius="base" />
      </Box>
      <Flex
        gridGap="2"
        w="100%"
        justifyContent="space-between"
        _hover={{ bg: label === 'step' && !state.qualpResult && !state.initialRoute && anyBG }}
        borderRadius="md"
      >
        <Box ml={2}>
          <Badge
            colorScheme={label === 'origin' ? 'green' : label === 'destination' ? 'blue' : 'yellow'}
            px="1"
          >
            {label === 'origin' ? 'Origem' : label === 'destination' ? 'Destino' : 'Parada'}
          </Badge>

          <Text fontSize="md" fontWeight="bold">
            {city ? city.name : stepCity ? stepCity.cityName : 'Não informado'} -{' '}
            {city ? city.state.uf : stepCity ? stepCity.uf : ' '}
          </Text>
        </Box>

        {stepCity && label === 'step' && !state.qualpResult && !state.initialRoute && (
          <Flex alignItems="center" mr={2}>
            <Button size="sm" colorScheme="red" onClick={() => handleDeleteCity(stepCity.value)}>
              <Icon as={BiTrash} borderRadius="base" />
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
