import { Alert, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react'
import { calcValueVpo } from '../../services/endpoints/truckpad/getFreightsTolls'
import { FreightType } from '../../services/types'

type Props = {
  freight: FreightType | undefined
}
export function AlertNoHasVpo({ freight }: Props): JSX.Element {
  if (!freight) return <></>
  const vpoInfo = calcValueVpo(freight)
  const noHasVpo = vpoInfo === 0 || !vpoInfo

  return (
    <>
      {noHasVpo && (
        <Alert
          variant="subtle"
          status="warning"
          borderRadius="md"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          gridGap="2"
        >
          <Flex alignItems="center">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="lg">
              Atenção: A rota exige pagamento de pedágio, o frete tem MDFE, mas NÃO tem VPO (Vale Pedágio
              Obrigatório).
            </AlertTitle>
          </Flex>
        </Alert>
      )}
    </>
  )
}
