import { MapContainer, TileLayer, useMap, Circle } from 'react-leaflet'
import { LatLngBoundsExpression } from 'leaflet'
import { useEffect } from 'react'

const containerStyle = {
  width: '100%',
  minHeight: '400px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}

type TileLayerCustomProps = {
  bounds: LatLngBoundsExpression
  radius: number
}

type RouteAnalysisMapProps = {
  coordinates: [number, number]
  radius: number
}

function TileLayerCustom({ bounds, radius }: TileLayerCustomProps) {
  const map = useMap()

  function getMapZoom(radiusKm: number): number {
    const referencePoints: { km: number; zoom: number }[] = [
      { km: 100, zoom: 8 },
      { km: 75, zoom: 8.4 },
      { km: 50, zoom: 9 },
      { km: 25, zoom: 10 },
      { km: 10, zoom: 11.3 },
    ]

    referencePoints.sort((a, b) => b.km - a.km)

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < referencePoints.length - 1; i++) {
      const current = referencePoints[i]
      const next = referencePoints[i + 1]

      if (radiusKm <= current.km && radiusKm >= next.km) {
        const ratio = (radiusKm - next.km) / (current.km - next.km)
        return next.zoom + ratio * (current.zoom - next.zoom)
      }
    }

    if (radiusKm >= referencePoints[0].km) {
      const extrapolationRatio = (radiusKm - referencePoints[0].km) / referencePoints[0].km
      return referencePoints[0].zoom - extrapolationRatio * 0.5
    }
    if (radiusKm <= referencePoints[referencePoints.length - 1].km) {
      const extrapolationRatio =
        (referencePoints[referencePoints.length - 1].km - radiusKm) /
        referencePoints[referencePoints.length - 1].km
      return referencePoints[referencePoints.length - 1].zoom + extrapolationRatio * 1
    }

    return 8
  }

  useEffect(() => {
    const maxZoom = getMapZoom(radius)
    map.flyToBounds(bounds, { maxZoom })
  }, [bounds, radius, map])

  return (
    <TileLayer
      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  )
}

export function RouteAnalysisMap({ coordinates, radius }: RouteAnalysisMapProps): JSX.Element {
  return (
    <div style={{ ...containerStyle, backgroundColor: 'white' }}>
      <MapContainer bounds={[coordinates]} boundsOptions={{ maxZoom: 5 }} style={containerStyle}>
        <TileLayerCustom bounds={[coordinates]} radius={radius} />

        <Circle center={coordinates} pathOptions={{ fillColor: 'blue' }} radius={radius * 1000} />
      </MapContainer>
    </div>
  )
}
