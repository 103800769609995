import { Alert, AlertIcon, AlertTitle, Flex } from '@chakra-ui/react'

type Props = {
  release_year: number
}

export function AlertAboutVehicleAge({ release_year }: Props): JSX.Element {
  const currentYear = new Date().getFullYear()
  const ageLimit = 30
  const vehicleAge = currentYear - release_year
  const vehicleAgeIsInvalid = vehicleAge >= ageLimit
  return (
    <>
      {vehicleAgeIsInvalid && (
        <Alert
          variant="subtle"
          status="warning"
          borderRadius="md"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          gridGap="2"
        >
          <Flex alignItems="center">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="lg">
              Atenção: O veículo tem {vehicleAge} anos e precisa de vistoria.
            </AlertTitle>
          </Flex>
        </Alert>
      )}
    </>
  )
}
