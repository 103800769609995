import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { IoCheckmark, IoCloseOutline } from 'react-icons/io5'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useParams } from 'react-router-dom'
import { TextArea } from '../../../../../components/form'
import { AdvanceRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { toastError } from '../../../../../config/error/toastError'
import { usePutAdvanceRequest } from '../../../../../services/endpoints/freights/putAdvanceRequest'

type HandleAdvanceRequestStatusModalProps = {
  isOpen: boolean
  onClose: () => void
  analysisStatus: 'approved' | 'rejected' | 'request_review' | 'finish'
}

type RequestParams = {
  id: string
}

type UpdateAdvanceRequestFormData = {
  status: string
  reject_reason?: string
}

const UpdateAdvanceRequestFormSchema = yup.object().shape({
  status: yup.string(),
  reject_reason: yup.string().when('status', {
    is: AdvanceRequestStatusEnum.REJECTED,
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
})

const modalMessagens = {
  approved: {
    title: 'Deseja realmente aprovar a solicitação de adiantamento?',
    body: 'Se você aprovar, um outro operador responsável irá dar seguemento e realizar o pagamento.',
    message: 'Solicitação de adiantamento aprovada!',
  },
  rejected: {
    title: 'Deseja realmente reprovar a solicitação de adiantamento?',
    body: 'Se você reprovar, o operador responsável pela solicitação de adiantamento irá reavaliar os dados.',
    message: 'Solicitação de adiantamento reprovada!',
  },
  request_review: {
    title: 'Deseja realmente solicitar revisão da solicitação de adiantamento?',
    body: 'Se você solicitar revisão, o operador responsável pela solicitação de adiantamento será notificado e irá reavaliar os dados.',
    message: 'Solicitação de adiantamento solicitada para revisão!',
  },
  finish: {
    title: 'Deseja realmente finalizar a solicitação de adiantamento?',
    body: 'Se a solicitação for finalizada, o mesmo não podera mais ser alterado.',
    message: 'Solicitação de adiantamento finalizada!',
  },
}

export function HandleAdvanceRequestStatusModal({
  isOpen,
  onClose,
  analysisStatus,
}: HandleAdvanceRequestStatusModalProps): JSX.Element {
  const toast = useToast()

  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateAdvanceRequestFormSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateAdvanceRequestStatus, isLoading } = usePutAdvanceRequest({
    onSuccess: () => {
      toast({
        title: modalMessagens[analysisStatus].message,
        status: 'success',
        position: 'top-right',
        isClosable: true,
        duration: 4000,
      })
    },
    onError: error => {
      toastError({ toast, error })
    },
  })

  const handleUpdateAdvanceRequest: SubmitHandler<UpdateAdvanceRequestFormData> = async data => {
    const uploadFormData = {
      status: AdvanceRequestStatusEnum.REJECTED,
      reject_reason: data.status === 'approved' || data.status === 'finish' ? null : data.reject_reason,
    }

    if (data.status === 'approved') {
      Object.assign(uploadFormData, {
        status: AdvanceRequestStatusEnum.AWAITING_PAYMENT,
        is_approved: true,
      })
    }

    if (data.status === 'finish') {
      Object.assign(uploadFormData, {
        status: AdvanceRequestStatusEnum.FINISHED,
      })
    }

    await updateAdvanceRequestStatus({
      id,
      data: uploadFormData,
    })

    onClose()
  }

  return (
    <Modal
      size="2xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(handleUpdateAdvanceRequest)} noValidate>
            <Text fontWeight="bold" fontSize="20">
              {modalMessagens[analysisStatus].title}
            </Text>
            <Text color="gray.400" mt={2} mb={2}>
              {modalMessagens[analysisStatus].body}
            </Text>

            {(analysisStatus === 'rejected' || analysisStatus === 'request_review') && (
              <TextArea
                label="Motivo da reavalição"
                name="reject_reason"
                error={errors.reject_reason}
                height="120px"
                setValue={setValue}
                isRequired
              />
            )}

            <Flex justifyContent="flex-end" mt={2}>
              <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                type="submit"
                colorScheme={analysisStatus === 'approved' || analysisStatus === 'finish' ? 'green' : 'red'}
                onClick={() => {
                  setValue('status', analysisStatus)
                }}
                isLoading={isLoading}
              >
                {analysisStatus === 'approved' ? (
                  <>
                    <Icon fontSize={16} as={IoCheckmark} mr={1} /> Aprovar
                  </>
                ) : analysisStatus === 'finish' ? (
                  <>
                    <Icon fontSize={20} as={IoCheckmark} mr={1} /> Finalizar
                  </>
                ) : (
                  <>
                    <Icon fontSize={20} as={IoCloseOutline} mr={1} /> Reprovar
                  </>
                )}
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
