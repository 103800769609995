import {
  Button,
  Flex,
  Icon,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { CiSearch } from 'react-icons/ci'
import { RiCalendarTodoLine, RiMenuLine } from 'react-icons/ri'
import * as yup from 'yup'
import { useSidebarDrawer } from '../../contexts/SidebarDrawerContext'
import { HeaderSearchBox } from './HeaderSearchBox'
import { Logo } from './Logo'
import { NotificationsNav } from './notifications/NotificationsNav'
import { Profile } from './Profile'

interface HeaderProps {
  SidebarMobile?: boolean
  isFullScreen?: boolean
  isFilters?: boolean
  initialDate?: string
  finalDate?: string
  setInitialDate?: (value: React.SetStateAction<string>) => void
  setFinalDate?: (value: React.SetStateAction<string>) => void
}

type FilterFormData = {
  initial_date: string
  final_date: string
}

export function Header({
  SidebarMobile,
  isFullScreen,
  isFilters,
  initialDate,
  finalDate,
  setInitialDate,
  setFinalDate,
}: HeaderProps): JSX.Element {
  const { onOpen } = useSidebarDrawer()
  const isWideVersion = useBreakpointValue({
    base: false,
    xl: true,
  })
  const isMediumVersion = useBreakpointValue({
    lg: true,
    md: true,
  })
  const isMobileVersion = useBreakpointValue({
    md: true,
  })

  const FilterSchema = yup.object().shape({
    initial_date: yup.string(),
    final_date: yup.string(),
  })

  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(FilterSchema),
  })

  const handleFilter: SubmitHandler<FilterFormData> = async data => {
    if (data.initial_date && setInitialDate) {
      setInitialDate(data.initial_date)
    }

    if (data.final_date && setFinalDate) {
      setFinalDate(data.final_date)
    }
  }

  return (
    <>
      <Flex
        as="header"
        w="100%"
        maxW={!isFullScreen ? 1420 : undefined}
        h="20"
        mx="auto"
        mt={['2', '4']}
        px={['4', '6']}
        alignItems="center"
        justifyContent="space-between"
      >
        {(!isWideVersion || isFilters || SidebarMobile) && (
          <IconButton
            aria-label="Open navigation"
            icon={<Icon as={RiMenuLine} />}
            fontSize="24"
            variant="link"
            mr="2"
            mt="2"
            onClick={onOpen}
          />
        )}
        <Logo />

        <Flex as="form" onSubmit={handleSubmit(handleFilter)} noValidate>
          {isMobileVersion && isMediumVersion && isFilters ? (
            <Flex direction={['column', 'row']} alignItems="center">
              <Text mx="5">De</Text>
              <Input
                mt={['1', '']}
                type="date"
                name="initial_date"
                defaultValue={initialDate?.split('T')[0]}
                onChange={e => {
                  setValue('initial_date', String(e.target.value))
                }}
              />

              <Text mr="4" ml="4">
                Até
              </Text>
              <Input
                mt={['1', '']}
                type="date"
                name="final_date"
                defaultValue={finalDate?.split('T')[0]}
                onChange={e => {
                  setValue('final_date', String(e.target.value))
                }}
              />

              <Button type="submit" mt={1} ml={2}>
                <Icon as={CiSearch} fontSize={20} />
              </Button>
            </Flex>
          ) : (
            !isMobileVersion &&
            !isMediumVersion &&
            isFilters && (
              <Flex ml="2" mr={['10']} mt="1">
                <Popover placement="auto">
                  <PopoverTrigger>
                    <Flex fontSize="sm" cursor="pointer">
                      <RiCalendarTodoLine size="20px" />
                    </Flex>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />

                      <PopoverBody>
                        <Flex direction={['column']} alignItems="center">
                          <Flex w="100%" alignItems="center">
                            <Text mr="5">De</Text>
                            <Input
                              mt={['1', '']}
                              type="date"
                              name="initial_date"
                              defaultValue={initialDate}
                              onChange={e => {
                                setValue('initial_date', String(e.target.value))
                              }}
                            />
                          </Flex>
                          <Flex w="100%" alignItems="center">
                            <Text mr="4">Até</Text>
                            <Input
                              mt={['1', '']}
                              type="date"
                              name="final_date"
                              defaultValue={finalDate}
                              onChange={e => {
                                setValue('final_date', String(e.target.value))
                              }}
                            />
                          </Flex>
                        </Flex>
                        <Flex justify="flex-end">
                          <Button type="submit" mt={1} ml={2}>
                            <Icon as={CiSearch} fontSize={20} />
                          </Button>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Flex>
            )
          )}
        </Flex>

        {isWideVersion && !isFilters && <HeaderSearchBox />}

        <Flex align="center" ml="auto">
          <Flex>
            <NotificationsNav />
          </Flex>
          <Profile />
        </Flex>
      </Flex>
    </>
  )
}
