import { Box, Button, Divider, Flex, Icon, useToast, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { RiUserAddLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { toastError } from '../../../../config/error/toastError'
import { useVehicleCategoriesCombox } from '../../../../hooks/useVehicleCategoriesCombox'
import { VehicleResponseFormData } from '../../../../services/endpoints/vehicles/getVehicle'
import { usePostVehicle, VehiclePayload } from '../../../../services/endpoints/vehicles/postVehicle'
import { useCreateAttachmentToVehicle } from '../../../../services/endpoints/vehicles/vehicle-attachments/createVehicleAttachment'
import { useUpdateAttachmentToVehicle } from '../../../../services/endpoints/vehicles/vehicle-attachments/updateAttachment'
import { ResponseOcrCrlv } from '../../../../services/types'
import { addMoreOneParamToUrl } from '../../../../services/utils/format-url/addMoreOneParamToUrl'
import { alterColorsGenderDatamex } from '../../../../services/utils/vehicleColorsDatamex'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setIsNewImage } from '../../../../store/slices/ocrCnhSlice'
import { setCrlvVehicleFile, setCrlvVehicleImg } from '../../../../store/slices/ocrCrlvSlice'
import { setVehicleCreated, setVehicleFound } from '../../../../store/slices/vehicleSlice'
import {
  VehicleForm,
  vehicleFormInitialValues,
  VehicleFormValues,
  VehicleSchema,
} from '../../../../components/forms/vehicle'
import UploadCRLVModal from '../../../freights/pendencies/modals/UploadCRLV.modal'
import { createCityInitialValue } from '../../../freights/pendencies/utils'

type FormVechileProviderProps = {
  initialValues?: VehicleResponseFormData | undefined
  onForward?: () => void
  invalidateCache?: () => void
}

const FormVechileProvider = ({
  initialValues,
  onForward,
  invalidateCache,
}: FormVechileProviderProps): JSX.Element => {
  useVehicleCategoriesCombox()
  const dispatch = useAppDispatch()
  const { crlvVehicleFile } = useAppSelector(state => state.ocrCrlvSlice)
  const toast = useToast()

  const methods = useForm<VehicleFormValues>({
    mode: 'onChange',
    defaultValues: vehicleFormInitialValues,
    resolver: yupResolver(VehicleSchema),
  })

  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const { mutateAsync: createVehicle } = usePostVehicle()
  const { mutateAsync: createAttachmentVehicle } = useCreateAttachmentToVehicle({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do veículo criado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
      dispatch(setIsNewImage(false))
      dispatch(setCrlvVehicleFile({} as File))
    },
  })
  const { mutateAsync: updateAttachmentVehicle } = useUpdateAttachmentToVehicle({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do veículo atualizado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
      dispatch(setIsNewImage(false))
      dispatch(setCrlvVehicleFile({} as File))
    },
  })

  const handleAttachmentUpload = async (vehicle_id: string) => {
    if (crlvVehicleFile?.name) {
      const attachment = initialValues?.attachments?.find(item => item.type === 'crlv')
      if (attachment?.id) {
        await updateAttachmentVehicle({
          id: attachment.id,
          attachment_file: crlvVehicleFile,
          vehicle_id,
        })
      } else {
        await createAttachmentVehicle({
          attachment_file: crlvVehicleFile,
          vehicle_id,
          name: 'Crlv do veículo',
          type: 'crlv',
        })
      }
    }
  }

  const onSubmit: SubmitHandler<VehicleFormValues> = async data => {
    const payload = {
      license_plate: data.license_plate.toUpperCase(),
      antt: data.license_plate.toUpperCase(),
      city_id: data.city_id.value,
      toll_tags_ids: data.toll_tags_ids.map(item => item.value),
      axes: data.axes,
      brand: data.brand,
      capacity_kg: data.capacity_kg,
      capacity_m3: data.capacity_m3,
      capacity_tara: data.capacity_tara,
      chassi: data.chassi,
      color: data.color,
      has_insurance: !!data.has_insurance,
      has_tracker: !!data.has_tracker,
      license_uf: data.license_uf,
      model: data.model,
      model_year: data.model_year,
      release_year: data.release_year,
      renavam: data.renavam,
      vehicle_body_id: data.vehicle_body_id,
      vehicle_category_id: data.vehicle_category_id,
    } as VehiclePayload

    if (initialValues?.id) {
      try {
        await createVehicle({
          id: initialValues.id,
          ...payload,
        })

        showSuccessToast('Veículo atualizado com sucesso!')

        await handleAttachmentUpload(initialValues.id)
        addMoreOneParamToUrl('vehicle_id', initialValues.id)
        onForward?.()
        invalidateCache?.()
      } catch (error) {
        toastError({ toast, error })
      }
    } else {
      try {
        const { data: vehicleCreated } = await createVehicle(payload)
        const vehicle_id = vehicleCreated.data.id
        showSuccessToast('Veículo cadastrado sucesso!')
        await handleAttachmentUpload(vehicle_id)
        addMoreOneParamToUrl('vehicle_id', vehicle_id)
        onForward?.()
        invalidateCache?.()
        dispatch(setVehicleCreated(vehicleCreated.data))
      } catch (error) {
        toastError({ toast, error })
      }
    }
  }

  const handleGetValuesByCRLV = (CRLV: Partial<ResponseOcrCrlv>) => {
    if (CRLV) {
      methods.reset({
        ...methods.getValues(),
        license_plate: CRLV.licensePlate,
        antt: CRLV.licensePlate,
        release_year: CRLV.manufactureYear,
        model_year: CRLV.modelYear,
        axes: CRLV.axes ? Number(CRLV.axes) : undefined,
        capacity_kg: CRLV.capacity ? Number(CRLV.capacity) * 1000 : undefined,
        brand: CRLV.brandName,
        chassi: CRLV.chassis,
        color: CRLV.predominantColor,
        model: CRLV.brandModelVersion,
        renavam: CRLV.renavam,
      })
    }
  }

  // Function to create initial values for the form
  const createInitialValues = useCallback(
    (vehicleData?: VehicleResponseFormData) => {
      if (vehicleData) {
        const { city, attachments } = vehicleData

        const attach = attachments?.find(item => item.type === 'crlv')
        if (attach) {
          dispatch(setCrlvVehicleImg(attach?.attachment_file_url || 'placeholder.png'))
        } else {
          dispatch(setCrlvVehicleImg(''))
        }

        const capitalizeWord = (word: string) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        }

        methods.reset({
          license_plate: vehicleData.license_plate || '',
          renavam: vehicleData.renavam || undefined,
          brand: vehicleData.brand || '',
          color: capitalizeWord(alterColorsGenderDatamex(vehicleData.color)) || '',
          model: vehicleData.model || '',
          model_year: vehicleData.model_year?.toString() || '',
          chassi: vehicleData.chassi || '',
          release_year: vehicleData.release_year?.toString() || '',
          antt: vehicleData.license_plate || '',
          license_uf: vehicleData.license_uf || '',
          has_insurance: vehicleData.has_insurance || false,
          has_tracker: vehicleData.has_tracker || false,
          vehicle_category_id: vehicleData?.vehicle_category.id || '',
          vehicle_body_id: vehicleData?.vehicle_body.id || '',
          axes: vehicleData?.axes || undefined,
          capacity_kg: vehicleData?.capacity_kg || undefined,
          capacity_m3: vehicleData?.capacity_m3 || undefined,
          capacity_tara: vehicleData?.capacity_tara || undefined,
          ...(!!city && {
            city_id: createCityInitialValue(city),
          }),
        })
      }
    },
    [dispatch, methods],
  )

  useEffect(() => {
    if (initialValues) {
      createInitialValues(initialValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  return (
    <VStack spacing={4} align="stretch">
      <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
        <UploadCRLVModal
          onSaveInformations={handleGetValuesByCRLV}
          onVehicleFound={data => {
            if (data) {
              // setVehicleFound(data)
              // createInitialValues(data)
            }
          }}
        />

        <Box my={4}>
          <FormProvider {...methods}>
            <VehicleForm
              type="vehicle"
              onVehicleFound={data => {
                dispatch(setVehicleFound(data))
              }}
            />
          </FormProvider>
        </Box>
        <Divider my={4} />
        <Flex justifyContent="flex-end" gridGap="2">
          <Button as={Link} to="/" variant="ghost" colorScheme="red">
            Voltar para lista de veículos
          </Button>

          <Button
            type="submit"
            colorScheme="orange"
            isLoading={methods.formState.isSubmitting}
            leftIcon={<Icon as={RiUserAddLine} />}
          >
            Cadastrar veículos
          </Button>
        </Flex>
      </form>
    </VStack>
  )
}

export default FormVechileProvider
