import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Text,
  Td,
  Box,
  Flex,
  Tooltip,
  Spinner,
  Icon,
  useColorModeValue,
  Grid,
  Divider,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format, startOfMonth } from 'date-fns'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaClipboardList } from 'react-icons/fa'
import { RiFilter2Line, RiSearchLine, RiStarLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import { Ability } from '../../../components/ability'
import { ReportToExcel } from '../../../components/reports/ReportToExcel'
import Filter from '../../../components/UI/Filter'
import { PageHeader } from '../../../components/UI/PageHeader'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import { useGetCountFreights } from '../../../services/endpoints/freights'
import {
  useReportsFeedbacksMotorist,
  fetchReportsFeedbacksMotorist,
  FiltersReportMotoristsFeedbacks,
} from '../../../services/endpoints/motorists/reportsFeedbacksMotorist'
import { formatDateWithTimeZone } from '../../../services/utils/dates/formatDateWithTimeZone'
import { CardMedia, CardPercent, ResponsiveGridItem } from './cards'
import { FiltersMotoristsFeedbacks, FiltersMotoristsFeedbacksForm } from './Filters'

const filtersSchemeForm = yup.object().shape({
  initial_date: yup.string(),
  final_date: yup.string(),
})

export const firstFeedbackDate = '2022-05-24'

export function MotoristsFeedbacksReports(): JSX.Element {
  const bg = useColorModeValue('blackAlpha', 'gray')
  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    page?: string
    initialDate?: string
    finalDate?: string
    freight_number?: string
    analyst?: Selection
  }>()

  const { setValue, handleSubmit, formState, getValues } = useForm({
    resolver: yupResolver(filtersSchemeForm),
  })

  const { errors, isSubmitting } = formState
  const per_page = 15
  const initialDate = () => {
    const now = startOfMonth(new Date())
    return format(now, 'yyyy-MM-dd')
  }

  const finalDate = () => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  }

  const [filters, setFilters] = useState<FiltersReportMotoristsFeedbacks>({
    initialDate: initialDate(),
    finalDate: finalDate(),
    page: getParams('page') ? Number(getParams('page')) : 1,
  })

  const [initialDateFeedbackTotal] = useState<string>(() => {
    const firstFeedback = new Date(`${firstFeedbackDate} 12:29:45.000`)
    return format(firstFeedback, 'yyyy-MM-dd')
  })
  const [finalDateFeedbackTotal] = useState<string>(new Date().toISOString().split('T')[0])

  const { data: feedbacksByPeriod, isLoading } = useReportsFeedbacksMotorist({
    ...filters,
    per_page,
  })

  const { data: feedbacksByPeriodUnpaged } = useReportsFeedbacksMotorist({
    ...filters,
    unpaged: true,
  })

  const { data: feedbacksTotal } = useReportsFeedbacksMotorist({
    initialDate: initialDateFeedbackTotal,
    finalDate: finalDateFeedbackTotal,
    onlyTotal: true,
  })

  const { data: countFreights } = useGetCountFreights({
    initial_date: initialDateFeedbackTotal,
    final_date: finalDateFeedbackTotal,
  })

  const { data: countFreightsByPeriod } = useGetCountFreights({
    initial_date: filters.initialDate,
    final_date: filters.finalDate,
  })

  const handleFilter: SubmitHandler<FiltersMotoristsFeedbacksForm> = data => {
    if (!data.initial_date) {
      setValue('initial_date', initialDate())
    }
    if (!data.final_date) {
      setValue('final_date', finalDate())
    }
    setFilters({
      freight_number: data.freight_number || undefined,
      analyst_id: data.analyst?.value || undefined,
      initialDate: data.initial_date || initialDate(),
      finalDate: data.final_date || finalDate(),
    })

    updateQueryParams({
      freight_number: data.freight_number || undefined,
      analyst: data.analyst
        ? JSON.stringify({ label: data.analyst?.label, value: data.analyst?.value })
        : undefined,
      initialDate: data.initial_date || undefined,
      finalDate: data.final_date || undefined,
    })
  }

  useEffect(() => {
    const pageQuery = getParams('page')
    const freightNumberQuery = getParams('freight_number')
    const initial_date = getParams('initialDate') || initialDate()
    const final_date = getParams('finalDate') || finalDate()
    const analyst = getParams('analyst')

    if (pageQuery || freightNumberQuery || initial_date || final_date || analyst) {
      setValue('page', Number(pageQuery) || 1)
      setValue('freight_number', freightNumberQuery || '')
      setValue('initial_date', initial_date)
      setValue('final_date', final_date)
      setValue('analyst', analyst ? JSON.parse(analyst) : undefined)

      handleSubmit(handleFilter)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const reasonScoreSize = 60
  const columnsName = ['FRETE', 'MOTORISTA', 'ANALISTA', 'DATA COLETA', 'DATA NOTA', 'MOTIVO', 'NOTA']

  return (
    <Layout SidebarMobile>
      <Box>
        <PageHeader
          title="Relatório de feedbacks dos motoristas"
          icon={FaClipboardList}
          rightContent={
            <>
              {feedbacksByPeriod && feedbacksByPeriod.data.data.length > 0 && (
                <Ability module="spotHub" action="show-generate-report-motorists-feedbacks">
                  <ReportToExcel
                    isLoading={isLoading}
                    documentTitle="Relatório de feedbacks dos motoristas"
                    columnsTitle={columnsName}
                    onGenerateReport={async () => {
                      const report = await fetchReportsFeedbacksMotorist({
                        ...filters,
                        initialDate: filters.initialDate,
                        finalDate: filters.finalDate,
                        unpaged: true,
                      })
                      return report.data.data.map(feedback => {
                        return [
                          `#${feedback.freight.freight_number}`,
                          feedback.freight?.motorist?.name,
                          feedback.freight?.creator?.name,
                          formatDateWithTimeZone(feedback.freight?.collect_cargo_at),
                          formatDateWithTimeZone(feedback.created_at),
                          feedback.reason,
                          feedback.recommendation_score,
                        ] as string[]
                      })
                    }}
                  />
                </Ability>
              )}
              <Button
                leftIcon={<RiFilter2Line />}
                variant="outline"
                colorScheme="blue"
                onClick={onToggleFilter}
              >
                Filtros
              </Button>
            </>
          }
        />
        <Divider my="4" />

        <Filter showFilter={onShowFilter}>
          <form onSubmit={handleSubmit(handleFilter)} noValidate>
            <FiltersMotoristsFeedbacks getValues={getValues} errors={errors} setValue={setValue} />

            <Flex mt="4" justify="flex-end">
              <Button
                type="submit"
                colorScheme="blue"
                leftIcon={<Icon as={RiSearchLine} />}
                isLoading={isSubmitting}
              >
                Filtrar
              </Button>
            </Flex>
          </form>
        </Filter>

        {feedbacksByPeriod?.data && feedbacksTotal?.data && feedbacksByPeriodUnpaged?.data && (
          <Box>
            <Grid gap={3} templateColumns="repeat(12, 1fr)" width="100%">
              <ResponsiveGridItem colSpan={4} rowStart={1} rowEnd={4}>
                <CardPercent
                  label="Avaliações no período"
                  feedbacks={feedbacksByPeriod.data.total}
                  freightsByPeriod={countFreightsByPeriod?.finished_freights.total || 0}
                  filters={{
                    initial_date:
                      formatDateWithTimeZone(getValues('initial_date') || initialDate(), 'dd MMM yy') || '',
                    final_date:
                      formatDateWithTimeZone(getValues('final_date') || finalDate(), 'dd MMM yy') || '',
                  }}
                />
              </ResponsiveGridItem>

              <ResponsiveGridItem colSpan={4} rowStart={1} rowEnd={4}>
                <CardMedia
                  label="Média no período selecionado"
                  feedbacksByPeriod={feedbacksByPeriodUnpaged}
                  feedbacksTotal={feedbacksTotal.data.total}
                  filters={{
                    initial_date:
                      formatDateWithTimeZone(getValues('initial_date') || initialDate(), 'dd MMM yy') || '',
                    final_date:
                      formatDateWithTimeZone(getValues('final_date') || finalDate(), 'dd MMM yy') || '',
                  }}
                />
              </ResponsiveGridItem>

              <ResponsiveGridItem colSpan={4} rowStart={1} rowEnd={4}>
                <CardPercent
                  label="Avaliações totais"
                  feedbacks={feedbacksTotal.data.total}
                  freightsByPeriod={countFreights?.finished_freights.total || 0}
                  filters={{
                    initial_date: formatDateWithTimeZone(initialDateFeedbackTotal, 'yyyy') || '',
                    final_date: formatDateWithTimeZone(finalDateFeedbackTotal, 'yyyy') || '',
                  }}
                />
              </ResponsiveGridItem>
            </Grid>
          </Box>
        )}

        <Divider my="4" />

        {feedbacksByPeriod && feedbacksByPeriod.data.total === 0 ? (
          <Flex alignItems="center" justifyContent="center" my="10">
            <Text fontSize="lg" fontWeight="bold">
              Nenhuma avaliação encontrada para os filtros selecionados
            </Text>
          </Flex>
        ) : (
          <Table size="sm" variant="striped" colorScheme={bg}>
            <Thead>
              <Tr>
                {columnsName.map(column => (
                  <Th fontSize="md" whiteSpace="nowrap">
                    {column}
                  </Th>
                ))}
              </Tr>
            </Thead>
            {isLoading ? (
              <Flex alignItems="center" justifyContent="center" my="10">
                <Spinner size="lg" />
              </Flex>
            ) : (
              <Tbody>
                {feedbacksByPeriod &&
                  feedbacksByPeriod.data.data.length > 0 &&
                  feedbacksByPeriod.data.data.map(feedback => (
                    <Tr key={feedback.id}>
                      <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                        <Link to={`/freights/show/${feedback.freight.id}`}>
                          #{feedback.freight.freight_number}
                        </Link>
                      </Td>
                      <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                        {feedback.freight?.motorist?.name}
                      </Td>
                      <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                        {feedback.freight?.creator?.name}
                      </Td>
                      <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                        {feedback.freight?.collect_cargo_at &&
                          formatDateWithTimeZone(feedback.freight?.collect_cargo_at)}
                      </Td>
                      <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                        {feedback.created_at && formatDateWithTimeZone(feedback.created_at)}
                      </Td>

                      <Td fontSize="sm" whiteSpace="nowrap" w="80%">
                        {feedback.reason && (
                          <Tooltip
                            hasArrow
                            label={feedback.reason}
                            color="gray.700"
                            bg="gray.300"
                            placement="top"
                          >
                            <Text>
                              {String(feedback.reason).substring(0, reasonScoreSize)}
                              {feedback.reason.length > reasonScoreSize ? '...' : undefined}
                            </Text>
                          </Tooltip>
                        )}
                      </Td>
                      <Td fontSize="sm" whiteSpace="nowrap" w="10%">
                        <Text fontWeight="bold" color="yellow.500" display="flex" alignItems="center">
                          {feedback.recommendation_score !== 10
                            ? `\xa0\xa0${feedback.recommendation_score}`
                            : feedback.recommendation_score}
                          <Icon as={RiStarLine} fontSize={20} mx="1" />
                        </Text>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            )}
          </Table>
        )}

        {feedbacksByPeriod && feedbacksByPeriod.data.data.length > 0 && (
          <Pagination
            currentPage={filters.page || 1}
            totalCountOfRegisters={Number(feedbacksByPeriod.data.total)}
            registersPerPage={per_page}
            onPageChange={newPage => {
              addPage(newPage)
              setFilters(prev => ({ ...prev, page: newPage }))
            }}
            mt="8"
          />
        )}
      </Box>
    </Layout>
  )
}
