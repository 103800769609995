import { UseQueryResult, useQuery } from 'react-query'
import { apiServer } from '../../api'
import { ResArrayApiType } from '../../types/ResponseApiType'

interface GetRoutePointsParams {
  freight_id: string
}

export interface GetRoutePointsReturn {
  id: string
  latitude: number
  longitude: number
  provider?: string
  freight_id: string
  toll_value: number
  distance: string
  duration?: string
  tags?: string
  stops?: string
}

async function getRoutePoints(freight_id: string): Promise<GetRoutePointsReturn[]> {
  const { data: response } = await apiServer.get<ResArrayApiType<GetRoutePointsReturn>>(
    `find-route-points/${freight_id}`,
  )

  return response.data
}

export function useGetRoutePoints(params: GetRoutePointsParams): UseQueryResult<GetRoutePointsReturn[]> {
  return useQuery(['find-route-points', params.freight_id], () => getRoutePoints(params.freight_id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    refetchOnWindowFocus: 'always',
  })
}
