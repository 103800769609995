import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseApiType, VehicleType } from '../../types'

export interface VehiclePayload {
  id?: string
  license_plate: string
  city_id: string
  toll_tags_ids: string[]
  antt: string
  axes: number
  brand: string
  capacity_kg: number
  capacity_m3: number
  capacity_tara: number
  chassi: string
  color: string
  has_insurance: boolean
  has_tracker: boolean
  license_uf: string
  model: string
  model_year: string
  release_year: string
  renavam: string
  vehicle_body_id: string
  vehicle_category_id: string
}

export async function createVehicle(
  data: VehiclePayload,
): Promise<AxiosResponse<ResponseApiType<VehicleType>>> {
  let response

  if (data?.id) {
    response = await apiServer.put(`/update-vehicle/${data.id}`, data)
  } else {
    response = await apiServer.post('/create-vehicle', data)
  }
  return response
}

export function usePostVehicle(
  options?: UseMutationOptions<AxiosResponse, unknown, VehiclePayload, unknown>,
): UseMutationResult<AxiosResponse<ResponseApiType<VehicleType>>, unknown, VehiclePayload, unknown> {
  return useMutation(async (data: VehiclePayload) => createVehicle(data), options)
}
