export const bankList = [
  {
    value: '001',
    label: '001 - Banco do Brasil S.A.',
  },
  {
    value: '003',
    label: '003 - Banco da Amazônia S.A.',
  },
  {
    value: '004',
    label: '004 - Banco do Nordeste do Brasil S.A.',
  },
  {
    value: '007',
    label: '007 - Banco Nacional de Desenvolvimento Econômico e Social BNDES',
  },
  {
    value: '010',
    label: '010 - Credicoamo Crédito Rural Cooperativa',
  },
  {
    value: '011',
    label: '011 - Credit Suisse Hedging-Griffo Corretora de Valores S.A.',
  },
  {
    value: '012',
    label: '012 - Banco Inbursa S.A.',
  },
  {
    value: '014',
    label: '014 - Natixis Brasil S.A. Banco Múltiplo',
  },
  {
    value: '015',
    label: '015 - UBS Brasil Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  },
  {
    value: '016',
    label: '016 - Coop de Créd. Mútuo dos Despachantes de Trânsito de SC e Rio Grande do Sul',
  },
  {
    value: '017',
    label: '017 - BNY Mellon Banco S.A.',
  },
  {
    value: '018',
    label: '018 - Banco Tricury S.A.',
  },
  {
    value: '021',
    label: '021 - Banestes S.A. Banco do Estado do Espírito Santo',
  },
  {
    value: '024',
    label: '024 - Banco Bandepe S.A.',
  },
  {
    value: '025',
    label: '025 - Banco Alfa S.A.',
  },
  {
    value: '029',
    label: '029 - Banco Itaú Consignado S.A.',
  },
  {
    value: '033',
    label: '033 - Banco Santander (Brasil) S. A.',
  },
  {
    value: '036',
    label: '036 - Banco Bradesco BBI S.A.',
  },
  {
    value: '037',
    label: '037 - Banco do Estado do Pará S.A.',
  },
  {
    value: '040',
    label: '040 - Banco Cargill S.A.',
  },
  {
    value: '041',
    label: '041 - Banco do Estado do Rio Grande do Sul S.A.',
  },
  {
    value: '047',
    label: '047 - Banco do Estado de Sergipe S.A.',
  },
  {
    value: '060',
    label: '060 - Confidence Corretora de Câmbio S.A.',
  },
  {
    value: '062',
    label: '062 - Hipercard Banco Múltiplo S.A.',
  },
  {
    value: '063',
    label: '063 - Banco Bradescard S.A.',
  },
  {
    value: '064',
    label: '064 - Goldman Sachs do Brasil  Banco Múltiplo S. A.',
  },
  {
    value: '065',
    label: '065 - Banco AndBank (Brasil) S.A.',
  },
  {
    value: '066',
    label: '066 - Banco Morgan Stanley S. A.',
  },
  {
    value: '069',
    label: '069 - Banco Crefisa S.A.',
  },
  {
    value: '070',
    label: '070 - Banco de Brasília S.A.',
  },
  {
    value: '074',
    label: '074 - Banco J. Safra S.A.',
  },
  {
    value: '075',
    label: '075 - Banco ABN Amro S.A.',
  },
  {
    value: '076',
    label: '076 - Banco KDB do Brasil S.A.',
  },
  {
    value: '077',
    label: '077 - Banco Inter S.A.',
  },
  {
    value: '078',
    label: '078 - Haitong Banco de Investimento do Brasil S.A.',
  },
  {
    value: '079',
    label: '079 - Banco Original do Agronegócio S.A.',
  },
  {
    value: '080',
    label: '080 - BT Corretora de Câmbio Ltda.',
  },
  {
    value: '081',
    label: '081 - BBN Banco Brasileiro de Negocios S.A.',
  },
  {
    value: '082',
    label: '082 - Banco Topazio S.A.',
  },
  {
    value: '083',
    label: '083 - Banco da China Brasil S.A.',
  },
  {
    value: '084',
    label: '084 - Uniprime Norte do Paraná - Cooperativa de Crédito Ltda.',
  },
  {
    value: '085',
    label: '085 - Cooperativa Central de Crédito Urbano - Cecred',
  },
  {
    value: '088',
    label: '088 - BANCO RANDON S.A.',
  },
  {
    value: '089',
    label: '089 - Cooperativa de Crédito Rural da Região da Mogiana',
  },
  {
    value: '091',
    label: '091 - Central de Cooperativas de Economia e Crédito Mútuo do Est RS - Unicred',
  },
  {
    value: '092',
    label: '092 - BRK S.A. Crédito, Financiamento e Investimento',
  },
  {
    value: '093',
    label: '093 - Pólocred Sociedade de Crédito ao Microempreendedor e à Empresa de Pequeno Porte',
  },
  {
    value: '094',
    label: '094 - Banco Finaxis S.A.',
  },
  {
    value: '095',
    label: '095 - Banco Confidence de Câmbio S.A.',
  },
  {
    value: '096',
    label: '096 - Banco BMFBovespa de Serviços de Liquidação e Custódia S/A',
  },
  {
    value: '097',
    label: '097 - Cooperativa Central de Crédito Noroeste Brasileiro Ltda - CentralCredi',
  },
  {
    value: '098',
    label: '098 - Credialiança Cooperativa de Crédito Rural',
  },
  {
    value: '099',
    label: '099 - Uniprime Central – Central Interestadual de Cooperativas de Crédito Ltda.',
  },
  {
    value: '100',
    label: '100 - Planner Corretora de Valores S.A.',
  },
  {
    value: '101',
    label: '101 - Renascença Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '102',
    label: '102 - XP Investimentos Corretora de Câmbio Títulos e Valores Mobiliários S.A.',
  },
  {
    value: '104',
    label: '104 - Caixa Econômica Federal',
  },
  {
    value: '105',
    label: '105 - Lecca Crédito, Financiamento e Investimento S/A',
  },
  {
    value: '107',
    label: '107 - Banco Bocom BBM S.A.',
  },
  {
    value: '108',
    label: '108 - PortoCred S.A. Crédito, Financiamento e Investimento',
  },
  {
    value: '111',
    label: '111 - Oliveira Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    value: '113',
    label: '113 - Magliano S.A. Corretora de Cambio e Valores Mobiliarios',
  },
  {
    value: '114',
    label: '114 - Central Cooperativa de Crédito no Estado do Espírito Santo - CECOOP',
  },
  {
    value: '117',
    label: '117 - Advanced Corretora de Câmbio Ltda.',
  },
  {
    value: '118',
    label: '118 - Standard Chartered Bank (Brasil) S.A. Banco de Investimento',
  },
  {
    value: '119',
    label: '119 - Banco Western Union do Brasil S.A.',
  },
  {
    value: '120',
    label: '120 - Banco Rodobens SA',
  },
  {
    value: '121',
    label: '121 - Banco Agibank S.A.',
  },
  {
    value: '122',
    label: '122 - Banco Bradesco BERJ S.A.',
  },
  {
    value: '124',
    label: '124 - Banco Woori Bank do Brasil S.A.',
  },
  {
    value: '125',
    label: '125 - Brasil Plural S.A. Banco Múltiplo',
  },
  {
    value: '126',
    label: '126 - BR Partners Banco de Investimento S.A.',
  },
  {
    value: '127',
    label: '127 - Codepe Corretora de Valores e Câmbio S.A.',
  },
  {
    value: '128',
    label: '128 - MS Bank S.A. Banco de Câmbio',
  },
  {
    value: '129',
    label: '129 - UBS Brasil Banco de Investimento S.A.',
  },
  {
    value: '130',
    label: '130 - Caruana S.A. Sociedade de Crédito, Financiamento e Investimento',
  },
  {
    value: '131',
    label: '131 - Tullett Prebon Brasil Corretora de Valores e Câmbio Ltda.',
  },
  {
    value: '132',
    label: '132 - ICBC do Brasil Banco Múltiplo S.A.',
  },
  {
    value: '133',
    label: '133 - Banco Cresol',
  },
  {
    value: '134',
    label: '134 - BGC Liquidez Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '135',
    label: '135 - Gradual Corretora de Câmbio, Títulos e Valores Mobiliários S.A.',
  },
  {
    value: '136',
    label: '136 - Confederação Nacional das Cooperativas Centrais Unicred Ltda – Unicred do Brasil',
  },
  {
    value: '137',
    label: '137 - Multimoney Corretora de Câmbio Ltda',
  },
  {
    value: '138',
    label: '138 - Get Money Corretora de Câmbio S.A.',
  },
  {
    value: '139',
    label: '139 - Intesa Sanpaolo Brasil S.A. - Banco Múltiplo',
  },
  {
    value: '140',
    label: '140 - Easynvest - Título Corretora de Valores SA',
  },
  {
    value: '141',
    label: '141 - BANCO MASTER DE INVESTIMENTO S.A.',
  },
  {
    value: '142',
    label: '142 - Broker Brasil Corretora de Câmbio Ltda.',
  },
  {
    value: '143',
    label: '143 - Treviso Corretora de Câmbio S.A.',
  },
  {
    value: '144',
    label: '144 - Bexs Banco de Câmbio S.A.',
  },
  {
    value: '145',
    label: '145 - Levycam - Corretora de Câmbio e Valores Ltda.',
  },
  {
    value: '146',
    label: '146 - Guitta Corretora de Câmbio Ltda.',
  },
  {
    value: '149',
    label: '149 - Facta Financeira S.A. - Crédito Financiamento e Investimento',
  },
  {
    value: '157',
    label: '157 - ICAP do Brasil Corretora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '159',
    label: '159 - Casa do Crédito S.A. Sociedade de Crédito ao Microempreendedor',
  },
  {
    value: '163',
    label: '163 - Commerzbank Brasil S.A. - Banco Múltiplo',
  },
  {
    value: '169',
    label: '169 - Banco Olé Bonsucesso Consignado S.A.',
  },
  {
    value: '172',
    label: '172 - Albatross Corretora de Câmbio e Valores S.A',
  },
  {
    value: '173',
    label: '173 - BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.',
  },
  {
    value: '174',
    label: '174 - Pernambucanas Financiadora S.A. Crédito, Financiamento e Investimento',
  },
  {
    value: '177',
    label: '177 - Guide Investimentos S.A. Corretora de Valores',
  },
  {
    value: '180',
    label: '180 - CM Capital Markets Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '182',
    label: '182 - Dacasa Financeira S/A - Sociedade de Crédito, Financiamento e Investimento',
  },
  {
    value: '183',
    label: '183 - Socred S.A. - Sociedade de Crédito ao Microempreendedor',
  },
  {
    value: '184',
    label: '184 - Banco Itaú BBA S.A.',
  },
  {
    value: '188',
    label: '188 - Ativa Investimentos S.A. Corretora de Títulos Câmbio e Valores',
  },
  {
    value: '189',
    label: '189 - HS Financeira S/A Crédito, Financiamento e Investimentos',
  },
  {
    value: '190',
    label: '190 - Cooperativa de Economia e Crédito Mútuo dos Servidores Públicos Estaduais do Rio',
  },
  {
    value: '191',
    label: '191 - Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '194',
    label: '194 - Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '195',
    label: '195 - VALOR S/A SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '196',
    label: '196 - Fair Corretora de Câmbio S.A.',
  },
  {
    value: '197',
    label: '197 - Stone Pagamentos S.A.',
  },
  {
    value: '204',
    label: '204 - Banco Bradesco Cartões S.A.',
  },
  {
    value: '208',
    label: '208 - Banco BTG Pactual S.A.',
  },
  {
    value: '212',
    label: '212 - Banco Original S.A.',
  },
  {
    value: '213',
    label: '213 - Banco Arbi S.A.',
  },
  {
    value: '217',
    label: '217 - Banco John Deere S.A.',
  },
  {
    value: '218',
    label: '218 - Banco BS2 S.A.',
  },
  {
    value: '222',
    label: '222 - Banco Credit Agrícole Brasil S.A.',
  },
  {
    value: '224',
    label: '224 - Banco Fibra S.A.',
  },
  {
    value: '233',
    label: '233 - Banco Cifra S.A.',
  },
  {
    value: '237',
    label: '237 - Banco Bradesco S.A.',
  },
  {
    value: '237',
    label: '237 - Next Banco S.A.',
  },
  {
    value: '241',
    label: '241 - Banco Clássico S.A.',
  },
  {
    value: '243',
    label: '243 - Banco Máxima S.A.',
  },
  {
    value: '246',
    label: '246 - Banco ABC Brasil S.A.',
  },
  {
    value: '249',
    label: '249 - Banco Investcred Unibanco S.A.',
  },
  {
    value: '250',
    label: '250 - BCV - Banco de Crédito e Varejo S/A',
  },
  {
    value: '253',
    label: '253 - Bexs Corretora de Câmbio S/A',
  },
  {
    value: '254',
    label: '254 - Parana Banco S. A.',
  },
  {
    value: '259',
    label: '259 - MONEYCORP BANCO DE CÂMBIO S.A.',
  },
  {
    value: '260',
    label: '260 - Nu Pagamentos S.A.',
  },
  {
    value: '265',
    label: '265 - Banco Fator S.A.',
  },
  {
    value: '266',
    label: '266 - Banco Cédula S.A.',
  },
  {
    value: '268',
    label: '268 - Barigui Companhia Hipotecária',
  },
  {
    value: '269',
    label: '269 - HSBC Brasil S.A. Banco de Investimento',
  },
  {
    value: '271',
    label: '271 - IB Corretora de Câmbio, Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '272',
    label: '272 - AGK CORRETORA DE CAMBIO S.A.',
  },
  {
    value: '273',
    label: '273 - COOPERATIVA DE CREDITO SULCREDI AMPLEA',
  },
  {
    value: '274',
    label: '274 - BMP SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR',
  },
  {
    value: '276',
    label: '276 - BANCO SENFF S.A.',
  },
  {
    value: '278',
    label: '278 - Genial Investimentos Corretora de Valores Mobiliários S.A.',
  },
  {
    value: '280',
    label: '280 - WILL FINANCEIRA S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '281',
    label: '281 - Cooperativa de Crédito Rural Coopavel',
  },
  {
    value: '283',
    label: '283 - RB INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES',
  },
  {
    value: '285',
    label: '285 - FRENTE CORRETORA DE CÂMBIO S.A.',
  },
  {
    value: '289',
    label: '289 - EFX CORRETORA DE CÂMBIO LTDA.',
  },
  {
    value: '290',
    label: '290 - PAGSEGURO INTERNET INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '292',
    label: '292 - GALAPAGOS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES',
  },
  {
    value: '296',
    label: '296 - OZ CORRETORA DE CÂMBIO S.A.',
  },
  {
    value: '299',
    label: '299 - BANCO AFINZ S.A. - BANCO MÚLTIPLO',
  },
  {
    value: '300',
    label: '300 - Banco de la Nacion Argentina',
  },
  {
    value: '301',
    label: '301 - Banco Dock Instituição de Pagamento S.A',
  },
  {
    value: '305',
    label: '305 - FOURTRADE CORRETORA DE CÂMBIO LTDA.',
  },
  {
    value: '306',
    label: '306 - PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS',
  },
  {
    value: '307',
    label: '307 - Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
  },
  {
    value: '310',
    label: '310 - VORTX DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  },
  {
    value: '312',
    label: '312 - HSCM - SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À',
  },
  {
    value: '318',
    label: '318 - Banco BMG S.A.',
  },
  {
    value: '320',
    label: '320 - China Construction Bank (Brasil) Banco Múltiplo S/A',
  },
  {
    value: '321',
    label: '321 - CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A',
  },
  {
    value: '322',
    label: '322 - Cooperativa de Crédito Rural de Abelardo Luz - Sulcredi/Crediluz',
  },
  {
    value: '323',
    label: '323 - Mercado Pago S/A',
  },
  {
    value: '324',
    label: '324 - CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '326',
    label: '326 - PARATI - CREDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  },
  {
    value: '328',
    label: '328 - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS FABRICANTES',
  },
  {
    value: '329',
    label: '329 - QI Sociedade de Crédito Direto S.A.',
  },
  {
    value: '330',
    label: '330 - BANCO BARI DE INVESTIMENTOS E FINANCIAMENTOS S.A.',
  },
  {
    value: '331',
    label: '331 - OSLO CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS',
  },
  {
    value: '332',
    label: '332 - ACESSO SOLUÇÕES DE PAGAMENTO S.A. - INSTITUIÇÃO DE',
  },
  {
    value: '334',
    label: '334 - BANCO BESA S.A.',
  },
  {
    value: '335',
    label: '335 - Banco Digio S.A',
  },
  {
    value: '336',
    label: '336 - Banco C6 S.A – C6 Bank',
  },
  {
    value: '341',
    label: '341 - Itaú Unibanco  S.A.',
  },
  {
    value: '342',
    label: '342 - Creditas Sociedade de Crédito Direto S.A.',
  },
  {
    value: '343',
    label: '343 - FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA',
  },
  {
    value: '348',
    label: '348 - Banco XP S.A.',
  },
  {
    value: '349',
    label: '349 - AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '350',
    label: '350 - COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS DE',
  },
  {
    value: '352',
    label: '352 - TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '358',
    label: '358 - MIDWAY S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '359',
    label: '359 - ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
  },
  {
    value: '360',
    label: '360 - TRINUS CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS',
  },
  {
    value: '362',
    label: '362 - CIELO S.A. - INSTITUIÇÃO DE PAGAMENTO',
  },
  {
    value: '363',
    label: '363 - SINGULARE CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '364',
    label: '364 - EFÍ S.A. - INSTITUIÇÃO DE PAGAMENTO',
  },
  {
    value: '365',
    label: '365 - SIMPAUL CORRETORA DE CAMBIO E VALORES MOBILIARIOS  S.A.',
  },
  {
    value: '366',
    label: '366 - Banco Société Générale Brasil S.A.',
  },
  {
    value: '368',
    label: '368 - Banco CSF S.A.',
  },
  {
    value: '370',
    label: '370 - Banco Mizuho do Brasil S.A.',
  },
  {
    value: '371',
    label: '371 - WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
  },
  {
    value: '373',
    label: '373 - UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
  },
  {
    value: '374',
    label: '374 - REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  },
  {
    value: '376',
    label: '376 - Banco J. P. Morgan S. A.',
  },
  {
    value: '377',
    label: '377 - BMS SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '378',
    label: '378 - BANCO BRASILEIRO DE CRÉDITO SOCIEDADE ANÔNIMA',
  },
  {
    value: '379',
    label: '379 - COOPERFORTE - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DE',
  },
  {
    value: '380',
    label: '380 - Banco Picpay - Banco Múltiplo S.A',
  },
  {
    value: '381',
    label: '381 - BANCO MERCEDES-BENZ DO BRASIL S.A.',
  },
  {
    value: '382',
    label: '382 - FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À',
  },
  {
    value: '383',
    label: '383 - EBANX INSTITUICAO DE PAGAMENTOS LTDA.',
  },
  {
    value: '384',
    label: '384 - GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO',
  },
  {
    value: '385',
    label: '385 - COOPERATIVA DE ECONOMIA E CREDITO MUTUO DOS',
  },
  {
    value: '386',
    label: '386 - NU FINANCEIRA S.A. - Sociedade de Crédito, Financiamento e Investimento',
  },
  {
    value: '387',
    label: '387 - Banco Toyota do Brasil S.A.',
  },
  {
    value: '389',
    label: '389 - Banco Mercantil do Brasil S.A.',
  },
  {
    value: '390',
    label: '390 - BANCO GM S.A.',
  },
  {
    value: '391',
    label: '391 - COOPERATIVA DE CREDITO RURAL DE IBIAM - SULCREDI/IBIAM',
  },
  {
    value: '393',
    label: '393 - Banco Volkswagen S.A.',
  },
  {
    value: '394',
    label: '394 - Banco Bradesco Financiamentos S.A.',
  },
  {
    value: '395',
    label: "395 - F.D'GOLD - DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.",
  },
  {
    value: '396',
    label: '396 - MAGALUPAY INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '397',
    label: '397 - LISTO SOCIEDADE DE CREDITO DIRETO S.A.',
  },
  {
    value: '398',
    label: '398 - IDEAL CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '399',
    label: '399 - Kirton Bank S.A. - Banco Múltiplo',
  },
  {
    value: '400',
    label: '400 - COOPERATIVA DE CRÉDITO, POUPANÇA E SERVIÇOS FINANCEIROS',
  },
  {
    value: '401',
    label: '401 - IUGU INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '402',
    label: '402 - COBUCCIO S/A - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E',
  },
  {
    value: '403',
    label: '403 - Cora SCD',
  },
  {
    value: '404',
    label: '404 - SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '406',
    label: '406 - ACCREDITO - SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '407',
    label: '407 - SEFER INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES',
  },
  {
    value: '408',
    label: '408 - BONUSPAGO SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '410',
    label: '410 - PLANNER SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '412',
    label: '412 - Banco Capital S. A.',
  },
  {
    value: '413',
    label: '413 - Banco BV S.A.',
  },
  {
    value: '414',
    label: '414 - LEND SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '415',
    label: '415 - BANCO NACIONAL S.A.',
  },
  {
    value: '418',
    label: '418 - ZIPDIN SOLUÇÕES DIGITAIS SOCIEDADE DE CRÉDITO DIRETO S/A',
  },
  {
    value: '419',
    label: '419 - NUMBRS SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '421',
    label: '421 - LAR COOPERATIVA DE CRÉDITO - LAR CREDI',
  },
  {
    value: '422',
    label: '422 - Banco Safra S.A.',
  },
  {
    value: '423',
    label: '423 - COLUNA S/A DISTRIBUIDORA DE TITULOS E VALORES MOBILIÁRIOS',
  },
  {
    value: '425',
    label: '425 - SOCINAL S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '426',
    label: '426 - NEON FINANCEIRA - CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  },
  {
    value: '427',
    label: '427 - COOPERATIVA DE CRÉDITO DOS SERVIDORES DA UNIVERSIDADE',
  },
  {
    value: '428',
    label: '428 - CREDSYSTEM SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '429',
    label: '429 - Crediare S.A. - Crédito, financiamento e investimento',
  },
  {
    value: '430',
    label: '430 - COOPERATIVA DE CREDITO RURAL SEARA - CREDISEARA',
  },
  {
    value: '433',
    label: '433 - BR-CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '435',
    label: '435 - DELCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '438',
    label: '438 - TRUSTEE DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  {
    value: '439',
    label: '439 - ID CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '440',
    label: '440 - CREDIBRF - COOPERATIVA DE CRÉDITO',
  },
  {
    value: '443',
    label: '443 - CREDIHOME SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '444',
    label: '444 - TRINUS SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '445',
    label: '445 - PLANTAE S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '447',
    label: '447 - MIRAE ASSET (BRASIL) CORRETORA DE CÂMBIO, TÍTULOS  E VALORES',
  },
  {
    value: '448',
    label: '448 - HEMERA DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  {
    value: '449',
    label: '449 - DM SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '450',
    label: '450 - FITBANK INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '451',
    label: '451 - J17 - SOCIEDADE DE CRÉDITO DIRETO S/A',
  },
  {
    value: '452',
    label: '452 - CREDIFIT SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '454',
    label: '454 - MÉRITO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  {
    value: '455',
    label: '455 - FÊNIX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  {
    value: '456',
    label: '456 - Banco MUFG Brasil S.A.',
  },
  {
    value: '457',
    label: '457 - UY3 SOCIEDADE DE CRÉDITO DIRETO S/A',
  },
  {
    value: '458',
    label: '458 - HEDGE INVESTMENTS DISTRIBUIDORA DE TÍTULOS E VALORES',
  },
  {
    value: '460',
    label: '460 - UNAVANTI SOCIEDADE DE CRÉDITO DIRETO S/A',
  },
  {
    value: '461',
    label: '461 - ASAAS GESTÃO FINANCEIRA INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '462',
    label: '462 - STARK SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '463',
    label: '463 - AZUMI DISTRIBUIDORA DE TíTULOS E VALORES MOBILIáRIOS LTDA.',
  },
  {
    value: '464',
    label: '464 - Banco Sumitomo Mitsui Brasileiro S.A.',
  },
  {
    value: '465',
    label: '465 - CAPITAL CONSIG SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '467',
    label: '467 - MASTER S/A CORRETORA DE CâMBIO, TíTULOS E VALORES',
  },
  {
    value: '469',
    label: '469 - PICPAY INVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS',
  },
  {
    value: '470',
    label: '470 - CDC SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '471',
    label: '471 - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS SERVIDORES',
  },
  {
    value: '473',
    label: '473 - Banco Caixa Geral - Brasil S.A.',
  },
  {
    value: '475',
    label: '475 - Banco Yamaha Motor do Brasil S.A.',
  },
  {
    value: '477',
    label: '477 - Citibank N.A.',
  },
  {
    value: '478',
    label: '478 - GAZINCRED S.A. SOCIEDADE DE CRÉDITO, FINANCIAMENTO E',
  },
  {
    value: '479',
    label: '479 - Banco ItauBank S.A.',
  },
  {
    value: '481',
    label: '481 - SUPERLÓGICA SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '482',
    label: '482 - SBCASH SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '484',
    label: '484 - MAF DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '487',
    label: '487 - Deutsche Bank S.A. - Banco Alemão',
  },
  {
    value: '488',
    label: '488 - JPMorgan Chase Bank, National Association',
  },
  {
    value: '492',
    label: '492 - ING Bank N.V.',
  },
  {
    value: '494',
    label: '494 - Banco de La Republica Oriental del Uruguay',
  },
  {
    value: '495',
    label: '495 - Banco de La Provincia de Buenos Aires',
  },
  {
    value: '505',
    label: '505 - Banco Credit Suisse (Brasil) S.A.',
  },
  {
    value: '506',
    label: '506 - RJI CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA',
  },
  {
    value: '507',
    label: '507 - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO EFÍ S.A.',
  },
  {
    value: '508',
    label: '508 - AVENUE SECURITIES DISTRIBUIDORA DE TÍTULOS E VALORES',
  },
  {
    value: '509',
    label: '509 - CELCOIN INSTITUICAO DE PAGAMENTO S.A.',
  },
  {
    value: '510',
    label: '510 - FFCRED SOCIEDADE DE CRÉDITO DIRETO S.A..',
  },
  {
    value: '511',
    label: '511 - MAGNUM SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '512',
    label: '512 - FINVEST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  {
    value: '513',
    label: '513 - ATF SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '516',
    label: '516 - QISTA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '518',
    label: '518 - MERCADO CRÉDITO SOCIEDADE DE CRÉDITO, FINANCIAMENTO E',
  },
  {
    value: '519',
    label: '519 - LIONS TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS',
  },
  {
    value: '520',
    label: '520 - SOMAPAY SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '521',
    label: '521 - PEAK SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
  },
  {
    value: '522',
    label: '522 - RED SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '523',
    label: '523 - HR DIGITAL - SOCIEDADE DE CRÉDITO DIRETO S/A',
  },
  {
    value: '524',
    label: '524 - WNT CAPITAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS',
  },
  {
    value: '525',
    label: '525 - INTERCAM CORRETORA DE CÂMBIO LTDA.',
  },
  {
    value: '526',
    label: '526 - MONETARIE SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '527',
    label: '527 - ATICCA - SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '528',
    label: '528 - REAG TRUST DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '529',
    label: '529 - PINBANK BRASIL INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '530',
    label: '530 - SER FINANCE SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '531',
    label: '531 - BMP SOCIEDADE DE CRÉDITO DIRETO S.A',
  },
  {
    value: '532',
    label: '532 - EAGLE SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '533',
    label: '533 - SRM BANK INSTITUIÇÃO DE PAGAMENTO S/A',
  },
  {
    value: '534',
    label: '534 - EWALLY INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '535',
    label: '535 - OPEA SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '536',
    label: '536 - NEON PAGAMENTOS S.A. - INSTITUIÇÃO DE PAGAMENTO',
  },
  {
    value: '537',
    label: '537 - MICROCASH SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À',
  },
  {
    value: '538',
    label: '538 - SUDACRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '539',
    label: '539 - SANTINVEST S.A. - CREDITO, FINANCIAMENTO E INVESTIMENTOS',
  },
  {
    value: '540',
    label: '540 - HBI SOCIEDADE DE CRÉDITO DIRETO S/A.',
  },
  {
    value: '541',
    label: '541 - FUNDO GARANTIDOR DE CREDITOS - FGC',
  },
  {
    value: '542',
    label: '542 - CLOUDWALK INSTITUIÇÃO DE PAGAMENTO E SERVICOS LTDA',
  },
  {
    value: '543',
    label: '543 - COOPERATIVA DE ECONOMIA E CRÉDITO MÚTUO DOS ELETRICITÁRIOS',
  },
  {
    value: '544',
    label: '544 - MULTICRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '545',
    label: '545 - Senso Corretora de Câmbio e Valores Mobiliários S.A.',
  },
  {
    value: '546',
    label: '546 - U4C INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '547',
    label: '547 - BNK DIGITAL SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '548',
    label: '548 - RPW S/A SOCIEDADE DE CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '549',
    label: '549 - INTRA INVESTIMENTOS DISTRIBUIDORA DE TÍTULOS E VALORES',
  },
  {
    value: '550',
    label: '550 - BEETELLER INSTITUIÇÃO DE PAGAMENTO LTDA.',
  },
  {
    value: '551',
    label: '551 - VERT DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    value: '552',
    label: '552 - UZZIPAY INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '553',
    label: '553 - PERCAPITAL SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '554',
    label: '554 - STONEX BANCO DE CÂMBIO S.A.',
  },
  {
    value: '555',
    label: '555 - PAN FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTOS',
  },
  {
    value: '556',
    label: '556 - SAYGO CORRETORA DE CÂMBIO S.A.',
  },
  {
    value: '557',
    label: '557 - PAGPRIME INSTITUICAO DE PAGAMENTO LTDA',
  },
  {
    value: '558',
    label: '558 - QI DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  {
    value: '559',
    label: '559 - KANASTRA FINANCEIRA S.A, CREDITO, FINANCIAMENTO E',
  },
  {
    value: '560',
    label: '560 - MAG INSTITUICAO DE PAGAMENTO LTDA',
  },
  {
    value: '561',
    label: '561 - PAY4FUN INSTITUICAO DE PAGAMENTO S.A.',
  },
  {
    value: '562',
    label: '562 - AZIMUT BRASIL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS',
  },
  {
    value: '563',
    label: '563 - PROTEGE CASH INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '565',
    label: '565 - ÁGORA CORRETORA DE TITULOS E VALORES MOBILIARIOS S.A.',
  },
  {
    value: '566',
    label: '566 - FLAGSHIP INSTITUICAO DE PAGAMENTOS LTDA',
  },
  {
    value: '568',
    label: '568 - BRCONDOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '569',
    label: '569 - CONTA PRONTA INSTITUICAO DE PAGAMENTO LTDA',
  },
  {
    value: '572',
    label: '572 - ALL IN CRED SOCIEDADE DE CREDITO DIRETO S.A.',
  },
  {
    value: '574',
    label: '574 - A55 SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '575',
    label: '575 - DGBK CREDIT S.A. - SOCIEDADE DE CRÉDITO DIRETO.',
  },
  {
    value: '576',
    label: '576 - MERCADO BITCOIN INSTITUICAO DE PAGAMENTO LTDA',
  },
  {
    value: '577',
    label: '577 - DESENVOLVE SP - AGÊNCIA DE FOMENTO DO ESTADO DE SÃO PAULO',
  },
  {
    value: '578',
    label: '578 - COOPERATIVA DE CRÉDITO DOS SERVIDORES PÚBLICOS MUNICIPAIS',
  },
  {
    value: '580',
    label: '580 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO',
  },
  {
    value: '581',
    label: '581 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO',
  },
  {
    value: '582',
    label: '582 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DE',
  },
  {
    value: '583',
    label: '583 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO DO',
  },
  {
    value: '584',
    label: '584 - COOPERATIVA CENTRAL DE CRÉDITO, POUPANÇA E INVESTIMENTO',
  },
  {
    value: '586',
    label: '586 - Z1 INSTITUIÇÃO DE PAGAMENTO LTDA.',
  },
  {
    value: '587',
    label: '587 - FIDD DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA.',
  },
  {
    value: '588',
    label: '588 - PROVER PROMOCAO DE VENDAS INSTITUICAO DE PAGAMENTO LTDA',
  },
  {
    value: '589',
    label: '589 - G5 SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '590',
    label: '590 - REPASSES FINANCEIROS E SOLUCOES TECNOLOGICAS INSTITUICAO',
  },
  {
    value: '591',
    label: '591 - BANVOX DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  },
  {
    value: '592',
    label: '592 - INSTITUIÇÃO DE PAGAMENTOS MAPS LTDA.',
  },
  {
    value: '593',
    label: '593 - TRANSFEERA INSTITUIÇÃO DE PAGAMENTO S.A',
  },
  {
    value: '594',
    label: '594 - ASA SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '595',
    label: '595 - ZOOP TECNOLOGIA & INSTITUICAO DE PAGAMENTO S.A.',
  },
  {
    value: '597',
    label: '597 - ISSUER INSTITUICAO DE PAGAMENTO LTDA.',
  },
  {
    value: '599',
    label: '599 - AGORACRED S/A SOCIEDADE DE CRÉDITO, FINANCIAMENTO E',
  },
  {
    value: '600',
    label: '600 - Banco Luso Brasileiro S.A.',
  },
  {
    value: '604',
    label: '604 - Banco Industrial do Brasil S.A.',
  },
  {
    value: '610',
    label: '610 - Banco VR S.A.',
  },
  {
    value: '611',
    label: '611 - Banco Paulista S.A.',
  },
  {
    value: '612',
    label: '612 - Banco Guanabara S.A.',
  },
  {
    value: '613',
    label: '613 - Omni Banco S.A.',
  },
  {
    value: '614',
    label: '614 - SANTS SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '615',
    label: '615 - SMART SOLUTIONS GROUP INSTITUICAO DE PAGAMENTO LTDA',
  },
  {
    value: '619',
    label: '619 - TRIO INSTITUICAO DE PAGAMENTO LTDA.',
  },
  {
    value: '620',
    label: '620 - REVOLUT SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '623',
    label: '623 - Banco Pan S.A.',
  },
  {
    value: '626',
    label: '626 - Banco Ficsa S. A.',
  },
  {
    value: '630',
    label: '630 - Banco Intercap S.A.',
  },
  {
    value: '632',
    label: '632 - Z-ON SOCIEDADE DE CRÉDITO DIRETO S.A.',
  },
  {
    value: '633',
    label: '633 - Banco Rendimento S.A.',
  },
  {
    value: '634',
    label: '634 - Banco Triângulo S.A.',
  },
  {
    value: '637',
    label: '637 - Banco Sofisa S. A.',
  },
  {
    value: '641',
    label: '641 - Banco Alvorada S.A.',
  },
  {
    value: '643',
    label: '643 - Banco Pine S.A.',
  },
  {
    value: '646',
    label: '646 - DM FINANCEIRA S.A. - CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  },
  {
    value: '651',
    label: '651 - PAGARE INSTITUICAO DE PAGAMENTO S.A.',
  },
  {
    value: '652',
    label: '652 - Itaú Unibanco Holding S.A.',
  },
  {
    value: '653',
    label: '653 - Banco Indusval S. A.',
  },
  {
    value: '654',
    label: '654 - Banco A. J. Renner S.A.',
  },
  {
    value: '655',
    label: '655 - Banco Votorantim S.A.',
  },
  {
    value: '659',
    label: '659 - ONEKEY PAYMENTS INSTITUICAO DE PAGAMENTO SA',
  },
  {
    value: '660',
    label: '660 - PAGME INSTITUIÇÃO DE PAGAMENTO LTDA.',
  },
  {
    value: '662',
    label: '662 - WE PAY OUT INSTITUICAO DE PAGAMENTO LTDA.',
  },
  {
    value: '663',
    label: '663 - ACTUAL DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  },
  {
    value: '664',
    label: '664 - EAGLE INSTITUICAO DE PAGAMENTO LTDA.',
  },
  {
    value: '665',
    label: '665 - STARK BANK S.A. - INSTITUICAO DE PAGAMENTO',
  },
  {
    value: '666',
    label: '666 - URBANO S.A. - SOCIEDADE DE CRÉDITO, FINANCIAMENTO E',
  },
  {
    value: '667',
    label: '667 - LIQUIDO INSTITUICAO DE PAGAMENTO LTDA',
  },
  {
    value: '668',
    label: '668 - VIA CAPITAL - SOCIEDADE DE CRÉDITO DIRETO S/A',
  },
  {
    value: '671',
    label: '671 - ZERO INSTITUIÇÃO DE PAGAMENTO S.A.',
  },
  {
    value: '674',
    label: '674 - HINOVA PAY INSTITUICAO DE PAGAMENTO S.A.',
  },
  {
    value: '676',
    label: '676 - DUFRIO FINANCEIRA, CRÉDITO, FINANCIAMENTO E INVESTIMENTOS S.A.',
  },
  {
    value: '707',
    label: '707 - Banco Daycoval S.A.',
  },
  {
    value: '712',
    label: '712 - Banco Ourinvest S.A.',
  },
  {
    value: '719',
    label: '719 - Banif - Bco Internacional do Funchal (Brasil) S.A.',
  },
  {
    value: '720',
    label: '720 - BANCO RNX S.A.',
  },
  {
    value: '735',
    label: '735 - Banco Neon S.A.',
  },
  {
    value: '739',
    label: '739 - Banco Cetelem S.A.',
  },
  {
    value: '741',
    label: '741 - Banco Ribeirão Preto S.A.',
  },
  {
    value: '743',
    label: '743 - Banco Semear S.A.',
  },
  {
    value: '745',
    label: '745 - Banco Citibank S.A.',
  },
  {
    value: '746',
    label: '746 - Banco Modal S.A.',
  },
  {
    value: '747',
    label: '747 - Banco Rabobank International Brasil S.A.',
  },
  {
    value: '748',
    label: '748 - Banco Cooperativo Sicredi S. A.',
  },
  {
    value: '751',
    label: '751 - Scotiabank Brasil S.A. Banco Múltiplo',
  },
  {
    value: '752',
    label: '752 - Banco BNP Paribas Brasil S.A.',
  },
  {
    value: '753',
    label: '753 - Novo Banco Continental S.A. - Banco Múltiplo',
  },
  {
    value: '754',
    label: '754 - Banco Sistema S.A.',
  },
  {
    value: '755',
    label: '755 - Bank of America Merrill Lynch Banco Múltiplo S.A.',
  },
  {
    value: '756',
    label: '756 - Sicoob - Banco Cooperativo do Brasil S/A - Bancoob',
  },
  {
    value: '757',
    label: '757 - Banco Keb Hana do Brasil S. A.',
  },
]
