import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import { Controller, useFormContext } from 'react-hook-form'
import useSearchVehicle from '../../../hooks/useSearchVehicle'
import { useGetVehicleCategoriesCombox } from '../../../hooks/useVehicleCategoriesCombox'
import { searchCitiesByName } from '../../../services/endpoints/cities/searchCities'
import { VehicleResponseFormData } from '../../../services/endpoints/vehicles/getVehicle'
import { tollTagsAutocomplete } from '../../../services/endpoints/vehicles/toll-tags/toll-tags-autocomplete'
import { vehicleColorsDatamex } from '../../../services/utils/vehicleColorsDatamex'
import { useAppDispatch } from '../../../store'
import { setHasTrailer } from '../../../store/slices/vehicleSlice'
import { UIInput } from '../../UI/UIInput'
import { UIMultiSelectAutoSyncAndAsync } from '../../UI/UIMultiSelectAutoSyncAndAsync'
import { UISelect } from '../../UI/UISelect'
import { UISelectAsync } from '../../UI/UISelectAsync'
import { UISwitch } from '../../UI/UISwitch'
import { VehicleFormValues } from './types'

type VehicleFormProps = {
  type?: 'vehicle' | 'trailer'
  onVehicleFound?: (vehicle: VehicleResponseFormData) => void
  disabled?: boolean
  isLoading?: boolean
}
export const VehicleForm = ({
  type = 'vehicle',
  onVehicleFound,
  disabled = false,
  isLoading = false,
}: VehicleFormProps): JSX.Element => {
  const { setValue, control } = useFormContext<VehicleFormValues>()
  const { vehicleBodies, vehicleCategories } = useGetVehicleCategoriesCombox()
  const dispatch = useAppDispatch()
  const { loading, search } = useSearchVehicle({
    onSuccess: data => {
      if (data.vehicle) {
        onVehicleFound?.(data.vehicle)
      }
    },
    type,
  })

  const isTrailer = type === 'trailer'

  return (
    <Box pointerEvents={disabled ? 'none' : 'auto'} opacity={disabled ? 0.5 : 1}>
      <Text fontSize="xs" opacity={0.5} mb="2">
        Informações do veículo:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="renavam"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                label="Renavam"
                name="renavam"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e)
                  search(e.target.value)
                }}
                isLoading={loading}
                isSearchable
                onSearch={search}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="license_plate"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="aaa9*99"
                label="Placa"
                name="license_plate"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
                isSearchable
                // onSearch={isTrailer ? setLicensePlateTrailer : setLicensePlateVehicle}
                // isLoading={carsApiLoading}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]}>
          <Controller
            control={control}
            name="toll_tags_ids"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIMultiSelectAutoSyncAndAsync
                isGetOptionsOnFocus
                name="toll_tags_ids"
                label="Tags"
                isError={!!error?.message}
                loadOptions={tollTagsAutocomplete}
                value={value}
                errorMessage={error?.message}
                onChange={onChange}
                placeholder="Selecione as tags..."
                isDisabled={!!isLoading}
              />
            )}
          />
        </GridItem>

        {!isTrailer && (
          <>
            <GridItem colSpan={[12, 4, 3]} opacity={isTrailer ? 0.1 : 1}>
              <Controller
                control={control}
                name="vehicle_category_id"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <UISelect
                    label="Categoria"
                    name="vehicle_category_id"
                    isRequired
                    isError={!!error?.message}
                    errorMessage={error?.message}
                    options={[{ label: '', value: '' }, ...(vehicleCategories || [])]}
                    value={value}
                    onChange={option => {
                      const hasTrailer = vehicleCategories.find(v => v.value === option.target.value)
                      dispatch(setHasTrailer(hasTrailer?.has_trailer))
                      onChange(option)
                    }}
                  />
                )}
              />
            </GridItem>
          </>
        )}
        <GridItem colSpan={[12, 4, isTrailer ? 6 : 3]}>
          <Controller
            control={control}
            name="vehicle_body_id"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label={isTrailer ? 'Reboque' : 'Carroceria'}
                isRequired
                isError={!!error?.message}
                name="vehicle_body_id"
                errorMessage={error?.message}
                options={[{ label: '', value: '' }, ...(vehicleBodies || [])]}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="release_year"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="9999"
                label="Ano fábrica"
                name="release_year"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="model_year"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="9999"
                name="model_year"
                label="Ano do Modelo"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 1]}>
          <Controller
            control={control}
            name="axes"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Eixos"
                name="axes"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value || ''}
                onChange={onChange}
                type="number"
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 5]}>
          <Controller
            control={control}
            name="brand"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Marca"
                name="brand"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="model"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Modelo"
                name="model"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="chassi"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="Chassi"
                name="chassi"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="capacity_m3"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                name="capacity_m3"
                label="Capacidade (m3)"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value || ''}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="capacity_tara"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                name="capacity_tara"
                label="Capacidade (TARA)"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value || ''}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="capacity_kg"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                type="number"
                name="capacity_kg"
                label="Capacidade (kg)"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value || ''}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="antt"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                mask="aaa9*99"
                label="Placa ANTT"
                name="antt"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 6]}>
          <Controller
            control={control}
            name="color"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelect
                label="Cor"
                name="color"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                options={vehicleColorsDatamex}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="has_insurance"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISwitch
                label="Tem seguro?"
                name="has_insurance"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                isChecked={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
        <GridItem colSpan={[12, 4, 3]}>
          <Controller
            control={control}
            name="has_tracker"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISwitch
                label="Tem rastreador?"
                name="has_tracker"
                isRequired
                isError={!!error?.message}
                errorMessage={error?.message}
                isChecked={value}
                onChange={onChange}
              />
            )}
          />
        </GridItem>
      </Grid>

      <Text fontSize="xs" opacity={0.5} my="2" mt="4">
        Informações de Endereço:
      </Text>
      <Grid templateColumns="repeat(12, 1fr)" gap={2}>
        <GridItem colSpan={[12, 6, 6]}>
          <Controller
            control={control}
            name="city_id"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UISelectAsync
                label="Cidade"
                placeholder="Cidade"
                name="city_id"
                loadOptions={searchCitiesByName}
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={option => {
                  setValue('license_uf', option?.uf || '')
                  onChange(option)
                }}
                value={
                  value || {
                    label: '',
                    value: '',
                  }
                }
                isRequired
              />
            )}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 1]}>
          <Controller
            control={control}
            name="license_uf"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <UIInput
                label="UF"
                name="license_uf"
                placeholder="UF"
                isError={!!error?.message}
                errorMessage={error?.message}
                onChange={onChange}
                value={value}
                isRequired
                isDisabled
              />
            )}
          />
        </GridItem>
      </Grid>
    </Box>
  )
}

export const vehicleFormInitialValues = {
  antt: '',
  axes: undefined,
  brand: '',
  capacity_kg: undefined,
  capacity_m3: undefined,
  capacity_tara: undefined,
  chassi: '',
  city_id: undefined,
  color: '',
  has_insurance: false,
  has_tracker: false,
  license_plate: '',
  license_uf: '',
  model: '',
  model_year: '',
  release_year: '',
  renavam: '',
  vehicle_body_id: '',
  vehicle_category_id: '',
}
