import {
  Badge,
  Box,
  BoxProps,
  Flex,
  Grid,
  GridItem,
  Heading,
  useColorModeValue,
  Text,
  VStack,
} from '@chakra-ui/react'
import { AiOutlineFieldNumber } from 'react-icons/ai'
import { FaTruckLoading, FaRegAddressCard, FaCalendarAlt } from 'react-icons/fa'
import { FiBox, FiMapPin, FiPhone, FiUserCheck } from 'react-icons/fi'
import { MdOutlineBusinessCenter, MdOutlineCarCrash, MdOutlineDirectionsCarFilled } from 'react-icons/md'
import { RiMoneyDollarBoxFill, RiMoneyDollarCircleLine } from 'react-icons/ri'
import { format } from 'date-fns'
import { BiSticker } from 'react-icons/bi'
import InfoBox from '../../../components/InfoBox'
import { FormattedOneFreight } from '../../../services/endpoints/freights'
import { conveyers } from '../../../services/utils/conveyers'
import parseCurrency from '../../../services/utils/parseCurrency'
import { formatBrandTags } from '../../../services/endpoints/vehicles/toll-tags/toll-tags-autocomplete'

interface GeneralInfosProps extends BoxProps {
  freight: FormattedOneFreight
}

const GeneralInfos = ({ freight }: GeneralInfosProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')
  const numberPhoneFormat = (phone?: string) => {
    if (!phone) return
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
  }

  const licensePlateFormat = (license_plate?: string) => {
    if (!license_plate) return
    return license_plate.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4')
  }

  const refClient =
    freight?.client_ref_type && freight?.client_ref_number
      ? `${freight?.client_ref_type?.toUpperCase()}-${freight?.client_ref_number}`
      : '-'

  const formatDate = (dateString?: string) => {
    if (!dateString) return '-'
    return format(new Date(dateString), 'dd/MM/yyyy HH:mm')
  }

  return (
    <Box aria-label="short freight details" bg={bg} rounded="lg" overflow="hidden" pb="2">
      <Heading m="4" size="md" fontWeight="medium">
        <Flex
          align={{
            base: 'start',
            md: 'start',
          }}
          flexDir={{
            base: 'column',
            md: 'row',
          }}
          gridGap="4"
        >
          Frete #{freight?.freight_number}
          <Flex align="center" gridGap="2">
            <Badge px="2" py="1" colorScheme="gray">
              {freight?.client?.nickname}
            </Badge>
            <Badge px="2" py="1" colorScheme="green">
              {freight?.formatted_status}
            </Badge>
            {freight?.transporter && (
              <Badge px="2" py="1" colorScheme="gray">
                {conveyers.find(c => c.value === freight?.transporter)?.label}
              </Badge>
            )}
          </Flex>
          <Flex
            justifyContent="flex-end"
            align={{
              base: 'end',
              md: 'center',
            }}
            gridGap="2"
            ml="auto"
          >
            <FaCalendarAlt color="gray.300" />
            <Text fontWeight="bold" fontSize="sm">
              Prev. coleta:
            </Text>
            <Text fontSize="sm">{formatDate(freight?.collect_cargo_at)}</Text>

            <FaCalendarAlt color="gray.300" />
            <Text fontWeight="bold" fontSize="sm">
              Prev. Entrega:
            </Text>
            <Text fontSize="sm">{formatDate(freight?.delivered_cargo_at)}</Text>
          </Flex>
        </Flex>
      </Heading>

      <VStack align="stretch" my="4" spacing={6}>
        <Grid
          templateColumns="repeat(12, 1fr)"
          gap="4"
          px="4"
          aria-label="short freight details company and requester"
        >
          <GridItem colSpan={[12, 3]}>
            <InfoBox
              color="green.500"
              title="Cliente:"
              content={freight?.client?.nickname}
              icon={MdOutlineBusinessCenter}
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <InfoBox
              color="green.500"
              title="Valor do cliente:"
              content={parseCurrency(freight.service_price)}
              icon={RiMoneyDollarBoxFill}
            />
          </GridItem>

          <GridItem colSpan={[12, 3]}>
            <InfoBox title="Solicitante:" content={freight?.client_contact?.name} icon={FiUserCheck} />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <InfoBox title="Nº ref cliente:" content={refClient} icon={AiOutlineFieldNumber} />
          </GridItem>
        </Grid>
      </VStack>

      {freight.motorist_id && (
        <Grid templateColumns="repeat(12, 1fr)" gap="4" p="4" bg="blackAlpha.50">
          <GridItem colSpan={[12, 3]}>
            <InfoBox title="Motorista" content={freight?.motorist?.name} icon={FaRegAddressCard} />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <InfoBox title="Telefone" content={numberPhoneFormat(freight?.motorist?.phone)} icon={FiPhone} />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <InfoBox
              title="Placa do veículo"
              content={licensePlateFormat(freight?.vehicle?.license_plate)}
              icon={MdOutlineDirectionsCarFilled}
            />
          </GridItem>
          <GridItem colSpan={[12, 3]}>
            <InfoBox title="Tipo de veículo" content={freight?.formatted_vehicle} icon={MdOutlineCarCrash} />
          </GridItem>
        </Grid>
      )}

      <Grid
        templateColumns="repeat(12, 1fr)"
        gap="4"
        mx="4"
        aria-label="short freight details origin and destination"
      >
        <GridItem colSpan={[12, 6]}>
          <InfoBox
            title="Endereço de origem:"
            content={freight?.origin_city}
            icon={FiMapPin}
            subInfoTitle="Endereço de coleta:"
            subInfoContent={freight?.collect_complement}
          />
        </GridItem>
        <GridItem colSpan={[12, 6]}>
          <InfoBox
            title="Endereço de destino:"
            content={freight?.destination_city}
            icon={FiMapPin}
            subInfoTitle="Endereço de entrega:"
            subInfoContent={freight?.delivery_complement}
          />
        </GridItem>
      </Grid>

      <Grid
        mt="4"
        templateColumns="repeat(12, 1fr)"
        gap="4"
        px="4"
        aria-label="short freight details cargo and value"
      >
        <GridItem colSpan={[12, 3]}>
          <InfoBox color="blue.500" title="Carga:" content={freight?.cargo?.name} icon={FiBox} />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <InfoBox
            color="blue.500"
            title="Valor de NF:"
            content={freight?.cargo_value}
            icon={RiMoneyDollarCircleLine}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <InfoBox
            title="Tipo de veículo"
            content={freight.vehicle_categories?.map(category => category.name).join(' / ')}
            icon={MdOutlineCarCrash}
          />
        </GridItem>

        <GridItem colSpan={[12, 3]}>
          <InfoBox
            title="Carrocerias"
            content={freight.vehicle_bodies?.map(body => body.name).join(' / ')}
            icon={FaTruckLoading}
          />
        </GridItem>
      </Grid>

      {freight.vehicle?.toll_tags && freight.vehicle.toll_tags.length > 0 && (
        <Grid
          mt="4"
          templateColumns="repeat(12, 1fr)"
          gap="4"
          px="4"
          aria-label="short freight details cargo and value"
        >
          <GridItem colSpan={[12, 12]}>
            <InfoBox
              title="Tags do veículo"
              content={freight.vehicle?.toll_tags?.map(tag => formatBrandTags(tag.brand)).join(' / ')}
              icon={BiSticker}
            />
          </GridItem>
        </Grid>
      )}
    </Box>
  )
}

export default GeneralInfos
