import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Link,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { format, utcToZonedTime } from 'date-fns-tz'
import ptLocale from 'date-fns/locale/pt-BR'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaClipboardList } from 'react-icons/fa'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import * as yup from 'yup'
import { ButtonsActions } from '../../../components/freight-contract/list/ButtonsActions'
import Filter from '../../../components/UI/Filter'
import { PageHeader } from '../../../components/UI/PageHeader'
import FixOverflow from '../../../hooks/fixOverflow'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../layout'
import { Pagination } from '../../../layout/Pagination'
import {
  FilterFreightContract,
  useGetAllFreightContracts,
} from '../../../services/endpoints/freight-contracts/getAllFreightContracts'
import parseCurrency from '../../../services/utils/parseCurrency'
import { boxWidth, displayAdaptable } from '../../../services/utils/tableAdaptable'
import { FiltersFreightContract } from '../Filters'

interface Filter extends FilterFreightContract {
  motorist_name?: { label: string; value: string }
}

const filterFormSchema = yup.object().shape({
  number: yup.number(),
  freight_number: yup.string(),
  emit_date_ini: yup.string(),
  emit_date_final: yup.string(),
  freight_id: yup.string(),
  motorist_name: yup.object().shape({}),
})

export function ListFreightContracts(): JSX.Element {
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    number?: string
    freight_number?: string
    motorist_name?: string
    emit_date_ini?: string
    emit_date_final?: string
    freight_id?: string
    page?: string
  }>()

  FixOverflow()
  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const [filters, setFilters] = useState<Filter>({
    emit_date_final: getParams?.('emit_date_final'),
    emit_date_ini: getParams?.('emit_date_ini'),
    number: getParams?.('number') ? Number(getParams?.('number')) : undefined,
    freight_number: getParams?.('freight_number'),
    motorist_name: getParams?.('motorist_name')
      ? {
          label: getParams?.('motorist_name'),
          value: getParams?.('motorist_name'),
        }
      : undefined,
    freight_id: getParams?.('freight_id'),
    page: getParams?.('page') ? Number(getParams?.('page')) : 1,
  })

  const bg = useColorModeValue('white', 'gray.800')

  const { data: freightContracts, isLoading } = useGetAllFreightContracts({
    ...filters,
    motorist_name: filters?.motorist_name?.label,
    page: filters?.page,
  })

  const { setValue, handleSubmit, formState } = useForm({
    resolver: yupResolver(filterFormSchema),
  })

  const { errors, isSubmitting } = formState

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  const handleFilter: SubmitHandler<Filter> = data => {
    setFilters({
      emit_date_ini: data.emit_date_ini,
      emit_date_final: data.emit_date_final,
      number: data.number,
      freight_number: data.freight_number,
      motorist_name: data.motorist_name,
      page: data.page || 1,
    })

    updateQueryParams({
      emit_date_ini: data.emit_date_ini,
      emit_date_final: data.emit_date_final,
      number: data.number?.toString(),
      freight_number: data.freight_number,
      motorist_name: data.motorist_name?.label,
      page: data.page?.toString() || '1',
    })
  }

  function handleFormatDate(value: Date | string) {
    const dataFormatada = value
      ? format(utcToZonedTime(value, 'America/Sao_Paulo'), 'dd/MM/yyyy', {
          timeZone: 'America/Sao_Paulo',
          locale: ptLocale,
        })
      : ''
    return dataFormatada
  }

  return (
    <Layout>
      <PageHeader
        title="Lista de contratos de frete"
        icon={FaClipboardList}
        rightContent={
          <>
            <Button
              leftIcon={<RiFilter2Line />}
              variant="outline"
              colorScheme="blue"
              onClick={onToggleFilter}
            >
              Filtros
            </Button>
          </>
        }
      />
      <Filter showFilter={onShowFilter}>
        <form onSubmit={handleSubmit(handleFilter)} noValidate>
          <FiltersFreightContract filters={filters} errors={errors} setValue={setValue} />

          <Flex mt="4" justify="flex-end">
            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} px="4" py="2" borderRadius="8" shadow="md" mb="8">
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            {freightContracts?.data && freightContracts?.data?.length > 0 ? (
              <Flex mt="4" w={boxWidth}>
                <Table size="sm" d={displayAdaptable} overflowX="auto">
                  <Thead>
                    <Tr>
                      <Th>NÚMERO</Th>
                      <Th>STATUS</Th>
                      <Th>TALÃO</Th>
                      <Th>EMISSÃO</Th>
                      <Th>MOTORISTA</Th>
                      <Th>ADIANTAMENTO</Th>
                      <Th>SALDO</Th>
                      <Th>VALOR TOTAL</Th>
                      <Th>VALOR PEDÁGIO</Th>
                      <Th>VALOR LÍQUIDO</Th>
                      <Th>FRETE</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {freightContracts.data.map(freightContract => (
                      <Tr key={freightContract.id}>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {freightContract.number}
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          <Badge
                            colorScheme={
                              freightContract.status === 'Cancelado'
                                ? 'red'
                                : freightContract.status === 'Gerado'
                                ? 'orange'
                                : 'green'
                            }
                            px="2"
                            py="1"
                          >
                            {freightContract.status}
                          </Badge>
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {freightContract.type.split(' - ')[1]}
                        </Td>
                        <Td fontSize="xs">{`${handleFormatDate(freightContract.emit_date)}`}</Td>
                        <Td fontSize="xs">{freightContract.motorist_name}</Td>
                        <Td fontSize="xs">{parseCurrency(freightContract.advance_value)}</Td>
                        <Td fontSize="xs">{parseCurrency(freightContract.balance)}</Td>
                        <Td>{parseCurrency(freightContract.total_value)}</Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {`${parseCurrency(freightContract.toll_value)}`}
                        </Td>
                        <Td fontSize="xs" whiteSpace="nowrap">
                          {`${parseCurrency(freightContract.freight_net_value)}`}
                        </Td>
                        <Td>
                          {freightContract.freight_id ? (
                            <Button
                              size="xs"
                              as={Link}
                              href={`/freights/show/${freightContract.freight_id}`}
                              colorScheme="blue"
                              target="_blank"
                              rel="noreferrer"
                            >
                              Frete
                            </Button>
                          ) : undefined}
                        </Td>
                        <Td>
                          <ButtonsActions freightContract={freightContract} />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            ) : (
              <Text textAlign="center">Nenhum resultado encontrado</Text>
            )}
          </>
        )}
        {freightContracts && freightContracts.total > 0 && (
          <Box mt={5}>
            <Pagination
              currentPage={filters?.page || 1}
              totalCountOfRegisters={freightContracts.total}
              onPageChange={handleChangePage}
            />
          </Box>
        )}
      </Box>
    </Layout>
  )
}
