import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { FaRegFile } from 'react-icons/fa'
import { FiUpload } from 'react-icons/fi'
import { toastError } from '../../../../config/error/toastError'
import useSearchVehicle from '../../../../hooks/useSearchVehicle'
import { usePostCrlvInformations } from '../../../../services/endpoints/documents/ocr/post-crl-informations'
import { convertPdfToImage } from '../../../../services/endpoints/documents/pdf/post-convert-pdf-to-image'
import { VehicleResponseFormData } from '../../../../services/endpoints/vehicles/getVehicle'
import { ResponseOcrCrlv } from '../../../../services/types'
import { parseBase64ToImage } from '../../../../services/utils/parseImageToBase64'
import { isValidateWidthImage } from '../../../../services/utils/validateWidthImage'
import { useAppDispatch, useAppSelector } from '../../../../store'
import {
  setCrlvTrailerFile,
  setCrlvTrailerImg,
  setCrlvVehicleFile,
  setCrlvVehicleImg,
  setIsNewImage,
} from '../../../../store/slices/ocrCrlvSlice'
import { CRLVinformations } from '../components/CRLVinformations'

type UploadCRLVModalProps = {
  onSaveInformations?: (CRLV: Partial<ResponseOcrCrlv>) => void
  onVehicleFound?: (vehicle: VehicleResponseFormData) => void
  type?: 'vehicle' | 'trailer'
}

const UploadCRLVModal = ({
  onSaveInformations,
  type = 'vehicle',
  onVehicleFound,
}: UploadCRLVModalProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [fileConvertLoading, setFileConvertLoading] = useState(false)
  const [CRLV, setCRLV] = useState<ResponseOcrCrlv | null>(null)
  const [vehicleFound, setVehicleFound] = useState<VehicleResponseFormData | null>(null)
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { crlvVehicleFile, crlvVehicleImg, crlvTrailerFile, crlvTrailerImg } = useAppSelector(
    state => state.ocrCrlvSlice,
  )
  const borderColor = useColorModeValue('gray.100', 'gray.500')
  const hasAttachment =
    type === 'vehicle' ? crlvVehicleFile?.name || crlvVehicleImg : crlvTrailerFile?.name || crlvTrailerImg
  const hasImageUrl = type === 'vehicle' ? crlvVehicleImg : crlvTrailerImg

  const { loading, search } = useSearchVehicle({
    onSuccess: data => {
      if (data.vehicle) {
        setVehicleFound(data.vehicle)
      }
    },
    type,
  })

  /**
   * funcao responsavel por mostrar toast
   * @param title
   * @param status
   */
  const showToast = useCallback(
    (title: string, status: 'info' | 'success' | 'error', description = '') => {
      toast({
        title,
        status,
        description,
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      })
    },
    [toast],
  )

  /**
   * funcao responsavel por converter o pdf para imagem
   * @param file
   *
   * faz a conversao do pdf para imagem e faz o dispatch para o reducer ocrCnh
   * setando a imagem e o arquivo convertido em base64
   *
   */
  const convertPdf = useCallback(
    async (file: File) => {
      setFileConvertLoading(true)
      showToast('Convertendo PDF para imagem', 'info')
      try {
        const resultConvertPdf = await convertPdfToImage({ file, slicePdf: true })
        if (resultConvertPdf) {
          const imageConvertedToFile = await parseBase64ToImage(resultConvertPdf.imageConverted)
          if (type === 'vehicle') {
            dispatch(setCrlvVehicleImg(resultConvertPdf.imageConverted))
            dispatch(setCrlvVehicleFile(imageConvertedToFile))
          } else {
            dispatch(setCrlvTrailerImg(resultConvertPdf.imageConverted))
            dispatch(setCrlvTrailerFile(imageConvertedToFile))
          }

          showToast('Imagem carregada com sucesso!', 'success')
        }
      } catch (error) {
        showToast('Erro ao converter PDF para imagem, por favor, tente novamente', 'error')
      } finally {
        setFileConvertLoading(false)
      }
    },
    [showToast, dispatch, type],
  )

  const { mutate: postCrlvInformations, isLoading: isCrlvInformationsLoading } = usePostCrlvInformations({
    onSuccess: data => {
      setCRLV(data)
      search(data.renavam)
    },
    onError: errorReq => {
      toastError({
        error: errorReq,
        toast,
      })
    },
  })

  const handleChangeFile = useCallback(
    async (file: File) => {
      const acceptedFormats = ['pdf', 'jpg', 'jpeg', 'png']
      const fileExtension = file.name.split('.').pop() as string

      if (!acceptedFormats.includes(fileExtension)) {
        showToast('Formato de imagem inválido', 'error')
        return
      }
      if (fileExtension === 'pdf') {
        convertPdf(file)
        return
      }
      isValidateWidthImage(file)
        .then(() => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64Image = reader.result
            if (type === 'trailer') {
              dispatch(setCrlvTrailerImg(base64Image as string))
              dispatch(setCrlvTrailerFile(file))
            } else {
              dispatch(setCrlvVehicleImg(base64Image as string))
              dispatch(setCrlvVehicleFile(file))
            }
          }

          reader.readAsDataURL(file)
        })
        .catch(() => {
          showToast('Formato de imagem inválido', 'error')
        })

      dispatch(setIsNewImage(true))
    },
    [dispatch, convertPdf, showToast, type],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { 'image/*': ['.pdf', '.jpg', '.jpeg', '.png'] },
    onDrop: acceptedFiles => handleChangeFile(acceptedFiles[0]),
    maxFiles: 1,
  })

  const handleGetDriverLicenseInformations = async () => {
    if (type === 'vehicle') {
      if (!crlvVehicleFile || !crlvVehicleImg) {
        showToast('Selecione uma imagem', 'error')
        return
      }
    }

    if (type === 'trailer') {
      if (!crlvTrailerFile || !crlvTrailerImg) {
        showToast('Selecione uma imagem', 'error')
        return
      }
    }

    postCrlvInformations(type === 'vehicle' ? crlvVehicleImg : crlvTrailerImg)
  }

  const handleSave = () => {
    if (!hasAttachment) {
      showToast('Selecione uma imagem', 'error')
      return
    }
    if (vehicleFound) {
      onVehicleFound?.(vehicleFound)
    } else if (CRLV) {
      onSaveInformations?.(CRLV)
    }
    onClose()
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems={{ base: 'flex-start', md: 'center' }}
      gridGap="4"
      flexDir={{ base: 'column', md: 'row' }}
      bg={hasAttachment ? 'green.50' : 'red.50'}
      p="2"
      rounded="md"
      border="1px solid"
      borderColor={hasAttachment ? 'green.500' : 'red.300'}
    >
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader py="2" fontWeight="bold" fontSize="sm">
            {hasAttachment ? 'Carregar novo CRLV' : 'Carregar CRLV'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            pointerEvents={isCrlvInformationsLoading || fileConvertLoading ? 'none' : 'auto'}
            opacity={isCrlvInformationsLoading || fileConvertLoading ? 0.5 : 1}
          >
            <Flex justifyContent="space-between" gridGap="4" flexDir={{ base: 'column', md: 'row' }}>
              <Flex flex={1} flexDir="column" gridGap="2">
                <Flex
                  {...getRootProps()}
                  borderRadius="md"
                  textAlign="center"
                  cursor="pointer"
                  _hover={{ borderColor: 'blue.400' }}
                  transition="border 0.2s"
                  justifyContent="center"
                  flexDirection="column"
                  alignItems="center"
                  height="400px"
                  borderWidth="2px"
                  borderColor={borderColor}
                  borderStyle="dashed"
                  role="group"
                  position="relative"
                >
                  <input {...getInputProps()} />
                  {fileConvertLoading && (
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      position="absolute"
                      w="99%"
                      h="99%"
                      mt={2}
                      p={3}
                      rounded="md"
                      sx={{
                        backdropFilter: 'blur(2px)',
                      }}
                    >
                      <Spinner />
                    </Flex>
                  )}
                  {!hasImageUrl && (
                    <Box fontSize="14px">
                      <Icon as={FiUpload} boxSize={8} mb={4} />
                      <Text size="xs">Solte o CRLV aqui ...</Text>
                      <Text size="xs">ou</Text>
                      <Button leftIcon={<FaRegFile />} size="sm" fontWeight="bold">
                        clique para selecionar
                      </Button>
                    </Box>
                  )}
                  <Box fontSize="14px" position="absolute" opacity={0} _groupHover={{ opacity: 1 }}>
                    <Icon as={FiUpload} boxSize={8} mb={4} />
                    <Text size="xs">Solte a CRLV aqui ...</Text>
                    <Text size="xs">ou</Text>
                    <Button leftIcon={<FaRegFile />} size="sm" fontWeight="bold">
                      clique para selecionar
                    </Button>
                  </Box>
                  {hasImageUrl && (
                    <Image
                      w="100%"
                      h="100%"
                      objectFit="contain"
                      src={hasImageUrl}
                      alt={type === 'vehicle' ? 'CRLV' : 'CRLV Trailer'}
                    />
                  )}
                  {isDragActive && <Text>Solte o arquivo aqui ...</Text>}
                </Flex>
                <Button
                  size="sm"
                  colorScheme="red"
                  onClick={handleGetDriverLicenseInformations}
                  isLoading={isCrlvInformationsLoading}
                  disabled={type === 'vehicle' ? !crlvVehicleFile : !crlvTrailerFile}
                >
                  Carregar dados via OCR
                </Button>
              </Flex>
              <Box flex={1}>
                <Box p="4" mb="8" border="1px" borderColor="gray.200" rounded="md">
                  <Heading fontSize="sm">Obter dados da CRLV via API</Heading>
                  <Text fontSize="sm">Por favor, Verifique se os dados da CRLV estão corretos.</Text>
                </Box>

                <Heading fontSize="sm">Informações da CRLV via API</Heading>
                <SimpleGrid mt="4" gridGap="2" opacity={CRLV ? 1 : 0.5}>
                  <CRLVinformations CRLV={CRLV} />
                </SimpleGrid>
                {hasImageUrl && (
                  <Link href={hasImageUrl} isExternal color="blue.500" fontSize="sm">
                    Baixar CRLV
                  </Link>
                )}
              </Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" colorScheme="gray" variant="outline" onClick={onClose} mr={3}>
              Fechar
            </Button>
            <Button
              colorScheme="green"
              size="sm"
              variant="solid"
              disabled={!hasAttachment}
              onClick={handleSave}
              isLoading={loading}
            >
              {CRLV ? 'Atualizar dados do formulário' : 'OK'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Flex gridGap="2" flexDir={{ base: 'column', md: 'row' }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          gap="2"
          p="2"
          w="50px"
          h="60px"
          bg={hasAttachment ? 'green.200' : 'red.100'}
          rounded="md"
          border="1px solid"
          borderColor={hasAttachment ? 'green.500' : 'red.500'}
        >
          <Icon as={FaRegFile} color={hasAttachment ? 'green.500' : 'red.400'} />
        </Box>
        <Box color="gray.800">
          <Heading fontSize="sm">{hasAttachment ? 'CRLV anexado' : 'CRLV não anexado'}</Heading>
          <Text fontSize="sm">Carregue a CRLV para obter os dados automaticamente e anexar.</Text>

          <Link
            href={hasImageUrl}
            pointerEvents={hasAttachment ? 'auto' : 'none'}
            opacity={hasAttachment ? 1 : 0.5}
            isExternal
            color="blue.500"
            fontSize="sm"
          >
            Baixar CRLV
          </Link>
        </Box>
      </Flex>
      <Box>
        <Button size="sm" colorScheme={hasAttachment ? 'green' : 'red'} onClick={onOpen}>
          {hasAttachment ? 'Anexar novo CRLV' : 'Anexar CRLV'}
        </Button>
      </Box>
    </Flex>
  )
}

export default UploadCRLVModal
