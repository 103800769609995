import { Divider, Flex, Grid, GridItem, Heading } from '@chakra-ui/react'
import { useContext, useState } from 'react'
import { DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { Select } from '../../../../../../components/form'
import {
  alternativeRouteOptions,
  cargoTypeOptions,
  freightTypeOptions,
  truckCategoryOptions,
  typeRouteOptions,
  vehicleCategoryOptions,
} from '../../formOptions'
import { RoutePlannerContext } from '../../ShowRoutePlanner'

type HandleRoutesFormProps = {
  errors: DeepMap<FieldValues, FieldError>
  setValue: UseFormSetValue<FieldValues>
}

export function HandleRoutesForm({ setValue, errors }: HandleRoutesFormProps): JSX.Element {
  const [vehicleCategory, setVehicleCategory] = useState<string>('truck')
  const { state } = useContext(RoutePlannerContext)

  const freightVehicleCategory = state.freight?.vehicle_categories?.[0]?.axes.toString()

  return (
    <>
      <Flex p={4} bg={state.theme.cardBackground} rounded="md" flexDirection="column" mt={4}>
        <Flex justifyContent="space-between">
          <Heading fontSize="2xl">Dados do veículo</Heading>
        </Flex>

        <Divider my={2} />

        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={[12, 6, 12]}>
            <Select
              name="vehicleCategory"
              label="Categoria do Veículo"
              setValue={setValue}
              onSelectOption={option => setVehicleCategory(option.value)}
              error={errors.vehicleCategory}
              initialValue="truck"
              options={vehicleCategoryOptions}
            />
          </GridItem>

          {vehicleCategory === 'truck' && (
            <GridItem colSpan={[12, 6, 12]}>
              <Select
                name="truckCategory"
                label="Selecione o tipo de caminhão"
                setValue={setValue}
                error={errors.truckCategory}
                initialValue={freightVehicleCategory || ''}
                options={truckCategoryOptions}
              />
            </GridItem>
          )}
        </Grid>
      </Flex>

      <Flex p={4} bg={state.theme.cardBackground} rounded="md" flexDirection="column" mt={4}>
        <Flex justifyContent="space-between">
          <Heading fontSize="2xl">Dados do frete</Heading>
        </Flex>

        <Divider my={2} />

        <Grid templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={[12, 6, 12]}>
            <Select
              name="freight_type"
              label="Tipo do Frete"
              setValue={setValue}
              error={errors.freight_type}
              initialValue="A"
              options={freightTypeOptions}
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 12]}>
            <Select
              name="cargoType"
              label="Tipo de Carga"
              setValue={setValue}
              error={errors.cargoType}
              options={cargoTypeOptions}
              initialValue="geral"
            />
          </GridItem>
        </Grid>
      </Flex>

      <Flex p={4} bg={state.theme.cardBackground} rounded="md" flexDirection="column" mt={4}>
        <Flex justifyContent="space-between">
          <Heading fontSize="2xl">Configurações de rota</Heading>
        </Flex>

        <Divider my={2} />

        <Grid templateColumns="1fr auto" gap={4} alignItems="center">
          <GridItem colSpan={[12, 6, 12]}>
            <Select
              name="typeRoute"
              label="Tipo da Rota"
              setValue={setValue}
              error={errors.typeRoute}
              initialValue="efficient"
              options={typeRouteOptions}
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 12]}>
            <Select
              name="alternativeRoutes"
              label="Qtd. Rotas Alternativas"
              setValue={setValue}
              error={errors.alternativeRoutes}
              initialValue="3"
              options={alternativeRouteOptions}
            />
          </GridItem>

          <GridItem colSpan={[12, 6, 12]}>
            <Select
              name="optimizedRoute"
              label="Otimizar Rota"
              setValue={setValue}
              error={errors.optimizedRoute}
              initialValue="no"
              options={[
                { value: 'yes', label: 'Sim' },
                { value: 'no', label: 'Não' },
              ]}
            />
          </GridItem>
        </Grid>
      </Flex>
    </>
  )
}
