import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Divider,
  Flex,
  Progress,
  SimpleGrid,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tooltip,
  Tr,
  useToast,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { RiLineChartLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { useAuth } from '../../../contexts/AuthContext'
import { Layout } from '../../../layout'
import { useReportsFeedbacksClient } from '../../../services/endpoints/clients/reportsFeedbacksClient'
import { useGetCountFreights } from '../../../services/endpoints/freights/getCountFreight'
import { useGetIndividualGoalExpectations } from '../../../services/endpoints/freights/getIndividualGoalExpectations'
import { getStatusZApiInstance } from '../../../services/endpoints/freights/getStatusZApiInstance'
import { useReportsFeedbacksMotorist } from '../../../services/endpoints/motorists/reportsFeedbacksMotorist'
import parseCurrency from '../../../services/utils/parseCurrency'
import { apexOptions } from '../apexcharts/FreightsPeriodMonths'
import { ComercialCard } from './cards/ComercialCard'
import { FeedbacksByMotoristAndClientCard } from './cards/FeedbacksByMotoristAndClientCard'
import { FreightsActiveCard } from './cards/FreightsActiveCard'
import { FreightsCurrentCard } from './cards/FreightsCurrentCard'
import { FreightsDisabledCard } from './cards/FreightsDisabled.Card'
import { FreightsFinishedCard } from './cards/FreightsFinishedCard'
import { FreightsMonitoredCard } from './cards/FreightsMonitoredCard'
import { FreightsNoShowCard } from './cards/FreightsNoShowCard'
import { ReportMotoristsCard } from './cards/ReportMotoristsCard'

interface ListCardsDashboardProps {
  bg: string
}

export function ListCardsDashboard({ bg }: ListCardsDashboardProps): JSX.Element {
  const toast = useToast()
  const { user } = useAuth()
  const [initialDate, setInitialDate] = useState<string>(() => {
    return format(new Date(), 'yyyy-MM-01')
  })
  const [finalDate, setFinalDate] = useState<string>(() => {
    return format(new Date(), 'yyyy-MM-dd')
  })

  const {
    data: countFreights,
    isLoading,
    isFetching,
    error: errorCountFreights,
    isError: isErrorCountFreights,
  } = useGetCountFreights({
    initial_date: initialDate,
    final_date: finalDate,
  })

  useEffect(() => {
    if (isErrorCountFreights) {
      toastError({ toast, error: errorCountFreights })
    }
  }, [isErrorCountFreights, errorCountFreights, toast])

  const { data: dataStatusZApi, isLoading: isLoadingStatusZApi } = getStatusZApiInstance()

  const freightsActive = countFreights?.pending_freights
  const freightsCurrent = countFreights?.current_freights
  const freightsFinished = countFreights?.finished_freights
  const freightsNoShow = countFreights?.no_show
  const freightsDisable = countFreights?.disabled
  const freightsMonitored = {
    freightsMonitoredFinished: countFreights?.monitored_freights || 0,
    freightsFinished: countFreights?.finished_freights.total || 0,
    // freightsAerials: countFreights?.finished_freights.aerial || 0,
  }

  const feedbackResponseClient = useReportsFeedbacksClient({
    initialDateFeedbacksClient: initialDate,
    finalDateFeedbacksClient: finalDate,
  })

  const feedbackResponseMotorist = useReportsFeedbacksMotorist({
    initialDate,
    finalDate,
  })

  useEffect(() => {
    if (feedbackResponseClient.isError) {
      toastError({ toast, error: feedbackResponseClient.error })
    }
  }, [feedbackResponseClient.isError, feedbackResponseClient.error, toast])

  useEffect(() => {
    if (feedbackResponseMotorist.isError) {
      toastError({ toast, error: feedbackResponseMotorist.error })
    }
  }, [feedbackResponseMotorist.isError, feedbackResponseMotorist.error, toast])

  const dataChartPeriodMonths = [freightsFinished?.total, freightsNoShow, freightsDisable]

  const {
    data: individualGoalExpectations,
    isLoading: isLoadingIndividualGoalExpectations,
    isError: isErrorIndividualGoalExpectations,
  } = useGetIndividualGoalExpectations({
    user_id: user.id,
    initial_date: `${initialDate}T00:00:00`,
    final_date: `${finalDate}T23:59:59`,
  })

  return (
    <Layout
      initialDate={initialDate}
      finalDate={finalDate}
      setInitialDate={setInitialDate}
      setFinalDate={setFinalDate}
      SidebarMobile
      isFilters
    >
      <SimpleGrid gap="4" minChildWidth="300px" align="flex-start" mt="-4">
        <FreightsActiveCard
          isLoading={isLoading}
          isFetching={isFetching}
          error={isErrorCountFreights}
          freightsActive={freightsActive}
          bg={bg}
        />

        <FreightsCurrentCard
          isLoading={isLoading}
          isFetching={isFetching}
          error={isErrorCountFreights}
          freightsCurrent={freightsCurrent}
          bg={bg}
        />

        <Flex
          p={['2', '3']}
          bg={!isLoading ? bg : undefined}
          borderRadius={8}
          shadow="md"
          direction="column"
          gridGap="5"
        >
          <FreightsFinishedCard
            isLoading={isLoading}
            isFetching={isFetching}
            error={isErrorCountFreights}
            freightsFinished={freightsFinished}
          />

          <FreightsNoShowCard
            isLoading={isLoading}
            isFetching={isFetching}
            error={isErrorCountFreights}
            freightsNoShow={freightsNoShow}
          />

          <FreightsDisabledCard
            isLoading={isLoading}
            isFetching={isFetching}
            error={isErrorCountFreights}
            freightsDisabled={freightsDisable}
          />
        </Flex>

        <ReportMotoristsCard bg={bg} />

        <FreightsMonitoredCard
          isLoading={isLoading}
          isFetching={isFetching}
          error={isErrorCountFreights}
          freightsMonitored={freightsMonitored}
          freightsAerials={+String(countFreights?.aerial_freights)}
          bg={bg}
        />

        {!isLoadingIndividualGoalExpectations ? (
          individualGoalExpectations ? (
            <Box p={4} bg={bg} borderRadius={8} shadow="md">
              <Text fontSize="md" fontWeight="bold">
                Acompanhamento de metas
              </Text>
              <Text fontSize="sm" color="gray.400">
                Acompanhe seu progresso diário e mensal
              </Text>

              <Flex justifyContent="space-between" alignItems="center" mt={2}>
                <Text fontSize="sm">Faturamento do mês:</Text>
                <Badge fontSize="md" colorScheme="green">
                  <Tooltip label="Valor total dos CTes neste mês" hasArrow placement="top">
                    {parseCurrency(individualGoalExpectations.monthly_revenue)}
                  </Tooltip>
                </Badge>
              </Flex>

              <Divider mt={2} />

              <Text fontSize="md" fontWeight="bold" mt={2}>
                Metas do vendedor
              </Text>

              <Box mt={1}>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="smaller" fontWeight="bold">
                    Meta Diária
                  </Text>

                  <Text fontSize="12px" display="flex">
                    <Tooltip label="Meu fechamento até o momento" hasArrow placement="top">
                      {parseCurrency(individualGoalExpectations.user_monthly_revenue)}
                    </Tooltip>
                    <Text marginLeft={1} marginRight={1}>
                      /
                    </Text>
                    <Tooltip label="Meu fechamento esperado do dia" hasArrow placement="top">
                      {parseCurrency(individualGoalExpectations.todayGoalExpectations.value)}
                    </Tooltip>
                  </Text>
                </Flex>

                <Progress
                  colorScheme="blue"
                  height="10px"
                  value={individualGoalExpectations.todayGoalExpectations.percentage}
                  w="100%"
                  borderRadius="md"
                />

                <Flex justifyContent="right">
                  <Text fontSize="12px">
                    {Math.ceil(individualGoalExpectations.todayGoalExpectations.percentage)} %
                  </Text>
                </Flex>
              </Box>

              <Divider mt={1} mb={1} />

              <Box>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text fontSize="smaller" fontWeight="bold">
                    Meta Mensal
                  </Text>

                  <Text fontSize="12px" display="flex">
                    <Tooltip label="Meu fechamento até o momento" hasArrow placement="top">
                      {parseCurrency(individualGoalExpectations.user_monthly_revenue)}
                    </Tooltip>
                    <Text marginLeft={1} marginRight={1}>
                      /
                    </Text>
                    <Tooltip label="Meu fechamento esperado do mês" hasArrow placement="top">
                      {parseCurrency(individualGoalExpectations.monthlyGoalExpectations.value)}
                    </Tooltip>
                  </Text>
                </Flex>

                <Progress
                  colorScheme="blue"
                  height="10px"
                  value={individualGoalExpectations.monthlyGoalExpectations.percentage}
                  w="100%"
                  borderRadius="md"
                />

                <Flex justifyContent="right">
                  <Text fontSize="12px">
                    {Math.ceil(individualGoalExpectations.monthlyGoalExpectations.percentage)} %
                  </Text>
                </Flex>
              </Box>
            </Box>
          ) : isErrorIndividualGoalExpectations ? (
            <Alert status="warning">
              <AlertIcon />
              Falha ao carregar dados das metas. Verifique a conexão com a internet ou entre em contato com a
              equipe de suporte do sistema.
            </Alert>
          ) : null
        ) : (
          <Flex borderRadius={8} shadow="md" p={4} bg={bg} justifyContent="center" alignItems="center">
            <Spinner mr={2} />
            <Text>Carregando metas individuais</Text>
          </Flex>
        )}

        {isLoading ? (
          <Flex justify="center">
            <Spinner ml={2} />
          </Flex>
        ) : isErrorCountFreights ? (
          <Alert status="warning">
            <AlertIcon />
            Falha ao carregar dados dos fretes. Verifique a conexão com a internet ou entre em contato com a
            equipe de suporte do sistema.
          </Alert>
        ) : (
          <Box bg={bg} borderRadius={8} shadow="md">
            <Flex gridGap="2" fontSize="lg" pt="4" alignItems="center" pl="4" mb="2" fontWeight="bold">
              <Tooltip placement="top" label="Relatório de fretes" hasArrow>
                <Flex>
                  <Link to="/freights-reports">
                    <RiLineChartLine />
                  </Link>
                </Flex>
              </Tooltip>
              GRÁFICO DE FRETES DO MÊS
            </Flex>
            <Chart type="pie" height={163} options={apexOptions} series={dataChartPeriodMonths} />
          </Box>
        )}

        {/* {isLoadingTruckpad ? (
          <Flex justify="center">
            <Spinner ml={2} />
          </Flex>
        ) : isErroTruckpad ? (
          <Alert status="warning">
            <AlertIcon />
            Falha ao carregar dados do Truckpad. Verifique a conexão com a internet ou entre em contato com a
            equipe de suporte do sistema.
          </Alert>
        ) : (
          data && (
            <Box bg={bg} borderRadius={8} shadow="md">
              <Flex gridGap="2" fontSize="lg" pt="4" pl="4" alignItems="center" mb="3" fontWeight="bold">
                <Flex fontSize="2xl">
                  <MdAttachMoney />
                </Flex>
                SALDO DO TRUCKPAD {isFetchingTruckpad && <Spinner ml={2} />}
              </Flex>
              <Flex justifyContent="center" alignItems="center" fontSize="3xl" fontWeight="bold" h="50%">
                {parseCurrency(data.Value)}
              </Flex>
            </Box>
          )
        )} */}

        <FeedbacksByMotoristAndClientCard
          countFeedbackClient={feedbackResponseClient}
          countFeedbackMotorist={feedbackResponseMotorist}
          bg={bg}
        />

        <ComercialCard />

        {isLoadingStatusZApi ? (
          <Flex justify="center">
            <Spinner ml={2} />
          </Flex>
        ) : isErrorCountFreights ? (
          <Alert status="warning">
            <AlertIcon />
            Falha ao carregar dados do Zapi. Verifique a conexão com a internet ou entre em contato com a
            equipe de suporte do sistema.
          </Alert>
        ) : (
          <Box p={['2', '3']} bg={bg} borderRadius={8} shadow="md">
            <Flex
              justifyContent="space-between"
              pl="5"
              pr="5"
              mt={1}
              alignItems="baseline"
              mb="4"
              fontWeight="bold"
            >
              <Text fontSize="lg">STATUS CONEXÃO Z-API</Text>
            </Flex>
            <Flex mt="8" justify="flex-end">
              <Table size="sm">
                <Tbody>
                  <Tr>
                    <Td>
                      <Text fontSize="md" color="gray.500">
                        STATUS DO BOT:
                      </Text>
                    </Td>
                    <Td fontSize="md" color="green.500" isNumeric>
                      {dataStatusZApi?.data ? (
                        <Tooltip label="Está tudo certo com o Z-API." aria-label="A tooltip">
                          <Badge colorScheme="green">CONECTADO</Badge>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          label="Z-API DESCONECTADO! Chame alguém do time de Tech para conectar."
                          aria-label="A tooltip"
                        >
                          <Badge colorScheme="red">DESCONECTADO</Badge>
                        </Tooltip>
                      )}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Flex>
          </Box>
        )}
      </SimpleGrid>
    </Layout>
  )
}
