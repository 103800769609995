/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Divider, Grid, GridItem, Heading, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import {
  Autocomplete,
  Input,
  InputNumberMask,
  Select,
  SelectOption,
  TextArea,
} from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import useQueryParamUpdater from '../../../../../hooks/useQueryParamUpdater'
import { BalanceRequestTypeFormatted } from '../../../../../services/endpoints/freights/getBalanceRequest'
import { getFreightByNumber } from '../../../../../services/endpoints/freights/getFreightByNumber'
import { bankList } from '../../../../../services/utils/bankList'

type CreateOrUpdateBalanceRequestFormType = {
  errors: DeepMap<FieldValues, FieldError>
  setValue: UseFormSetValue<FieldValues>
  initial_value?: BalanceRequestTypeFormatted
}

type CustomFormValuesType = {
  client?: {
    label: string
  }
  ctes?: SelectOption[]
  motorist?: {
    name: string
    rate_score?: number
  }
}

export function CreateOrUpdateBalanceRequestForm({
  errors,
  setValue,
  initial_value,
}: CreateOrUpdateBalanceRequestFormType): JSX.Element {
  const toast = useToast()
  const [paymentMethod, setPaymentMethod] = useState('')
  const [is_efrete, setIsEfrete] = useState('no')
  const [freightNumber, setFreightNumber] = useState<string>('')
  const [customFormValues, setCustomFormValues] = useState<CustomFormValuesType>()
  const { getParams } = useQueryParamUpdater<{
    freight_number?: string
  }>()

  const getFormData = useCallback(
    async (freight_number?: string): Promise<void> => {
      let freight
      let motorist

      if (freight_number) {
        toast({
          title: 'Buscando dados do frete!',
          status: 'info',
          position: 'top',
          isClosable: true,
          duration: 3000,
        })

        freight = await getFreightByNumber(freight_number)

        if (freight) {
          setValue('freight_id', freight.id)
          motorist = freight.motorist

          toast({
            title: 'Frete encontrado com sucesso!',
            status: 'success',
            position: 'top',
            isClosable: true,
            duration: 3000,
          })
        }
      } else {
        freight = initial_value?.freight
        setPaymentMethod(initial_value?.pix ? 'pix' : 'account')
      }

      if (freight) {
        const customFormValuesData: CustomFormValuesType = {
          client: {
            label: freight.client.name,
          },
        }

        if (freight.ctes && freight.ctes.length > 0) {
          const { ctes } = freight
          const ctesFormated = ctes.map(cte => ({ label: String(cte.nct), value: cte.id }))
          Object.assign(customFormValuesData, {
            ctes: ctesFormated,
          })
        }

        if (motorist) {
          Object.assign(customFormValuesData, {
            motorist: {
              name: motorist.name,
              rate_score: motorist.rate_score,
            },
          })
        } else {
          toastError({ toast, error: 'Frete não possui motorista' })
        }

        setCustomFormValues(customFormValuesData)
      }
    },
    [initial_value, setValue, toast],
  )

  useEffect(() => {
    if (
      (freightNumber && freightNumber.length >= 4) ||
      (initial_value && initial_value.freight?.freight_number)
    ) {
      getFormData(freightNumber)
    }
  }, [freightNumber, toast, initial_value, getFormData])

  useEffect(() => {
    const freightNumberQuery = getParams('freight_number')

    if (freightNumberQuery) {
      setFreightNumber(freightNumberQuery)
    }
  }, [getParams, setValue])

  return (
    <>
      <Heading size="md" fontWeight="normal" mb="5">
        Informações do frete
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            label="Número do frete"
            name="freight_number"
            error={errors.freight_number}
            placeholder="Número do frete"
            type="number"
            autoFocus
            setValue={setValue}
            isDisabled={!!initial_value?.freight?.freight_number}
            onChange={e => setFreightNumber(e.target.value)}
            initialValue={initial_value?.freight?.freight_number || freightNumber}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 9]} mr={2}>
          <Input
            title="Cliente"
            label="Cliente"
            name="client"
            error={errors.client}
            placeholder="Cliente"
            setValue={setValue}
            initialValue={customFormValues?.client?.label}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]} mr={2}>
          <Input
            title="Motorista"
            label="Motorista"
            name="motorist"
            error={errors.motorist}
            placeholder="Motorista"
            setValue={setValue}
            initialValue={customFormValues?.motorist?.name}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            title="Nota Motorista"
            label="Nota Motorista"
            name="rate_score"
            error={errors.rate_score}
            placeholder="Nota Motorista"
            setValue={setValue}
            initialValue={customFormValues?.motorist?.rate_score}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="CTe"
            name="cte_id"
            error={errors.cte_id}
            setValue={setValue}
            options={
              customFormValues?.ctes?.length
                ? [{ label: '', value: '' }, ...customFormValues?.ctes]
                : [{ label: 'Frete não possui CTe', value: '' }]
            }
            isDisabled={!customFormValues}
            initialValue={initial_value?.cte_id}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="Desconto Buonny"
            name="discount_buonny"
            error={errors.discount_buonny}
            setValue={setValue}
            options={[
              { label: 'Não', value: '0' },
              { label: 'R$ 35', value: '35' },
              { label: 'R$ 45', value: '45' },
            ]}
            isDisabled={!customFormValues}
            initialValue={String(initial_value?.discount_buonny)}
            isRequired
          />
        </GridItem>
      </Grid>

      <Divider my="4" />

      <Heading size="md" fontWeight="normal" mb="5">
        Informações solicitação de saldo
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <InputNumberMask
            label="Valor Total"
            name="total_value"
            error={errors.total_value}
            placeholder="Valor Total"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={Number(initial_value?.total_value)}
            isCashBRL
            isRequired
          />
        </GridItem>
      </Grid>

      <Divider my="4" />

      <Heading size="md" fontWeight="normal" mb="5">
        Informações de pagamento
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, paymentMethod === 'pix' ? 3 : 4]} mr={2}>
          <Select
            label="E-Frete"
            name="e_frete"
            setValue={setValue}
            error={errors.e_frete}
            options={[
              {
                label: 'Não',
                value: 'no',
              },
              {
                label: 'Sim',
                value: 'yes',
              },
            ]}
            onSelectOption={option => {
              setIsEfrete(option.value)
              setPaymentMethod('')
              setValue('payment_type', '')
            }}
            isDisabled={!customFormValues}
            initialValue={initial_value?.e_frete ? 'yes' : 'no'}
          />
        </GridItem>

        {is_efrete === 'no' && (
          <GridItem colSpan={[12, 6, paymentMethod === 'pix' ? 3 : 4]} mr={2}>
            <Select
              label="Tipo de pagamento"
              name="payment_type"
              error={errors.payment_type}
              setValue={setValue}
              options={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'Conta bancaria',
                  value: 'account',
                },
                {
                  label: 'Pix',
                  value: 'pix',
                },
              ]}
              onSelectOption={option => {
                setPaymentMethod(option.value)
              }}
              initialValue={paymentMethod}
              isDisabled={!customFormValues}
            />
          </GridItem>
        )}

        {paymentMethod === 'pix' && (
          <GridItem colSpan={[12, 6, 6]} mr={2}>
            <Input
              name="pix"
              error={errors.pix}
              label="Pix"
              setValue={setValue}
              isDisabled={!customFormValues}
              initialValue={initial_value?.pix}
            />
          </GridItem>
        )}

        {paymentMethod === 'account' && (
          <>
            <GridItem colSpan={[12, 6, 4]} mr={2}>
              <Select
                name="account_type"
                error={errors.account_type}
                label="Tipo de conta"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Corrente', value: 'current' },
                  { label: 'Poupança', value: 'savings' },
                  { label: 'Outros', value: 'others' },
                ]}
                initialValue={initial_value?.account_type}
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 4]}>
              <Autocomplete
                name="bank"
                error={errors.bank}
                label="Banco"
                setValue={setValue}
                options={[...bankList]}
                initialValue={
                  initial_value?.bank
                    ? {
                        label: initial_value?.bank,
                        value: initial_value?.bank,
                      }
                    : undefined
                }
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Input
                name="agency"
                error={errors.agency}
                label="Agencia"
                setValue={setValue}
                initialValue={initial_value?.agency}
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              <Input
                name="account"
                error={errors.account}
                label="Conta"
                setValue={setValue}
                initialValue={initial_value?.account}
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 2]}>
              <Input
                name="account_digit"
                error={errors.account_digit}
                label="Cód. Banco"
                setValue={setValue}
                initialValue={initial_value?.account_digit}
                isDisabled={!customFormValues}
              />
            </GridItem>
          </>
        )}
      </Grid>

      <Divider my="4" />

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 12]}>
          <TextArea
            name="observation"
            h="150"
            error={errors.observation}
            label="Observação"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.observation}
          />
        </GridItem>
      </Grid>
    </>
  )
}
