/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'

import { apiServer } from '../../api'

async function getPdfMdfeDatamex(key: string) {
  const data = await apiServer.get(`/document-handler/mdfes/generate-pdf/${key}`)
  return data.data.data
}
export function useGetPdfMdfeDatamex(options?: UseMutationOptions<AxiosResponse, unknown, string, unknown>) {
  return useMutation(async (key: string) => getPdfMdfeDatamex(key), options)
}
