/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Button,
  Grid,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { FaArrowRight } from 'react-icons/fa'
import { MdPersonSearch } from 'react-icons/md'
import { RiCheckLine } from 'react-icons/ri'
import { useHistory } from 'react-router-dom'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { usePostCostRiskAnalysis } from '../../../services/endpoints/risk-analysis/cost-risk-analysis/postCostRiskAnalysis'
import { usePatchRiskAnalysis } from '../../../services/endpoints/risk-analysis/patchRiskAnalysis'
import {
  InputInsertProfessionalToBuonny,
  useInsertProfessionalToBuonny,
} from '../../../services/endpoints/risk-management/insert-professional'
import { useAppSelector } from '../../../store'
import { FormSendToBuonny } from '../../freights/pendencies/components/send-to-buonny/FormSendToBuonny'
import DisapprovedButton from './action-buttons/DisapprovedButton'
import ToCorrectButton from './action-buttons/ToCorrectButton'

interface ModalProps {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
}

export function ShowRegisterMotoristToBuonnyModal({ setValue, formState }: ModalProps): JSX.Element {
  const toast = useToast()
  const history = useHistory()
  const { mutateAsync: mutateAsyncChangeStatus, isLoading: isLoadingChangeStatus } = usePatchRiskAnalysis()
  const insertProfissionalToBuonny = useInsertProfessionalToBuonny({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Ficha do profissional enviada para buonny com sucesso',
        position: 'top-right',
      })
      queryClient.invalidateQueries('one-risk-analysis')
      queryClient.invalidateQueries('cost-risk-analysis')
      queryClient.invalidateQueries('check-cost-risk-analysis')
    },
  })
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { riskAnalysis } = useAppSelector(state => state.riskAnalysisSlice)

  const { mutateAsync: mutateAsyncCreateCostRiskAnalysis, isLoading: isLoadingCost } =
    usePostCostRiskAnalysis({
      onSuccess: () => {
        queryClient.invalidateQueries('cost-risk-analysis')
        queryClient.invalidateQueries('check-cost-risk-analysis')
      },
    })

  const handleIncludeMotoristToBuonny = async () => {
    if (
      riskAnalysis &&
      riskAnalysis.freight &&
      riskAnalysis.motorist &&
      riskAnalysis.vehicle &&
      riskAnalysis.vehicle.city &&
      riskAnalysis.vehicle.owner &&
      riskAnalysis.vehicle.antt_owner &&
      riskAnalysis.vehicle.owner.city &&
      riskAnalysis.vehicle.owner.city.state &&
      riskAnalysis.motorist.address_city &&
      riskAnalysis.motorist.driver_license &&
      riskAnalysis.motorist.attachments
    ) {
      toast({
        status: 'warning',
        title: 'Enviando dados para buonny',
        description: 'Aguarde até obter a resposta',
        position: 'top-right',
        isClosable: true,
        duration: 2000,
      })

      try {
        const hasTrailer = riskAnalysis.vehicle.trailers && riskAnalysis.vehicle.trailers.length > 0

        const motoristAttachment = riskAnalysis.motorist.attachments.find(attach => attach.type === 'cnh')

        if (!motoristAttachment?.attachment_file_url) throw new Error('Motorista sem a imagem da CNH')
        const motoristImageUrl = motoristAttachment.attachment_file_url

        const insertMotoristData: InputInsertProfessionalToBuonny = {
          product: 2,
          cnpj_shipper: riskAnalysis.freight.client.cnpj_shipper,
          cnpj_transporter: '37991170000195',
          buonny_cargo_type: riskAnalysis.freight.buonny_cargo_type,
          buonny_cargo_price: riskAnalysis.freight.buonny_cargo_price,
          origin: {
            name: riskAnalysis.freight.origin.name,
            state: {
              uf: riskAnalysis.freight.origin.state.uf,
            },
          },
          destination: {
            name: riskAnalysis.freight.destination.name,
            state: {
              uf: riskAnalysis.freight.destination.state.uf,
            },
          },
          return_contact: {
            name: 'Fernanda Salino',
            contact_type: 2,
            return_type: 1,
            description: 'fernanda.salino@spotx.com.br',
          },
          professional: {
            image_professional: motoristImageUrl,
            category: riskAnalysis.motorist.motorist_category,
            document: riskAnalysis.motorist.cpf!,
            name: riskAnalysis.motorist.name,
            father_name: riskAnalysis.motorist.father_name!,
            mother_name: riskAnalysis.motorist.mother_name!,
            rg: riskAnalysis.motorist.rg!,
            rg_state: riskAnalysis.motorist.rg_uf!,
            rg_issue_date: String(riskAnalysis.motorist.rg_dispatch_date),
            birthday: String(riskAnalysis.motorist.birth),
            birth_city: {
              name: riskAnalysis.motorist.birth_city || riskAnalysis.motorist.address_city.name,
              state: {
                uf: riskAnalysis.motorist.birth_state || riskAnalysis.motorist.address_city.state.uf,
              },
            },
            cnh: {
              cnh: riskAnalysis.motorist.driver_license.register_number!,
              category: riskAnalysis.motorist.driver_license.category!,
              validity: String(riskAnalysis.motorist.driver_license.validity),
              state: riskAnalysis.motorist.driver_license.dispatcher_uf!,
              first_cnh_date: String(riskAnalysis.motorist.driver_license.first_dispatch_date),
              security_code: riskAnalysis.motorist.driver_license.insurance_number!,
            },
            address: {
              state: riskAnalysis.motorist.address_city.state.uf,
              city: riskAnalysis.motorist.address_city.name,
              zipcode: riskAnalysis.motorist.zipcode!,
              district: riskAnalysis.motorist.district!,
              address: riskAnalysis.motorist.address!,
              number: Number(riskAnalysis.motorist.address_number),
            },
            contacts: [
              {
                name: riskAnalysis.motorist.name,
                contact_type: 1,
                return_type: 1,
                description: riskAnalysis.motorist.phone,
              },
            ],
          },
          vehicles: [
            {
              license_plate: riskAnalysis.vehicle.license_plate!,
              chassi: riskAnalysis.vehicle.chassi!,
              renavam: riskAnalysis.vehicle.renavam!,
              city_licensed: {
                name: riskAnalysis.vehicle.city.name,
                state: {
                  uf: riskAnalysis.vehicle.city.state.uf,
                },
              },
              vehicle_color: riskAnalysis.vehicle.color!,
              release_year: riskAnalysis.vehicle.release_year!,
              model: riskAnalysis.vehicle.model_buonny ? riskAnalysis.vehicle.model_buonny : undefined,
              model_year: riskAnalysis.vehicle.release_year!,
              fabricator: riskAnalysis.vehicle.brand_buonny ? riskAnalysis.vehicle.brand_buonny : undefined,
              technology: riskAnalysis.vehicle.technology ? riskAnalysis.vehicle.technology : undefined,
              owner: {
                document:
                  riskAnalysis.vehicle.owner.type === 'pf'
                    ? riskAnalysis.vehicle.owner.cpf!
                    : riskAnalysis.vehicle.owner.cnpj!,
                company_name: riskAnalysis.vehicle.owner.name,
                rg_ie:
                  riskAnalysis.vehicle.owner.type === 'pf'
                    ? riskAnalysis.vehicle.owner.rg!
                    : riskAnalysis.vehicle.owner.ie!,
                address: {
                  state: riskAnalysis.vehicle.owner.city.state.uf,
                  city: riskAnalysis.vehicle.owner.city.name,
                  zipcode: riskAnalysis.vehicle.owner.zipcode!,
                  district: riskAnalysis.vehicle.owner.district!,
                  address: riskAnalysis.vehicle.owner.address!,
                  number: Number(riskAnalysis.vehicle.owner.address_number),
                },
                contacts: [
                  {
                    name: riskAnalysis.vehicle.owner.name,
                    contact_type: 1,
                    return_type: 1,
                    description: riskAnalysis.vehicle.owner.phone!,
                  },
                ],
              },
              antt_owner: {
                rntrc: riskAnalysis.vehicle.antt_owner.rntrc,
              },
            },
          ],
          additional_data: {
            was_stolen_in_last_24_months: riskAnalysis.motorist.was_stolen_in_last_24_months,
            stolen_times: riskAnalysis.motorist.stolen_times
              ? Number(riskAnalysis.motorist.stolen_times)
              : undefined,
            had_accident: riskAnalysis.motorist.had_accident,
            accident_times: riskAnalysis.motorist.accident_times
              ? Number(riskAnalysis.motorist.accident_times)
              : undefined,
            how_long_carry_to_company: riskAnalysis.motorist.how_long_carry_to_company?.toString(),
            carry_times: riskAnalysis.motorist.carry_times,
            has_tracking_system: !!riskAnalysis.vehicle.has_tracker,
            tracking_system: riskAnalysis.vehicle.tracking_system || undefined,
            respond_for_crime_in_last_10_years: riskAnalysis.motorist.respond_for_crime_in_last_10_years,
            crime_process_numbers: !!riskAnalysis.motorist.crime_process_numbers,
          },
        }

        if (
          hasTrailer &&
          riskAnalysis.vehicle &&
          riskAnalysis.vehicle.trailers &&
          riskAnalysis.vehicle.trailers.length > 0
        ) {
          const [trailer] = riskAnalysis.vehicle.trailers
          if (trailer && trailer.owner && trailer.city && trailer.owner.city) {
            insertMotoristData.vehicles.push({
              license_plate: trailer.license_plate!,
              chassi: trailer.chassi!,
              renavam: trailer.renavam!,
              city_licensed: {
                name: trailer.city.name,
                state: {
                  uf: trailer.city.state.uf,
                },
              },
              vehicle_color: trailer.color!,
              release_year: trailer.release_year!,
              model: trailer.model_buonny!,
              model_year: trailer.release_year!,
              fabricator: trailer.brand_buonny ? trailer.brand_buonny : undefined,
              technology: trailer.technology ? trailer.technology : undefined,
              owner: {
                document: trailer.owner.type === 'pf' ? trailer.owner.cpf! : trailer.owner.cnpj!,
                company_name: trailer.owner.name,
                rg_ie: trailer.owner.type === 'pf' ? trailer.owner.rg! : trailer.owner.ie!,
                address: {
                  state: trailer.owner.city.state.uf,
                  city: trailer.owner.city.name,
                  zipcode: trailer.owner.zipcode!,
                  district: trailer.owner.district!,
                  address: trailer.owner.address!,
                  number: Number(trailer.owner.address_number),
                },
                contacts: [
                  {
                    name: trailer.owner.name,
                    contact_type: 1,
                    return_type: 1,
                    description: trailer.owner.phone!,
                  },
                ],
              },
              antt_owner: {
                rntrc: trailer.owner.rntrc || riskAnalysis.vehicle.antt_owner.rntrc,
              },
            })
          }
        }

        await insertProfissionalToBuonny.mutateAsync(insertMotoristData)

        await mutateAsyncCreateCostRiskAnalysis({
          risk_manager: 'buonny',
          value: 32,
          risk_analysis_id: riskAnalysis.id,
          freight_id: riskAnalysis.freight_id,
          motorist_id: riskAnalysis.motorist_id,
        })

        await mutateAsyncChangeStatus({
          id: riskAnalysis.id,
          status: 'awaiting_gr',
        })
        onClose()
      } catch (error) {
        toastError({ toast, error })
      }
    }
  }

  return (
    <>
      {riskAnalysis && riskAnalysis.freight && riskAnalysis.motorist && riskAnalysis.vehicle && (
        <>
          <Button
            colorScheme="green"
            // isDisabled={cpfInBlackList}
            onClick={() => {
              onOpen()
            }}
            leftIcon={<Icon as={RiCheckLine} />}
          >
            Cadastrar ou atualizar dados
          </Button>

          <Modal
            isOpen={isOpen}
            onClose={() => {
              onClose()
            }}
            closeOnOverlayClick={false}
            // scrollBehavior="inside"
            size="5xl"
          >
            <ModalOverlay />

            <ModalContent>
              <ModalHeader>Dados para atualizar cadastro na buonny</ModalHeader>

              <ModalCloseButton />
              <ModalBody>
                <Grid templateColumns="repeat(12, 1fr)" gap={4} alignItems="center">
                  <FormSendToBuonny
                    isOnlyShow
                    formState={formState}
                    freight={riskAnalysis.freight}
                    motorist={riskAnalysis.motorist}
                    setValue={setValue}
                    vehicle={riskAnalysis.vehicle}
                  />
                </Grid>
              </ModalBody>
              <ModalFooter>
                <Stack direction={['column', 'row']}>
                  <DisapprovedButton />
                  <Button
                    colorScheme="yellow"
                    size="sm"
                    onClick={() => {
                      history.push(
                        `/freights/show/${riskAnalysis.freight_id}/pendencies?motorist_id=${riskAnalysis.motorist_id}&vehicle_id=${riskAnalysis.vehicle_id}&risk_analysis_id=${riskAnalysis.id}&gr_page=true`,
                      )
                    }}
                    rightIcon={<FaArrowRight />}
                  >
                    Corrigir cadastros
                  </Button>
                  <ToCorrectButton />
                  <Button
                    size="sm"
                    rightIcon={<MdPersonSearch size={20} />}
                    colorScheme="green"
                    isLoading={insertProfissionalToBuonny.isLoading || isLoadingChangeStatus || isLoadingCost}
                    onClick={() => {
                      handleIncludeMotoristToBuonny()
                    }}
                  >
                    Enviar dados para buonny
                  </Button>
                </Stack>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  )
}
