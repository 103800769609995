import { useQuery, UseQueryResult } from 'react-query'
import { localStorageKeys } from '../../../contexts/AuthContext'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import { UserAuthorization } from '../../types/Authorization'

type Input = { users: string[] }
type Output = UserAuthorization

export async function updatePermissionsByUsersOnStorage(params: Input): Promise<Output> {
  const { data } = await apiServer.get<ResponseApiType<Output[]>>('/authorization/permissions/users', {
    params,
  })
  if (data.data && data.data.length > 0) {
    localStorage.setItem(localStorageKeys.permissionsByUsers, JSON.stringify(data.data[0]))
  } else {
    localStorage.removeItem(localStorageKeys.permissionsByUsers)
  }
  return data.data[0]
}

export function useUpdatePermissionsByUsersOnStorage(params: Input): UseQueryResult<Output, unknown> {
  return useQuery(
    ['updatePermissionsByUsersOnStorage', params],
    () => updatePermissionsByUsersOnStorage(params),
    {
      enabled: !!params.users[0],
      refetchOnWindowFocus: false,
      refetchInterval: 1000 * 60 * 60 * 2, // 2 hours
    },
  )
}
