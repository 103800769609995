import { Button, Grid, GridItem } from '@chakra-ui/react'
import { FieldValues, FormState, UseFormSetValue } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { AutocompleteMultiAsync, AutocompleteOption, RadioGroup } from '../../../../components/form'
import { getAllUsersWithoutSuper } from '../../../../services/endpoints/users/getAllUsersWithoutSuper'

export type FormEditPermissionsToUsers = {
  users: AutocompleteOption[]
  permissionType: 'can' | 'cannot'
}

type Props = {
  setValue: UseFormSetValue<FieldValues>
  formState: FormState<FieldValues>
}

export const editUsersPermissionsValidateSchema = yup.object().shape({
  users: yup.array().of(yup.object()).required('Selecione ao menos um usuário'),
  permissionType: yup.string().required('Escolha uma ação'),
})

export function FormEditPermissionsToUsers({ formState, setValue }: Props): JSX.Element {
  const history = useHistory()

  return (
    <Grid templateColumns="repeat(12, 1fr)" gap="4">
      <GridItem colSpan={[12, 12, 12, 12, 7]}>
        <AutocompleteMultiAsync
          setValue={setValue}
          name="users"
          placeholder="Selecione os usuários a para configurar as permissões"
          loadOptions={getAllUsersWithoutSuper}
          error={formState.errors.users}
        />
      </GridItem>
      <GridItem colSpan={[10, 10, 10, 10, 3]}>
        <RadioGroup
          mt="3"
          direction={['row']}
          name="permissionType"
          error={formState.errors.permissionType}
          setValue={setValue}
          options={[
            { label: 'Permitir', value: 'can' },
            { label: 'Negar', value: 'cannot' },
          ]}
        />
      </GridItem>
      <GridItem colSpan={[3, 1]}>
        <Button colorScheme="blue" variant="outline" onClick={() => history.goBack()}>
          Cancelar
        </Button>
      </GridItem>

      <GridItem colSpan={[3, 1]}>
        <Button type="submit" colorScheme="orange" variant="outline" isLoading={formState.isSubmitting}>
          Salvar
        </Button>
      </GridItem>
    </Grid>
  )
}
