import { Skeleton, Table, TableContainer, Flex, Box } from '@chakra-ui/react'

export function SkeletonLoadingTable(): JSX.Element {
  return (
    <TableContainer>
      <Table>
        <Flex gridGap={6} direction="column">
          <Skeleton>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
          </Skeleton>
          <Skeleton>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
          </Skeleton>
          <Skeleton>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
          </Skeleton>
          <Skeleton>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
          </Skeleton>
          <Skeleton>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
          </Skeleton>
          <Skeleton>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
            <Box>loading</Box>
          </Skeleton>
        </Flex>
      </Table>
    </TableContainer>
  )
}
