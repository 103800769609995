import {
  Box,
  Flex,
  Icon,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useState } from 'react'
import { RiDeleteBin6Line, RiRadarFill, RiSmartphoneLine, RiWhatsappLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Ability } from '../../../components/ability'
import { AlertDialogConfirm } from '../../../components/AlertDialogConfirm'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useDeleteRiskAnalysis } from '../../../services/endpoints/risk-analysis/deleteRiskAnalysis'
import { GetRiskAnalysisResponse } from '../../../services/endpoints/risk-analysis/getAllRiskAnalysis'
import parseCurrency from '../../../services/utils/parseCurrency'
import { displayAdaptable } from '../../../services/utils/tableAdaptable'
import { useAppDispatch } from '../../../store'
import { setRiskAnalysisId } from '../../../store/slices/riskAnalysisSlice'
import ToForceApprovalIcon from '../modals/actions-icons/ToForceApprovalIcon'

interface TableListProps {
  riskAnalysis?: GetRiskAnalysisResponse
}

export function TableListAllRiskAnalysisPage({ riskAnalysis }: TableListProps): JSX.Element {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const { isOpen: isOpenConfirm, onClose: onCloseConfirm, onOpen: onOpenConfirm } = useDisclosure()

  const [idSelected, setIdSelected] = useState<string>()

  const colorLineTimeline = useColorModeValue('red.300', 'red.500')

  const { mutateAsync: mutateAsyncDelete } = useDeleteRiskAnalysis({
    onSuccess: () => {
      queryClient.invalidateQueries('one-risk-analysis')
      queryClient.invalidateQueries('one-risk-analysis-by-options')
      queryClient.invalidateQueries('list-risk-analysis')
      queryClient.invalidateQueries('compatible_motorists')
      queryClient.invalidateQueries('list-risk-analysis-history')
    },
  })

  const handleRemoveFromRiskAnalysis = async (riskAnalysisId: string) => {
    try {
      await mutateAsyncDelete(riskAnalysisId)
      toast({
        title: 'Item removido com sucesso!',
        isClosable: true,
        position: 'top-right',
        status: 'success',
      })
    } catch (error) {
      toastError({ toast, error })
    }
  }

  return (
    <>
      <Table size="sm" my={4} d={displayAdaptable} overflowX="auto">
        <Thead>
          <Tr>
            <Th>Frete</Th>
            <Th>Status</Th>
            <Th>Motor.</Th>
            <Th>Veículo</Th>
            <Th>Placa</Th>
            <Th>Cliente</Th>
            {/* <Th whiteSpace="nowrap">Check List</Th> */}
            <Th>Cod. buonny</Th>
            <Th>Usuário</Th>
            <Th>Data</Th>
            <Th>Custos</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {riskAnalysis &&
            riskAnalysis.data.map(item => {
              const trBgColor = item?.freight?.buonny_value_out_of_range ? colorLineTimeline : undefined
              return (
                <Tr key={item.id} bgColor={trBgColor}>
                  <Td whiteSpace="nowrap">
                    <Flex gridGap={4}>
                      <Tooltip
                        label={`Ver análise do frete #${item?.freight?.freight_number}`}
                        hasArrow
                        placement="auto"
                      >
                        <Link to={`risk-analyst-infos/${item.id}`}>
                          <Text fontSize="xs">#{item?.freight?.freight_number}</Text>
                        </Link>
                      </Tooltip>
                      {item?.freight?.has_monitoring_buonny && (
                        <Icon as={RiRadarFill} fontSize={18} color="#00a0dc" />
                      )}
                      {item?.freight?.has_monitoring_app && (
                        <Icon as={RiSmartphoneLine} fontSize={18} color="#00a0dc" />
                      )}
                      {item?.freight?.has_monitoring_whatsapp && (
                        <Icon as={RiWhatsappLine} fontSize={18} color="#00a0dc" />
                      )}
                    </Flex>
                  </Td>

                  <Td whiteSpace="nowrap">
                    <Text fontSize="md">
                      <Tag colorScheme={item.color_status} p="2" borderRadius="full" gridGap={2}>
                        <Text fontSize="xs" fontWeight="bold">
                          {item?.status}
                        </Text>
                      </Tag>
                    </Text>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Text fontSize="xs">{item?.motorist?.name.split(' ')[0]}</Text>
                    <Text fontSize="xs">{item?.motorist?.name.split(' ')[1]}</Text>
                  </Td>
                  <Td whiteSpace="nowrap">
                    <Text fontSize="xs">{item?.vehicle?.vehicle_category?.name}</Text>
                    <Text fontSize="xs">{item?.vehicle?.vehicle_body?.name}</Text>
                  </Td>
                  <Td>{item?.vehicle?.license_plate}</Td>
                  <Td>{item?.freight?.client?.name}</Td>
                  {/* <Td whiteSpace="nowrap">
                    {item?.depends_check && (
                      <Flex gridGap={2} direction="column" alignItems="center">
                        <Tag
                          colorScheme={item?.depends_check === 'Sim' ? 'green' : 'red'}
                          p="2"
                          borderRadius="full"
                        >
                          <Text fontSize="xs" fontWeight="bold">
                            {item?.depends_check}
                          </Text>
                        </Tag>
                      </Flex>
                    )}
                  </Td> */}

                  <Td whiteSpace="nowrap">
                    {item?.consultation_code && (
                      <Text fontSize="xs">{item?.consultation_code.substring(0, 35)}</Text>
                    )}
                  </Td>
                  <Td whiteSpace="nowrap">
                    {item?.user?.name && (
                      <>
                        <Text fontSize="xs">{item?.user?.name.split(' ')[0]}</Text>
                        <Text fontSize="xs">{item?.user?.name.split(' ')[1]}</Text>
                      </>
                    )}
                  </Td>

                  <Td whiteSpace="nowrap">
                    {item?.created_at && (
                      <>
                        <Text fontSize="xs">{item?.created_at.split('-')[0]}</Text>
                        <Text fontSize="xs">{item?.created_at.split('-')[1]}</Text>
                      </>
                    )}
                  </Td>

                  <Td whiteSpace="nowrap">
                    {item?.costRiskAnalysisPaidOut === 'Pendente' ? (
                      <>
                        <Text fontSize="xs">{item?.costRiskAnalysisPaidOut}</Text>
                      </>
                    ) : (
                      <Text fontSize="xs">
                        {parseCurrency(
                          item.costRiskAnalysis?.reduce((acc, elem) => Number(acc) + Number(elem.value), 0),
                        )}
                      </Text>
                    )}
                  </Td>

                  <Td whiteSpace="nowrap">
                    <Flex gridGap={2} alignItems="center">
                      {item.status !== 'Aprovado' && (
                        <ToForceApprovalIcon onClick={() => dispatch(setRiskAnalysisId(item.id))} />
                      )}

                      <Ability module="operation" action="delete-risk-analysis">
                        <Tooltip hasArrow label="Remover remover da análise de risco.">
                          <Box
                            cursor="pointer"
                            color="red"
                            onClick={() => {
                              onOpenConfirm()
                              setIdSelected(item.id)
                            }}
                          >
                            <Icon fontSize="2xl" as={RiDeleteBin6Line} />
                          </Box>
                        </Tooltip>
                      </Ability>
                    </Flex>
                  </Td>
                </Tr>
              )
            })}
        </Tbody>
      </Table>

      {idSelected && (
        <AlertDialogConfirm
          isOpen={isOpenConfirm}
          title="Remover da análise de risco"
          description="Tem certeza que deseja remover este item da lista de análise de risco?"
          onClose={onCloseConfirm}
          onConfirm={() => handleRemoveFromRiskAnalysis(idSelected)}
        />
      )}
    </>
  )
}
