import { Box, Button, Flex, Grid, GridItem, Icon, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaTruckArrowRight } from 'react-icons/fa6'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import {
  Autocomplete,
  InputMask,
  Input,
  AutocompleteAsync,
  AutocompleteMultiAsync,
  AutocompleteMulti,
} from '../../../../components/form'
import { AutocompleteOption } from '../../../../components/form/types/AutocompleteOption'
import { ReportToExcel } from '../../../../components/reports/ReportToExcel'
import { SkeletonLoadingTable } from '../../../../components/SkeletonLoadingTable'
import Filter from '../../../../components/UI/Filter'
import { PageHeader } from '../../../../components/UI/PageHeader'
import { useAuth } from '../../../../contexts/AuthContext'
import useQueryParamUpdater from '../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../layout'
import { Pagination } from '../../../../layout/Pagination'
import { searchCitiesByName } from '../../../../services/endpoints/cities/searchCities'
import { getContactsAutocomplete } from '../../../../services/endpoints/clients/client-contacts/getContactsAutocomplete'
import { searchClientsByName } from '../../../../services/endpoints/clients/searchClients'
import {
  FiltersFreightsParams,
  freightStatusInProgressOptions,
  ManyStatus,
} from '../../../../services/endpoints/freights/getFreights'
import { searchMotoristByName } from '../../../../services/endpoints/motorists/searchMotoristByName'
import {
  fetchAllFreightsTolls,
  useGetFreightsTolls,
} from '../../../../services/endpoints/truckpad/getFreightsTolls'
import { getUserOperation } from '../../../../services/endpoints/users/getUserOperation'
import { getUserSales } from '../../../../services/endpoints/users/getUserSales'
import {
  decodeEncodeAutocompleteToObject,
  encodeAutocompleteToUrl,
} from '../../../../services/utils/AutocompleteToUrl'
import { TableContainerFreightsTollStatus } from './table'
import { freightsTollsTitleColumns } from './table/TrComponent'

interface FiltersFreights {
  motorist_id?: AutocompleteOption
  client_id?: AutocompleteOption
  creator_id?: AutocompleteOption
  seller_id?: AutocompleteOption
  client_contact_id?: AutocompleteOption[]
  origin_id?: AutocompleteOption
  destination_id?: AutocompleteOption
  is_monitoring?: boolean
  status?: AutocompleteOption[]
  many_status?: string[]
  freight_number?: number | string
  initial_date?: Date | string
  final_date?: Date | string
  has_monitoring_type?: 'app' | 'buonny' | 'whatsapp' | 'tracker_devices'
  place_vehicle?: string
  page?: number
  per_page?: number
}

export function FreightsTollStatus(): JSX.Element {
  const bgMain = useColorModeValue('white', 'gray.900')

  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const {
    getUserIdToListFreights,
    getSellerIdToListFreights,
    setUserIdToListFreights,
    setSellerIdToListFreights,
  } = useAuth()

  const {
    addPage,
    getParams,
    getArrayParamsFromUrl,
    upsertQueryParams,
    upsertArrayParamsToUrl,
    removeQueryParam,
  } = useQueryParamUpdater<{
    page?: string
    initial_date?: string
    final_date?: string
    freight_number?: string
    origin_id?: string
    destination_id?: string
    creator_id?: string
    seller_id?: string
    client_id?: string
    client_contact_id?: string[]
    status?: string[]
    place_vehicle?: string
    motorist_id?: string
  }>()

  const [filters, setFilters] = useState<FiltersFreightsParams>(() => {
    return {
      page: getParams('page') ? Number(getParams('page')) : 1,
    }
  })
  const { setValue, handleSubmit, formState, getValues } = useForm<FiltersFreights>({})

  const {
    data: allFreights,
    isLoading: isLoadingAllFreights,
    isFetching: isFetchingAllFreights,
  } = useGetFreightsTolls({
    ...filters,
    many_status: filters?.status
      ? (filters.status as ManyStatus)
      : ['pending', 'in_origin', 'collecting', 'ready', 'on_road', 'in_destination', 'delivered', 'finished'],
    initial_date: filters?.initial_date,
    final_date: filters?.final_date,
    per_page: 15,
  })

  const handleFilterFreightsInProgress: SubmitHandler<FiltersFreights> = async data => {
    setFilters({
      ...({
        initial_date: data.initial_date,
        final_date: data.final_date,
        freight_number: data.freight_number,
        status: data.status?.map(status => status.value),
        motorist_id: data.motorist_id?.value,
        has_monitoring_app: data.has_monitoring_type === 'app' ? true : undefined,
        has_monitoring_buonny: data.has_monitoring_type === 'buonny' ? true : undefined,
        has_monitoring_whatsapp: data.has_monitoring_type === 'whatsapp' ? true : undefined,
        has_monitoring_tracker_devices: data.has_monitoring_type === 'tracker_devices' ? true : undefined,
        place_vehicle: data.place_vehicle,
        origin_id: data.origin_id?.value,
        destination_id: data.destination_id?.value,
        client_contact_id: data.client_contact_id?.map(contact => contact.value),
        client_id: data.client_id?.value,
        seller_id: data.seller_id?.value,
        user_id: data.creator_id?.value,
      } as FiltersFreightsParams),
    })
    upsertQueryParams({
      initial_date: data.initial_date?.toString() || undefined,
      final_date: data.final_date?.toString() || undefined,
      freight_number: data.freight_number?.toString() || undefined,
      origin_id: encodeAutocompleteToUrl(data.origin_id || undefined),
      destination_id: encodeAutocompleteToUrl(data.destination_id || undefined),
      creator_id: encodeAutocompleteToUrl(data.creator_id || undefined),
      seller_id: encodeAutocompleteToUrl(data.seller_id || undefined),
      client_id: encodeAutocompleteToUrl(data.client_id || undefined),
      place_vehicle: data.place_vehicle || undefined,
      motorist_id: encodeAutocompleteToUrl(data.motorist_id || undefined),
    })
    upsertArrayParamsToUrl('client_contact_id', data.client_contact_id)
    upsertArrayParamsToUrl('status', data.status)
  }

  useEffect(() => {
    const decripteQueryParams = (string: string, key: string): AutocompleteOption | undefined => {
      if (!string) {
        return undefined
      }
      const decoded = decodeEncodeAutocompleteToObject(string)
      if (!decoded) {
        removeQueryParam(key)
        return undefined
      }
      return Array.isArray(decoded) ? decoded[0] : (decoded as AutocompleteOption)
    }

    const initialDateQuery = getParams('initial_date')
    const finalDateQuery = getParams('final_date')
    const freightNumberQuery = getParams('freight_number')
    const originIdQuery = getParams('origin_id')
    const destinationIdQuery = getParams('destination_id')
    const creatorIdQuery = getParams('creator_id')
    const sellerIdQuery = getParams('seller_id')
    const clientIdQuery = getParams('client_id')
    const clientContactIdQuery = getArrayParamsFromUrl('client_contact_id')
    const statusQuery = getArrayParamsFromUrl('status')
    const placeVehicleQuery = getParams('place_vehicle')
    const motoristIdQuery = getParams('motorist_id')

    if (
      initialDateQuery ||
      finalDateQuery ||
      freightNumberQuery ||
      originIdQuery ||
      destinationIdQuery ||
      creatorIdQuery ||
      sellerIdQuery ||
      clientIdQuery ||
      clientContactIdQuery ||
      statusQuery ||
      placeVehicleQuery ||
      motoristIdQuery
    ) {
      setValue('freight_number', Number(freightNumberQuery) || undefined)
      setValue('initial_date', initialDateQuery || undefined)
      setValue('final_date', finalDateQuery || undefined)
      setValue('origin_id', decripteQueryParams(originIdQuery, 'origin_id') || undefined)
      setValue('destination_id', decripteQueryParams(destinationIdQuery, 'destination_id') || undefined)
      setValue('creator_id', decripteQueryParams(creatorIdQuery, 'creator_id') || undefined)
      setValue('seller_id', decripteQueryParams(sellerIdQuery, 'seller_id') || undefined)
      setValue('client_id', decripteQueryParams(clientIdQuery, 'client_id') || undefined)
      setValue('client_contact_id', clientContactIdQuery || undefined)
      setValue('status', statusQuery || undefined)
      setValue('place_vehicle', placeVehicleQuery || undefined)
      setValue('motorist_id', decripteQueryParams(motoristIdQuery, 'motorist_id') || undefined)

      handleSubmit(handleFilterFreightsInProgress)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      getUserIdToListFreights()
      getSellerIdToListFreights()
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFreights, filters])

  return (
    <Layout SidebarMobile>
      <PageHeader
        title="Lista de pedágio dos fretes"
        icon={FaTruckArrowRight}
        rightContent={
          <>
            <Button
              leftIcon={<RiFilter2Line />}
              variant="outline"
              colorScheme="blue"
              onClick={onToggleFilter}
            >
              Filtros
            </Button>
          </>
        }
      />
      <Filter showFilter={onShowFilter}>
        <form onSubmit={handleSubmit(handleFilterFreightsInProgress)} noValidate>
          {!formState.isSubmitting && (
            <Grid templateColumns="repeat(12, 1fr)" gap="3">
              <GridItem colSpan={[12, 6, 3]} mr={2}>
                <Input
                  type="date"
                  name="initial_date"
                  setValue={setValue}
                  initialValue={getValues('initial_date')}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <Input
                  type="date"
                  name="final_date"
                  setValue={setValue}
                  initialValue={getValues('final_date')}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <AutocompleteAsync
                  name="origin_id"
                  placeholder="Origem"
                  initialValue={getValues('origin_id')}
                  setValue={setValue}
                  loadOptions={searchCitiesByName}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <AutocompleteAsync
                  name="destination_id"
                  placeholder="Destino"
                  initialValue={getValues('destination_id')}
                  setValue={setValue}
                  loadOptions={searchCitiesByName}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <Autocomplete
                  name="creator_id"
                  placeholder="Analista"
                  initialValue={getValues('creator_id')}
                  setValue={setValue}
                  onSelectOption={userSelected => {
                    if (userSelected.value) {
                      setUserIdToListFreights(userSelected)
                      getUserIdToListFreights()
                    } else {
                      setUserIdToListFreights()
                      getUserIdToListFreights()
                    }
                  }}
                  clearState={() => {
                    setUserIdToListFreights(undefined)
                    getUserIdToListFreights()
                  }}
                  loadOptions={getUserOperation}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <Autocomplete
                  name="seller_id"
                  placeholder="Vendedor"
                  initialValue={getValues('seller_id')}
                  setValue={setValue}
                  onSelectOption={userSelected => {
                    if (userSelected.value) {
                      setSellerIdToListFreights(userSelected)
                      getSellerIdToListFreights()
                    } else {
                      setSellerIdToListFreights()
                      getSellerIdToListFreights()
                    }
                  }}
                  clearState={() => {
                    setSellerIdToListFreights(undefined)
                    getSellerIdToListFreights()
                  }}
                  loadOptions={getUserSales}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <InputMask
                  mask="999999999999999999"
                  name="freight_number"
                  initialValue={getValues('freight_number')?.toString()}
                  placeholder="Número do frete"
                  setValue={setValue}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <AutocompleteAsync
                  name="client_id"
                  placeholder="Cliente"
                  initialValue={getValues('client_id')}
                  setValue={setValue}
                  loadOptions={searchClientsByName}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 3]}>
                <AutocompleteMulti
                  name="status"
                  placeholder="Status"
                  setValue={setValue}
                  options={[
                    { label: '', value: '' },
                    { label: 'Ativo', value: 'pending' },
                    ...freightStatusInProgressOptions,
                  ]}
                  initialValue={getArrayParamsFromUrl('status')}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 2]}>
                <InputMask
                  name="place_vehicle"
                  mask="aaa9*99"
                  maskPlaceholder=""
                  placeholder="Placa"
                  setValue={setValue}
                  uppercaseAll
                  initialValue={getValues('place_vehicle')}
                />
              </GridItem>
              <GridItem colSpan={[12, 8, 7]}>
                <AutocompleteAsync
                  name="motorist_id"
                  setValue={setValue}
                  placeholder="Motorista"
                  initialValue={getValues('motorist_id')}
                  loadOptions={searchMotoristByName}
                />
              </GridItem>

              <GridItem colSpan={[12, 6, 12]}>
                <AutocompleteMultiAsync
                  name="client_contact_id"
                  placeholder="Solicitante"
                  setValue={setValue}
                  loadOptions={getContactsAutocomplete}
                  initialValue={getArrayParamsFromUrl('client_contact_id')}
                />
              </GridItem>
            </Grid>
          )}

          <Flex justify="flex-end" mt="4" gridGap="2">
            {allFreights && allFreights.data?.length > 0 && (
              <ReportToExcel
                isLoading={false}
                documentTitle="Relatório de pedágio dos fretes"
                columnsTitle={freightsTollsTitleColumns}
                onGenerateReport={async () => {
                  const report = await fetchAllFreightsTolls({
                    ...filters,
                    initial_date: filters?.initial_date,
                    final_date: filters?.final_date,
                    unpaged: true,
                  })
                  return report.data.map(freight => {
                    return [
                      freight.freight_number,
                      freight.formatted_has_tag,
                      freight.formatted_client_name,
                      freight.collect_cargo_at_pt_br,
                      freight.cargo_delivery_estimate,
                      freight.formatted_origin_name,
                      freight.formatted_destination_name,
                      freight.formatted_status,
                      freight.formatted_toll_value,
                      freight.formatted_value_vpo,
                      freight.formatted_value_vpo_was_paid,
                    ] as string[]
                  })
                }}
              />
            )}

            <Button
              type="submit"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isFetchingAllFreights || formState.isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>
      <Box bg={bgMain}>
        {isLoadingAllFreights || isFetchingAllFreights ? (
          <SkeletonLoadingTable />
        ) : (
          <>
            {allFreights && (
              <>
                <TableContainerFreightsTollStatus freights={allFreights.data} />
                <Box mb={8}>
                  <Pagination
                    currentPage={filters?.page || 1}
                    totalCountOfRegisters={allFreights.total}
                    onPageChange={newPage => {
                      addPage(newPage)
                      setFilters(prev => ({ ...prev, page: newPage }))
                    }}
                    p="6"
                  />
                </Box>
              </>
            )}
          </>
        )}
      </Box>
    </Layout>
  )
}
