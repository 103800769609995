/* eslint-disable no-param-reassign */
import { format, utcToZonedTime } from 'date-fns-tz'
import ptLocale from 'date-fns/locale/pt-BR'

export function formatDateWithTimeZone(value?: Date | string, formatDate?: string): string | undefined {
  if (!formatDate) formatDate = 'dd/MM/yyyy HH:mm'
  const formattedDate = value
    ? format(utcToZonedTime(value, 'America/Sao_Paulo'), formatDate, {
        timeZone: 'America/Sao_Paulo',
        locale: ptLocale,
      })
    : undefined
  return formattedDate
}
