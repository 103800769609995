import {
  Alert,
  AlertIcon,
  Badge,
  Flex,
  Heading,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { DownloadMdfePdf } from '../../../../../components/datamex/list-mdfes/ButtonsActions'
import { FormattedAllFreightsTolls } from '../../../../../services/endpoints/truckpad/getFreightsTolls'
import { MdfeType } from '../../../../../services/types'
import { displayAdaptable } from '../../../../../services/utils/tableAdaptable'
import { TrComponent } from './TrComponent'

type TableContainerProps = {
  freights: FormattedAllFreightsTolls[]
}

export function TableContainerFreightsTollStatus({ freights }: TableContainerProps): JSX.Element {
  const badgeBgColor = useColorModeValue('gray.300', 'gray.800')

  return (
    <>
      {freights && freights.length > 0 ? (
        <TableContainer backgroundColor="transparent" width="100%" overflowX="unset">
          <Table size="md" colorScheme="gray" variant="striped" borderRadius="16px">
            <Thead>
              <TrComponent />
            </Thead>
            <Tbody>
              {freights.map((freight: FormattedAllFreightsTolls) => {
                return (
                  <Tr key={freight.id}>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      <Tooltip hasArrow placement="auto" label={`Ver frete de ${freight.client?.nickname}`}>
                        <Link target="_blank" href={`/freights/show/${freight.id}`}>
                          <Flex direction="row" gridGap="1">
                            <Text fontWeight="bold">#{freight.freight_number} </Text>
                          </Flex>
                        </Link>
                      </Tooltip>
                    </Td>

                    <Td fontSize="xs" whiteSpace="nowrap">
                      <Badge
                        colorScheme={freight.formatted_has_tag === 'Sim' ? 'green' : 'red'}
                        rounded="full"
                        cursor="pointer"
                      >
                        <Tooltip label={freight.formatted_tag_provider} hasArrow placement="top">
                          {freight.formatted_has_tag}
                        </Tooltip>
                      </Badge>
                    </Td>

                    <Td fontSize="xs" whiteSpace="nowrap">
                      <Text fontWeight="bold">{freight.formatted_client_name}</Text>
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freight.collect_cargo_at_pt_br}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freight.cargo_delivery_estimate}
                    </Td>

                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freight.formatted_origin_name}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freight.formatted_destination_name}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      {freight.formatted_status}
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      <Badge
                        colorScheme={freight.formatted_toll_value !== 'N/A' ? 'green' : 'blue'}
                        rounded="full"
                      >
                        {freight.formatted_toll_value}
                      </Badge>
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap" d={displayAdaptable}>
                      <Flex justify="space-between" align="center" gridGap={1}>
                        <Badge
                          colorScheme={freight.formatted_value_vpo !== 'Não' ? 'green' : 'blue'}
                          rounded="full"
                        >
                          {freight.formatted_value_vpo}
                        </Badge>
                        {freight.cte?.mdfes &&
                          freight.cte.mdfes.map((mdfe: MdfeType) => {
                            return <DownloadMdfePdf mdfe={mdfe} />
                          })}
                      </Flex>
                    </Td>
                    <Td fontSize="xs" whiteSpace="nowrap">
                      <Badge
                        color={freight.formatted_value_vpo_was_paid === 'Sim' ? 'green.500' : 'red.500'}
                        rounded="full"
                        bgColor={badgeBgColor}
                      >
                        {freight.formatted_value_vpo_was_paid}
                      </Badge>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
            <Tfoot>
              <TrComponent />
            </Tfoot>
          </Table>
        </TableContainer>
      ) : (
        <Alert status="info">
          <AlertIcon />
          <Heading size="md">Sem dados</Heading>
        </Alert>
      )}
    </>
  )
}
