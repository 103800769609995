import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { FormEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaClipboardList } from 'react-icons/fa'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { AutocompleteAsync, Input, Select, SelectOption } from '../../../../../components/form'
import Filter from '../../../../../components/UI/Filter'
import useQueryParamUpdater from '../../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../../layout'
import { FilterCteIssuanceRequestsType } from '../../../../../services/endpoints/freights/GetAllCteIssuanceRequest'
import { searchMotoristByName } from '../../../../../services/endpoints/motorists/searchMotoristByName'
import { getAllUsers } from '../../../../../services/endpoints/users/getAllUsers'
import { CteIssuanceRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { CteIssuanceRequestTableList } from '../components/table-list'

type FormFiltersCteIssuanceRequests = {
  freight_number?: string
  status?: CteIssuanceRequestStatusEnum
  creator_id?: SelectOption
  initial_date?: string
  final_date?: string
  motorist_id?: SelectOption
}

export function ListCteIssuanceRequests(): JSX.Element {
  const [initialDate, setInitialDate] = useState<string>()
  const [finalDate, setFinalDate] = useState<string>()
  const [filters, setFilters] = useState<FilterCteIssuanceRequestsType>({} as FilterCteIssuanceRequestsType)
  const bg = useColorModeValue('white', 'gray.800')
  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    freight_number?: string
    status?: CteIssuanceRequestStatusEnum
    initial_date?: string
    final_date?: string
    motorist_label?: string
    motorist_value?: string
    beneficiary_label?: string
    beneficiary_value?: string
    creator_label?: string
    creator_value?: string
    cf_number?: string
  }>()

  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const { handleSubmit, setValue } = useForm({})

  const handleFilterCteIssuanceRequests: SubmitHandler<FormFiltersCteIssuanceRequests> = data => {
    const formattedFilters: FilterCteIssuanceRequestsType = {
      status: data.status,
      freight_number: data.freight_number,
      creator_id: data.creator_id,
      initial_date: data.initial_date,
      final_date: data.final_date,
      motorist_id: data.motorist_id,
    }

    setFilters(formattedFilters)

    updateQueryParams({
      freight_number: data.freight_number || undefined,
      status: data.status || undefined,
      initial_date: data.initial_date || undefined,
      final_date: data.final_date || undefined,
      motorist_label: data.motorist_id?.label || undefined,
      motorist_value: data.motorist_id?.value || undefined,
      creator_label: data.creator_id?.label || undefined,
      creator_value: data.creator_id?.value || undefined,
    })
  }

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  function handleInitialDate(event: FormEvent<HTMLInputElement>): void {
    setInitialDate(event.currentTarget.value)
  }

  function handleFinalDate(event: FormEvent<HTMLInputElement>): void {
    setFinalDate(event.currentTarget.value)
  }

  useEffect(() => {
    const freightNumberQuery = getParams('freight_number')
    const initialDateQuery = getParams('initial_date')
    const finalDateQuery = getParams('final_date')
    const statusQuery = getParams('status')
    const motoristLabelQuery = getParams('motorist_label')
    const motoristValueQuery = getParams('motorist_value')
    const creatorLabelQuery = getParams('creator_label')
    const creatorValueQuery = getParams('creator_value')

    if (
      freightNumberQuery ||
      statusQuery ||
      initialDateQuery ||
      finalDateQuery ||
      motoristValueQuery ||
      creatorValueQuery ||
      motoristLabelQuery ||
      creatorLabelQuery
    ) {
      setValue('freight_number', freightNumberQuery || '')
      setValue('initial_date', initialDateQuery || '')
      setValue('final_date', finalDateQuery || '')
      setValue('status', statusQuery || '')
      setValue('motorist_id', { label: motoristLabelQuery || '', value: motoristValueQuery || '' })
      setValue('creator_id', { label: creatorLabelQuery || '', value: creatorValueQuery || '' })

      handleSubmit(handleFilterCteIssuanceRequests)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Heading fontSize="2xl">Solicitações de emissão de CTe</Heading>

        <Box>
          <Button
            as={Link}
            to="/cte-issuance-requests/create"
            colorScheme="orange"
            leftIcon={<Icon as={FaClipboardList} />}
          >
            Nova solicitação de emissão de CTe
          </Button>
          <Button
            leftIcon={<RiFilter2Line />}
            variant="outline"
            colorScheme="blue"
            ml={2}
            onClick={onToggleFilter}
          >
            Filtros
          </Button>
        </Box>
      </Flex>

      <Filter showFilter={onShowFilter} key={1}>
        <form onSubmit={handleSubmit(handleFilterCteIssuanceRequests)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Input
                label="Número do frete"
                name="freight_number"
                placeholder="Filtrar pelo número"
                setValue={setValue}
                initialValue={filters?.freight_number}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Select
                label="Status"
                name="status"
                setValue={setValue}
                options={[
                  {
                    label: '',
                    value: '',
                  },
                  {
                    label: 'Aguardando Análise',
                    value: 'awaiting_analysis',
                  },
                  {
                    label: 'CO Reprovado',
                    value: 'rejected',
                  },
                  {
                    label: 'Aguardando emissão do CTe',
                    value: 'awaiting_issuance_cte',
                  },
                  {
                    label: 'CTe emitido',
                    value: 'cte_issued',
                  },
                  {
                    label: 'CTe retornado ao motorista',
                    value: 'cte_return_to_motorist',
                  },
                  {
                    label: 'Aguardando solicitação de adiantamento',
                    value: 'awaiting_advance_request',
                  },
                  {
                    label: 'Solicitação de adiantamento em andamento',
                    value: 'advance_request_ongoing',
                  },
                  {
                    label: 'Solicitação de adiantamento finalizado',
                    value: 'advance_request_finished',
                  },
                  {
                    label: 'Finalizado',
                    value: 'finished',
                  },
                ]}
                initialValue={filters?.status}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Input
                label="Data inicial"
                name="initial_date"
                type="date"
                initialValue={filters.initial_date || initialDate}
                onBlur={handleInitialDate}
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Input
                label="Data final"
                name="final_date"
                type="date"
                initialValue={filters.final_date || finalDate}
                onBlur={handleFinalDate}
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 4]} mr={2}>
              <AutocompleteAsync
                label="Motorista"
                name="motorist_id"
                placeholder="Filtrar pelo motorista"
                setValue={setValue}
                loadOptions={searchMotoristByName}
                initialValue={filters?.motorist_id}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 4]} mr={2}>
              <AutocompleteAsync
                label="Responsável"
                name="creator_id"
                placeholder="Filtrar pelo responsável"
                setValue={setValue}
                loadOptions={getAllUsers}
                initialValue={filters?.creator_id}
              />
            </GridItem>
          </Grid>

          <Flex justifyContent="flex-end" mt={2}>
            <Button type="submit" size="md" colorScheme="blue" leftIcon={<Icon as={RiSearchLine} />}>
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} p={4} borderRadius="8" shadow="md">
        <CteIssuanceRequestTableList
          handleChangePage={handleChangePage}
          filters={filters}
          initialDate={initialDate}
          finalDate={finalDate}
          hasPagination
        />
      </Box>
    </Layout>
  )
}
