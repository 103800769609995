export const vehicleCategoryOptions = [
  {
    value: 'truck',
    label: 'Caminhão',
  },
  {
    value: 'car',
    label: 'Carro',
  },
]

export const truckCategoryOptions = [
  { value: '', label: 'Selecione uma opção' },
  {
    value: '2',
    label: 'Caminhão leve, ônibus, caminhão-trator e furgão (2 eixos, rodagem dupla)',
  },
  {
    value: '3',
    label: 'Automóvel e caminhonete com semirreboque (3 eixos, rodagem simples)',
  },
  {
    value: '4',
    label: 'Caminhão, caminhão-trator com semirreboque e ônibus (3 eixos, rodagem dupla)',
  },
  { value: '5', label: 'Automóvel e caminhonete com reboque (4 eixos, rodagem simples)' },
  {
    value: '6',
    label: 'Caminhão com reboque, caminhão-trator com semi-reboque (4 eixos, rodagem dupla)',
  },
  {
    value: '7',
    label: 'Caminhão com reboque, caminhão-trator com semi-reboque (5 eixos, rodagem dupla)',
  },
  {
    value: '8',
    label: 'Caminhão com reboque, caminhão-trator com semi-reboque (6 eixos, rodagem dupla)',
  },
  {
    value: '9',
    label: 'Caminhão com reboque, caminhão-trator com semi-reboque (7 eixos, rodagem dupla)',
  },
  {
    value: '10',
    label: 'Caminhão com reboque, caminhão-trator com semi-reboque (8 eixos, rodagem dupla)',
  },
]

export const freightTypeOptions = [
  { value: 'A', label: 'Transporte Rodoviário de Carga Lotação (Tabela A)' },
  { value: 'B', label: 'Contratação Apenas do Veículo Automotor de Cargas (Tabela B)' },
  {
    value: 'C',
    label: 'Transporte Rodoviário de Carga Lotação de Alto Desempenho (Tabela C)',
  },
  {
    value: 'D',
    label: 'Contratação Apenas do Veículo Automotor de Cargas de Alto Desempenho (Tabela D)',
  },
]

export const cargoTypeOptions = [
  { value: '', label: 'Selecione uma opção' },
  { value: 'all', label: 'Todas' },
  { value: 'granel_solido', label: 'Granel Sólido' },
  { value: 'granel_liquido', label: 'Granel Líquido' },
  { value: 'frigorificada', label: 'Frigorificada' },
  { value: 'conteinerizada', label: 'Conteinerizada' },
  { value: 'geral', label: 'Geral' },
  { value: 'neogranel', label: 'Neogranel' },
  { value: 'perigosa_granel_solido', label: 'Perigosa Granel Sólido' },
  { value: 'perigosa_granel_liquido', label: 'Perigosa Granel Líquido' },
  { value: 'perigosa_frigorificada', label: 'Perigosa Frigorificada' },
  { value: 'perigosa_conteinerizada', label: 'Perigosa Conteinerizada' },
  { value: 'perigosa_geral', label: 'Perigosa Geral' },
  { value: 'granel_pressurizada', label: 'Granel Pressurizada' },
]

export const typeRouteOptions = [
  { value: 'efficient', label: 'Rota Eficiente' },
  { value: 'short', label: 'Rota Curta' },
  { value: 'avoid_tolls', label: 'Evitar Pedágios' },
]

export const alternativeRouteOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
]
