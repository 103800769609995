import { useQuery, UseQueryResult } from 'react-query'

import { apiServer } from '../../api'

import { AutocompleteOption } from '../../../components/form/types/AutocompleteOption'
import { ResponseApiType } from '../../types'

export interface FetchRouteAnalisisParams {
  origins: AutocompleteOption
  destinations: AutocompleteOption
  initial_date: string
  final_date: string
  origins_radius: string
  destinations_radius: string
}

export interface IRouteAnalysysResponse {
  name: string
  [key: string]: unknown
}

async function fetchRouteAnalysis(data: FetchRouteAnalisisParams): Promise<IRouteAnalysysResponse[]> {
  const params = data && { ...data }

  const { data: routeAnalysis } = await apiServer.get<ResponseApiType<IRouteAnalysysResponse[]>>(
    '/route-analysys',
    {
      params: {
        origin_id: params.origins.value,
        destination_id: params.destinations.value,
        initial_date: params.initial_date,
        final_date: params.final_date,
        origin_radius: params.origins_radius,
        destination_radius: params.destinations_radius,
      },
    },
  )

  return routeAnalysis.data
}

export function useGetRouteAnalysis(
  data: FetchRouteAnalisisParams,
): UseQueryResult<IRouteAnalysysResponse[]> {
  return useQuery(['routeAnalysis', data], () => fetchRouteAnalysis(data), {
    staleTime: 1000 * 60 * 10,
  })
}
