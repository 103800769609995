import { useState, useEffect, FormEvent } from 'react'
import {
  Text,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  useColorModeValue,
  Tooltip,
  useDisclosure,
  Switch,
} from '@chakra-ui/react'
import { RiSearchLine } from 'react-icons/ri'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { format } from 'date-fns'
import { Layout } from '../../../layout'

import {
  FetchRouteAnalisisParams,
  useGetRouteAnalysis,
} from '../../../services/endpoints/freights/getRouteAnalysis'
import useQueryParamUpdater from '../../../hooks/useQueryParamUpdater'
import { RouteAnalysisGraphic } from './components/Graphic'
import { AutocompleteAsync, Input } from '../../../components/form'
import { searchCitiesByName } from '../../../services/endpoints/cities/searchCities'
import { SetLocationRadiusModal } from './modals/SetLocationRadiusModal'

const filterRouteAnalysis = yup.object().shape({
  origins: yup.object().required('Cidade de origem inválida'),
  destinations: yup.object().required('Cidade de destino inválida'),
  initial_date: yup.string().required('Data inicial inválida'),
  final_date: yup.string().required('Data final inválida'),
})

type FilterParamsType = {
  origins_value: string
  destinations_value: string
  origins_radius: string
  destinations_radius: string
  initial_date: string
  final_date: string
  origins_label?: string
  destinations_label?: string
  origins_lat?: string
  origins_lng?: string
  destinations_lat?: string
  destinations_lng?: string
}

export type LocationRadiusType = {
  origin: {
    label?: string | null
    value?: number
    radius?: number
    lat?: number
    lng?: number
  } | null
  destination: {
    label?: string | null
    value?: number
    radius?: number
    lat?: number
    lng?: number
  } | null
}

export function RouteAnalysis(): JSX.Element {
  const [locations, setLocations] = useState<LocationRadiusType>({
    origin: null,
    destination: null,
  })
  const [labelMap, setLabelMap] = useState<'origin' | 'destination'>('origin')
  const [filters, setFilters] = useState<FetchRouteAnalisisParams>({} as FetchRouteAnalisisParams)
  const [initialDate, setInitialDate] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-01-01')
  })

  const [finalDate, setFinalDate] = useState<string>(() => {
    const now = new Date()
    return format(now, 'yyyy-MM-dd')
  })

  const {
    isOpen: isOpenSetLocationRadiusModal,
    onOpen: onOpenSetLocationRadiusModal,
    onClose: onCloseSetLocationRadiusModal,
  } = useDisclosure()

  const { updateQueryParams, getParams } = useQueryParamUpdater<FilterParamsType>()

  const { data: routeAnalysis, refetch } = useGetRouteAnalysis({
    initial_date: filters.initial_date,
    final_date: filters.final_date,
    origins: filters.origins,
    destinations: filters.destinations,
    origins_radius: filters.origins_radius,
    destinations_radius: filters.destinations_radius,
  })

  const bg = useColorModeValue('white', 'gray.800')

  const { setValue, handleSubmit, formState, setError } = useForm({
    resolver: yupResolver(filterRouteAnalysis),
  })

  const { errors, isSubmitting } = formState

  function validateDates(): boolean {
    const initial_date_formated = new Date(initialDate)
    const final_date_formated = new Date(finalDate)

    if (initial_date_formated > final_date_formated) {
      setError('initial_date', { message: 'Data inicial inválida' })
      setError('final_date', { message: 'Data final inválida' })

      return false
    }

    return true
  }

  const handleFilter: SubmitHandler<FetchRouteAnalisisParams> = async data => {
    const isValidDates = validateDates()

    // eslint-disable-next-line no-useless-return
    if (!isValidDates) return

    const originRadius =
      locations && locations.origin
        ? locations.origin.radius
        : data.origins && data.origins.radius
        ? data.origins.radius
        : 0

    const destinationsRadius =
      locations && locations.destination
        ? locations.destination.radius
        : data.destinations && data.destinations.radius
        ? data.destinations.radius
        : 0

    updateQueryParams({
      initial_date: data.initial_date,
      final_date: data.final_date,
      origins_value: data.origins.value.toString(),
      destinations_value: data.destinations.value.toString(),
      origins_label: data.origins.label,
      destinations_label: data.destinations.label,
      origins_radius: String(originRadius),
      destinations_radius: String(destinationsRadius),
      origins_lat: String(data.origins.lat),
      origins_lng: String(data.origins.lng),
      destinations_lat: String(data.destinations.lat),
      destinations_lng: String(data.destinations.lng),
    })

    setFilters({
      origins: data.origins,
      destinations: data.destinations,
      initial_date: data.initial_date,
      final_date: data.final_date,
      origins_radius: String(originRadius),
      destinations_radius: String(destinationsRadius),
    })

    refetch()
  }

  function handleInitialDate(event: FormEvent<HTMLInputElement>): void {
    setInitialDate(event.currentTarget.value)
  }

  function handleFinalDate(event: FormEvent<HTMLInputElement>): void {
    setFinalDate(event.currentTarget.value)
  }

  useEffect(() => {
    const initialDateParam = getParams('initial_date')
    const finalDateParam = getParams('final_date')
    const originsValueParam = getParams('origins_value')
    const destinationsValueParam = getParams('destinations_value')
    const originsLabelParam = getParams('origins_label')
    const destinationsLabelParam = getParams('destinations_label')
    const originsRadiusLabel = getParams('origins_radius')
    const destinationsRadiusLabel = getParams('destinations_radius')
    const originsLatParam = getParams('origins_lat')
    const originsLngParam = getParams('origins_lng')
    const destinationsLatParam = getParams('destinations_lat')
    const destinationsLngParam = getParams('destinations_lng')

    if (originsLatParam && originsLngParam && originsRadiusLabel) {
      setLocations(prevState => ({
        ...prevState,
        origin: {
          label: originsLabelParam,
          value: Number(originsValueParam),
          lat: Number(originsLatParam),
          lng: Number(originsLngParam),
          radius: Number(originsRadiusLabel),
        },
      }))
    }

    if (destinationsLatParam && destinationsLngParam && destinationsRadiusLabel) {
      setLocations(prevState => ({
        ...prevState,
        destination: {
          label: destinationsLabelParam,
          value: Number(destinationsValueParam),
          lat: Number(destinationsLatParam),
          lng: Number(destinationsLngParam),
          radius: Number(destinationsRadiusLabel),
        },
      }))
    }

    if (initialDateParam && finalDateParam) {
      handleFilter({
        initial_date: initialDateParam,
        final_date: finalDateParam,
        origins: {
          label: originsLabelParam,
          value: originsValueParam,
          lat: originsLatParam ? Number(originsLatParam) : undefined,
          lng: originsLngParam ? Number(originsLngParam) : undefined,
          radius: originsRadiusLabel ? Number(originsRadiusLabel) : undefined,
        },
        destinations: {
          label: destinationsLabelParam,
          value: destinationsValueParam,
          lat: destinationsLatParam ? Number(destinationsLatParam) : undefined,
          lng: destinationsLngParam ? Number(destinationsLngParam) : undefined,
          radius: destinationsRadiusLabel ? Number(destinationsRadiusLabel) : undefined,
        },
        origins_radius: originsRadiusLabel,
        destinations_radius: destinationsRadiusLabel,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
        <form onSubmit={handleSubmit(handleFilter)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[8, 6]}>
              <Flex flexDirection="column">
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="gray.400" mb={1}>
                    Cidade de origem
                  </Text>

                  {locations && locations.origin && locations.origin.label && locations.origin.value && (
                    <Flex alignItems="center" mb={1}>
                      <Text color="gray.400">Definir raio</Text>
                      <Switch
                        size="sm"
                        ml={2}
                        colorScheme="blue"
                        defaultChecked={
                          !!(
                            locations &&
                            locations.origin &&
                            locations.origin.radius &&
                            locations.origin.radius > 0
                          )
                        }
                        onChange={state => {
                          const isChecked = state.target.checked
                          if (isChecked) {
                            setLocations(prevState => {
                              return {
                                ...prevState,
                                origin: {
                                  ...prevState.origin,
                                  radius: 50,
                                },
                              }
                            })
                          } else {
                            setLocations(prevState => {
                              return {
                                ...prevState,
                                origin: {
                                  ...prevState.origin,
                                  radius: 0,
                                },
                              }
                            })
                          }
                        }}
                      />
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <Flex alignItems="center">
                <AutocompleteAsync
                  name="origins"
                  setValue={setValue}
                  error={errors.origins}
                  loadOptions={searchCitiesByName}
                  placeholder="Origem"
                  initialValue={filters.origins}
                  onSelectOption={city => {
                    if (city) {
                      setLocations(prevState => {
                        return {
                          ...prevState,
                          origin: {
                            ...prevState.origin,
                            label: city.label,
                            value: Number(city.value),
                            lat: city.lat,
                            lng: city.lng,
                          },
                        }
                      })
                    }
                  }}
                />

                {locations && locations.origin && locations.origin.radius && locations.origin.radius > 0 ? (
                  <Tooltip label="Definir raio de pesquisa" placement="top" hasArrow>
                    <Button
                      ml={2}
                      colorScheme="blue"
                      onClick={() => {
                        setLabelMap('origin')
                        onOpenSetLocationRadiusModal()
                      }}
                      isDisabled={
                        !locations ||
                        !locations.origin ||
                        !locations.origin.lat ||
                        !locations.origin.lng ||
                        !locations.origin.label ||
                        !locations.origin.value ||
                        !locations.origin.radius
                      }
                    >
                      <Text>{locations.origin.radius} Km</Text>
                    </Button>
                  </Tooltip>
                ) : null}
              </Flex>
            </GridItem>

            <GridItem colSpan={[8, 6]}>
              <Flex flexDirection="column">
                <Flex alignItems="center" justifyContent="space-between">
                  <Text color="gray.400" mb={1}>
                    Cidade de destino
                  </Text>

                  {locations &&
                    locations.destination &&
                    locations.destination.label &&
                    locations.destination.value && (
                      <Flex alignItems="center" mb={1}>
                        <Text color="gray.400">Definir raio</Text>
                        <Switch
                          size="sm"
                          ml={2}
                          colorScheme="blue"
                          defaultChecked={
                            !!(
                              locations &&
                              locations.destination &&
                              locations.destination.radius &&
                              locations.destination.radius > 0
                            )
                          }
                          onChange={state => {
                            const isChecked = state.target.checked
                            if (isChecked) {
                              setLocations(prevState => {
                                return {
                                  ...prevState,
                                  destination: {
                                    ...prevState.destination,
                                    radius: 50,
                                  },
                                }
                              })
                            } else {
                              setLocations(prevState => {
                                return {
                                  ...prevState,
                                  destination: {
                                    ...prevState.destination,
                                    radius: 0,
                                  },
                                }
                              })
                            }
                          }}
                        />
                      </Flex>
                    )}
                </Flex>
              </Flex>
              <Flex alignItems="center">
                <AutocompleteAsync
                  name="destinations"
                  setValue={setValue}
                  error={errors.destinations}
                  loadOptions={searchCitiesByName}
                  placeholder="Destino"
                  initialValue={filters.destinations}
                  onSelectOption={city => {
                    if (city) {
                      setLocations(prevState => {
                        return {
                          ...prevState,
                          destination: {
                            ...prevState.destination,
                            label: city.label,
                            value: Number(city.value),
                            lat: city.lat,
                            lng: city.lng,
                          },
                        }
                      })
                    }
                  }}
                />

                {locations &&
                locations.destination &&
                locations.destination.radius &&
                locations.destination.radius > 0 ? (
                  <Tooltip label="Definir raio de pesquisa" placement="top" hasArrow>
                    <Button
                      ml={2}
                      colorScheme="blue"
                      onClick={() => {
                        setLabelMap('destination')
                        onOpenSetLocationRadiusModal()
                      }}
                      isDisabled={
                        !locations ||
                        !locations.destination ||
                        !locations.destination.lat ||
                        !locations.destination.lng ||
                        !locations.destination.label ||
                        !locations.destination.value ||
                        !locations.destination.radius
                      }
                    >
                      <Text>{locations.destination.radius} Km</Text>
                    </Button>
                  </Tooltip>
                ) : null}
              </Flex>
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Text color="gray.400" mb={1}>
                Data inicial
              </Text>
              <Input
                name="initial_date"
                type="date"
                initialValue={filters.initial_date || initialDate}
                error={errors.initial_date}
                setValue={setValue}
                onBlur={handleInitialDate}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Text color="gray.400" mb={1}>
                Data final
              </Text>
              <Input
                name="final_date"
                type="date"
                initialValue={filters.final_date || finalDate}
                error={errors.final_date}
                setValue={setValue}
                onBlur={handleFinalDate}
              />
            </GridItem>
          </Grid>

          <Flex mt="4" justify="flex-end">
            <Button
              type="submit"
              size="sm"
              colorScheme="blue"
              leftIcon={<Icon as={RiSearchLine} />}
              isLoading={isSubmitting}
            >
              Filtrar
            </Button>
          </Flex>
        </form>
      </Box>

      <RouteAnalysisGraphic data={routeAnalysis} />

      {isOpenSetLocationRadiusModal && (
        <SetLocationRadiusModal
          isOpen={isOpenSetLocationRadiusModal}
          onClose={onCloseSetLocationRadiusModal}
          locations={locations}
          setLocations={setLocations}
          labelMap={labelMap}
        />
      )}
    </Layout>
  )
}
