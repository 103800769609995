import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions } from 'react-query'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../api'
import { LoadingRequestStatusEnum } from '../../../types/EnumTypes'

type UpdateLoadingRequestType = {
  id: string
  data: {
    status?: LoadingRequestStatusEnum
    reject_reason?: string | null
    motorist_id?: string
    motorist_vehicle_id?: string
    cte_id?: string
    discount_buonny?: number
    reason?: string
    type?: string
    total_value?: number
    total_gross?: number
    origin?: string
    destination?: string
    beneficiary_id?: string
    e_frete?: boolean
    payment_type?: string | null
    pix?: string | null
    account_type?: string | null
    bank?: string | null
    agency?: string | null
    account?: string | null
    account_digit?: string | null
    observation?: string | null
    payment_observation?: string | null
    is_approved?: boolean
  }
}

async function updateLoadingRequest(data: UpdateLoadingRequestType) {
  const response = await apiServer.put(`/loading-requests/${data.id}`, data.data)
  queryClient.invalidateQueries('loading-requests')
  return response
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function usePutLoadingRequest(
  options?: UseMutationOptions<AxiosResponse, unknown, UpdateLoadingRequestType, unknown>,
) {
  return useMutation(async (data: UpdateLoadingRequestType) => updateLoadingRequest(data), options)
}
