import { apiServer } from '../../api'
import { UserType } from '../../types'

interface UsersFiltered {
  label: string
  value: string
  role: string
}

export async function getAllUsersWithoutSuper(name?: string): Promise<UsersFiltered[]> {
  const { data: usersResponse } = await apiServer.get('/users', {
    params: {
      name,
      unpaged: true,
    },
  })

  return usersResponse.data.data
    .filter((user: UserType) => user.role !== 'super')
    .map((user: UserType) => {
      return {
        label: user.name,
        value: user.id,
        role: user.role,
      }
    })
}
