import { QualpDataResponseType } from '../../../../services/endpoints/qualp/postRoutePlanner'
import parseCurrency from '../../../../services/utils/parseCurrency'
import { calculateTotalToll } from './calculate-total-toll'

export function addTagsAtRoute(data: QualpDataResponseType, truckCategory: number): any {
  if (data && data.routes && data.routes.length > 1) {
    const formattedRoutes = data.routes.map((route: any) => {
      if (route.tolls && route.tolls.length > 0) {
        return {
          ...route,
          totalTollPrice: calculateTotalToll(route.tolls, truckCategory),
          formattedTolls: parseCurrency(calculateTotalToll(route.tolls, truckCategory)),
        }
      }
      return route
    })

    const tollValues = formattedRoutes.map(route => route.totalTollPrice)
    const distanceValues = formattedRoutes.map(route =>
      Number(route.distance.replace(' km', '').replace(',', '').trim()),
    )
    const durationValues = formattedRoutes.map(route => {
      const [hours, minutes, seconds] = route.duration.split(':').map(Number)
      return hours * 3600 + minutes * 60 + seconds
    })

    const minToll = Math.min(...tollValues)
    const minDistance = Math.min(...distanceValues)
    const minDuration = Math.min(...durationValues)

    const tagsMap = [
      { label: 'Menor pedágio', color: 'green', condition: (toll: number) => toll === minToll },
      {
        label: 'Menor distância',
        color: 'blue',
        condition: (distance: number) => distance === minDistance,
      },
      { label: 'Menor tempo', color: 'yellow', condition: (duration: number) => duration === minDuration },
    ]

    const routesWithTags = formattedRoutes.map((route, index) => {
      const tollValue = tollValues[index]
      const distanceValue = distanceValues[index]
      const durationValue = durationValues[index]

      const tags = tagsMap
        .filter(tag =>
          tag.condition(
            tag.label === 'Menor pedágio'
              ? tollValue
              : tag.label === 'Menor distância'
              ? distanceValue
              : durationValue,
          ),
        )
        .map(tag => ({ label: tag.label, color: tag.color }))

      return {
        ...route,
        tags,
      }
    })

    return {
      ...data,
      routes: routesWithTags,
    }
  }
  if (data && data.routes && data.routes.length === 1) {
    return {
      ...data,
      routes: [
        {
          ...data.routes[0],
          tags: [
            {
              label: 'Menor pedágio',
              color: 'green',
            },
            {
              label: 'Menor distância',
              color: 'blue',
            },
            {
              label: 'Menor tempo',
              color: 'yellow',
            },
          ],
        },
      ],
    }
  }
}
