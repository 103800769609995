import { AutocompleteOption } from '../../components/form'

export function encodeAutocompleteToUrl(obj?: AutocompleteOption | AutocompleteOption[]): string {
  if (!obj) {
    return ''
  }

  if (Array.isArray(obj)) {
    if (obj.length === 0) return ''

    const encodedItems = obj
      .map(item => {
        if (!item.label || !item.value) return ''
        const encodedLabel = btoa(item.label)
        const encodedValue = btoa(item.value.toString())
        return `label/${encodedLabel}#value/${encodedValue}`
      })
      .filter(Boolean)

    return encodedItems.join('|')
  }

  if (!obj.label || !obj.value) {
    return ''
  }

  const encodedLabel = btoa(obj.label)
  const encodedValue = btoa(obj.value.toString())

  return `label/${encodedLabel}#value/${encodedValue}`
}

function decodeSingleItem(url: string): AutocompleteOption | undefined {
  const pairs = url.split('#')
  const obj: Partial<AutocompleteOption> = {}

  pairs.forEach(pair => {
    const [key, value] = pair.split('/')
    if (key && value) {
      try {
        if (key === 'label') {
          obj.label = atob(value)
        } else if (key === 'value') {
          obj.value = atob(value)
        }
      } catch (e) {
        return undefined
      }
    }
  })

  if (obj.label && obj.value) {
    return obj as AutocompleteOption
  }

  return undefined
}

export function decodeEncodeAutocompleteToObject(
  url: string,
): AutocompleteOption | AutocompleteOption[] | undefined {
  if (!url) {
    return undefined
  }

  // Check if it's an array (contains pipe separator)
  if (url.includes('|')) {
    const items = url.split('|')
    const result: AutocompleteOption[] = []

    items.forEach(item => {
      const decoded = decodeSingleItem(item)
      if (decoded) {
        result.push(decoded)
      }
    })

    return result.length > 0 ? result : undefined
  }

  return decodeSingleItem(url)
}
