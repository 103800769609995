/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Thead,
  Table,
  Tr,
  Th,
  Tbody,
  Td,
  Badge,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  Flex,
  Spinner,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { toastError } from '../../../../../../config/error/toastError'
import { Pagination } from '../../../../../../layout/Pagination'
import {
  FilterAdvanceRequestsType,
  useGetAllAdvanceRequest,
} from '../../../../../../services/endpoints/freights/getAllAdvanceRequests'
import { advanceRequestStatusLegend } from '../../../../../../services/utils/advanceRequestStatusLegend'
import { getAdvanceRequestColorByStatus } from '../../../../../../services/utils/getAdvanceRequestColorByStatus'

type AdvanceRequestTableListProps = {
  handleChangePage?: (newPage: number) => void
  filters?: FilterAdvanceRequestsType
  initialDate?: string | undefined
  finalDate?: string | undefined
  hasPagination?: boolean
}

export function AdvanceRequestTableList({
  handleChangePage,
  filters,
  initialDate,
  finalDate,
  hasPagination,
}: AdvanceRequestTableListProps): JSX.Element {
  const toast = useToast()
  const hoverBgLineTable = useColorModeValue('gray.200', 'gray.700')

  const { data, isLoading, isError, error } = useGetAllAdvanceRequest({
    initial_date: filters?.initial_date || initialDate,
    final_date: filters?.final_date || finalDate,
    ...filters,
  })

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  return (
    <>
      {!isLoading ? (
        data && data.data && data.data.length > 0 ? (
          <>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Frete</Th>
                  <Th>Status</Th>
                  <Th>Motivo</Th>
                  <Th>Motorista</Th>
                  <Th>Reponsável</Th>
                  <Th>Data</Th>
                </Tr>
              </Thead>
              <Tbody>
                {data?.data.map(advanceRequest => (
                  <Tr
                    key={advanceRequest.id}
                    cursor="pointer"
                    _hover={{ bg: hoverBgLineTable, transition: '0.3s' }}
                    onClick={() => {
                      window.open(`/advance-requests/show/${advanceRequest.id}`, '_blank')?.focus()
                    }}
                  >
                    <Td>#{advanceRequest?.freight?.freight_number}</Td>
                    <Td>
                      <Tooltip
                        label={
                          advanceRequest.reject_reason || advanceRequestStatusLegend(advanceRequest.status)
                        }
                        hasArrow
                        placement="top"
                        arrowSize={15}
                      >
                        <Badge
                          px="2"
                          py="1"
                          colorScheme={getAdvanceRequestColorByStatus(advanceRequest.status)}
                          w="100%"
                          textAlign="center"
                        >
                          {advanceRequest.formatted_status}
                        </Badge>
                      </Tooltip>
                    </Td>
                    <Td>
                      {advanceRequest.reason.length > 20 ? (
                        <Tooltip label={advanceRequest.reason} hasArrow placement="top" arrowSize={15}>
                          <Text>
                            {String(advanceRequest.reason).substring(0, 20)}
                            {advanceRequest.reason.length > 20 ? '...' : ''}
                          </Text>
                        </Tooltip>
                      ) : (
                        advanceRequest.reason
                      )}
                    </Td>
                    <Td>{advanceRequest.freight?.motorist?.name}</Td>
                    <Td>{advanceRequest.creator!.name}</Td>
                    <Td>{advanceRequest.created_at}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {hasPagination && handleChangePage && (
              <Pagination
                currentPage={filters?.page || 1}
                totalCountOfRegisters={data?.total ?? 0}
                registersPerPage={15}
                onPageChange={handleChangePage}
                pt="4"
                pb="1"
              />
            )}
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar as solicitações de adiantamento</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de adiantamento encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}
    </>
  )
}
