import { LatLngBoundsExpression } from 'leaflet'
import { useContext, useEffect, useState } from 'react'
import { MapContainer, TileLayer, Polyline, useMap } from 'react-leaflet'
import { decodePolyline } from '../services/decode-polyline-service'
import { MapMarker } from './components/MapMarker'
import { RoutePlannerContext } from './ShowRoutePlanner'

const containerStyle = {
  width: '100%',
  minHeight: '400px',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}

type Location = {
  lat: number
  lng: number
}

type RoutePlannerMapProps = {
  polyline?: string
  initialRoutePolyline?: Location[]
}

type TileLayerCustomProps = {
  bounds: LatLngBoundsExpression
}

function TileLayerCustom({ bounds }: TileLayerCustomProps) {
  const map = useMap()

  useEffect(() => {
    map.flyToBounds(bounds, { maxZoom: 9 })
  }, [bounds, map])

  return (
    <TileLayer
      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
  )
}

export function RoutePlannerMap({ polyline, initialRoutePolyline }: RoutePlannerMapProps): JSX.Element {
  const { state } = useContext(RoutePlannerContext)
  const [decodedPolyline, setDecodedPolyline] = useState<Array<{ lat: number; lng: number }>>([])
  const [bounds, setBounds] = useState<LatLngBoundsExpression>([
    [-3.675295, -60.26574],
    [-23.687185, -40.284531],
  ])

  const { origin, destination } = state.freight

  useEffect(() => {
    const stops: Array<[number, number]> = state.steps.map(stop => [Number(stop.lat), Number(stop.lng)])

    const boundsLocations: LatLngBoundsExpression = [
      [Number(origin?.lat), Number(origin?.lng)],
      ...stops,
      [Number(destination?.lat), Number(destination?.lng)],
    ]

    setBounds(boundsLocations)
  }, [decodedPolyline, destination?.lat, destination?.lng, origin?.lat, origin?.lng, state.steps])

  useEffect(() => {
    if (polyline) {
      setDecodedPolyline(decodePolyline(polyline))
    } else if (initialRoutePolyline && initialRoutePolyline.length && initialRoutePolyline.length > 0) {
      setDecodedPolyline(initialRoutePolyline)
    }
  }, [polyline, initialRoutePolyline])

  return (
    <div style={{ ...containerStyle, backgroundColor: 'white' }}>
      <MapContainer bounds={bounds} boundsOptions={{ maxZoom: 5 }} style={containerStyle}>
        <MapMarker type="origin" city={origin} />

        {state.steps &&
          state.steps.length > 0 &&
          state.steps.map(city => <MapMarker type="step" stepCity={city} />)}

        <MapMarker type="destination" city={destination} />

        <TileLayerCustom bounds={bounds} />

        {decodedPolyline.length > 0 && (
          <Polyline
            pathOptions={{
              color: 'blue',
              opacity: 0.6,
            }}
            positions={decodedPolyline.map(coord => [coord.lat, coord.lng])}
            weight={10}
            opacity={1}
          />
        )}
      </MapContainer>
    </div>
  )
}
