import { Button, Divider, Flex, Icon, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { IoArrowBackOutline } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { RoutePlannerContext } from '../../ShowRoutePlanner'

export function RoutePlannerHeader(): JSX.Element {
  const history = useHistory()
  const { state } = useContext(RoutePlannerContext)

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontSize="xl">Roteirizador - Frete #{state.freight.freight_number}</Text>

        <Button
          size="sm"
          onClick={() => {
            history.push(`/freights/show/${state.freight.id}`)
          }}
        >
          <Icon as={IoArrowBackOutline} mr="1" />
          Voltar ao frete
        </Button>
      </Flex>

      <Divider my={4} />
    </>
  )
}
