import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import { ChangeEventHandler, useEffect, useRef, useState } from 'react'
import { MdClear, MdCheckCircle } from 'react-icons/md'
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import useClickOutside from '../../hooks/useClickOutside'

type Option = {
  label: string
  value: string | number
  [key: string]: any
}

type UIMultiSelectAutoProps = {
  name?: string
  label?: string
  isError?: boolean
  errorMessage?: string
  helperText?: string
  isRequired?: boolean
  loadOptions?: (text: string) => Promise<Option[]>
  options?: Option[]
  value?: Option[]
  onChange?: (value: Option[]) => void
  placeholder?: string
  isDisabled?: boolean
  isGetOptionsOnFocus?: boolean
}

export const UIMultiSelectAutoSyncAndAsync = ({
  helperText,
  isRequired,
  isError,
  errorMessage,
  label,
  loadOptions,
  options: initialOptions = [],
  onChange,
  value = [],
  placeholder,
  name,
  isDisabled,
  isGetOptionsOnFocus = false,
}: UIMultiSelectAutoProps): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState(false)
  const [optionsFiltered, setOptionsFiltered] = useState<Option[]>(initialOptions.slice(0, 10))
  const [inputValue, setInputValue] = useState<string>('')
  const containerRef = useRef<HTMLDivElement>(null)
  const bg = useColorModeValue('gray.100', 'gray.700')

  const getOptions = useRef(
    debounce(
      async (search: string) => {
        setLoading(true)
        if (!loadOptions) {
          // Handle local filtering showing all options
          const filtered = (initialOptions || []).filter(option =>
            option.label.toUpperCase().includes(search.toUpperCase()),
          )
          setOptionsFiltered(filtered)
          setLoading(false)
          return
        }

        try {
          const data = await loadOptions(search)
          setOptionsFiltered(data)
        } catch (error) {
          setOptionsFiltered([])
        } finally {
          setLoading(false)
        }
      },
      500,
      {},
    ),
  ).current

  useClickOutside(containerRef, () => {
    if (isOpen) {
      onClose()
      setInputValue('')
      // Show all initial options when closing
      setOptionsFiltered(initialOptions.slice(0, 10))
    }
  })

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    setInputValue(e.target.value)
    if (e.target.value) {
      getOptions(e.target.value)
    } else {
      // Show all initial options when input is empty
      setOptionsFiltered(initialOptions.slice(0, 10))
    }
  }

  const handleSelect = (option: Option) => {
    // Check if option is already selected
    if (value.some(v => v.value === option.value)) {
      return
    }

    const newValue = [...value, option]
    onChange?.(newValue)
    setInputValue('')

    // Update filtered options, removing the selected option
    setOptionsFiltered(prev => prev.filter(opt => opt.value !== option.value))
  }

  const handleRemove = (optionToRemove: Option) => {
    const newValue = value.filter(v => v.value !== optionToRemove.value)
    onChange?.(newValue)

    // Add removed option back to filtered options if it matches current filter
    if (inputValue && optionToRemove.label.toUpperCase().includes(inputValue.toUpperCase())) {
      setOptionsFiltered(prev => [...prev, optionToRemove].slice(0, 10))
    }
  }

  const handleFocus = async () => {
    onOpen()
    if (isGetOptionsOnFocus && loadOptions) {
      setLoading(true)
      try {
        const data = await loadOptions('')
        setOptionsFiltered(data)
      } catch (error) {
        setOptionsFiltered([])
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    return () => {
      getOptions.cancel()
    }
  }, [getOptions])

  return (
    <Box ref={containerRef} opacity={isDisabled ? 0.5 : 1}>
      <FormControl isInvalid={isError} isRequired={isRequired}>
        {label && (
          <FormLabel fontSize="14" mb="1.5">
            {label}
          </FormLabel>
        )}

        <InputGroup>
          <Box
            w="100%"
            minH="40px"
            border="1px solid"
            borderColor={isError ? 'red.500' : 'inherit'}
            borderRadius="md"
            px="2"
            py="1"
            onClick={() => !isDisabled && onOpen()}
            cursor={isDisabled ? 'not-allowed' : 'pointer'}
            position="relative"
          >
            <Flex flexWrap="wrap" gridGap="1" alignItems="center" mr="2">
              {value.map(item => (
                <Tag key={item.value} size="sm" colorScheme="orange" borderRadius="full">
                  <TagLabel>{item.label}</TagLabel>
                  {!isDisabled && (
                    <TagCloseButton
                      onClick={e => {
                        e.stopPropagation()
                        handleRemove(item)
                      }}
                    />
                  )}
                </Tag>
              ))}
              <Input
                flex="1"
                minW="120px"
                variant="unstyled"
                name={name}
                value={inputValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                placeholder={value.length === 0 ? placeholder : ''}
                isDisabled={isDisabled}
                _disabled={{
                  opacity: 0.7,
                  cursor: 'not-allowed',
                }}
              />
            </Flex>
          </Box>
          <InputRightElement h="100%" pr="2" ml="5">
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <Flex gap="2">
                <Icon
                  as={isOpen ? RiArrowUpSLine : RiArrowDownSLine}
                  fontSize="20px"
                  cursor={isDisabled ? 'not-allowed' : 'pointer'}
                  onClick={() => !isDisabled && onOpen()}
                />
                {value.length > 0 && !isDisabled && (
                  <Icon
                    as={MdClear}
                    fontSize="20px"
                    cursor="pointer"
                    onClick={() => {
                      onChange?.([])
                      setInputValue('')
                      setOptionsFiltered([])
                    }}
                  />
                )}
              </Flex>
            )}
          </InputRightElement>
        </InputGroup>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
        {isError && <FormErrorMessage>{errorMessage}</FormErrorMessage>}

        {isOpen && (
          <Box
            position="absolute"
            w="100%"
            mt="2"
            zIndex={10}
            bg={bg}
            boxShadow="md"
            borderRadius="md"
            maxH="300px"
            overflowY="auto"
          >
            {optionsFiltered.length === 0 && !loading && (
              <Text p="3" color="gray.500">
                Nenhum resultado encontrado
              </Text>
            )}
            {optionsFiltered.map(option => {
              const isSelected = value.some(v => v.value === option.value)
              const selectedButtonBg = bg === 'gray.100' ? 'gray.200' : 'gray.700'
              return (
                <Flex key={option.value} justifyContent="space-between" gridGap="2" p="1">
                  <Button
                    w="100%"
                    border="1px"
                    key={option.value}
                    justifyContent="space-between"
                    bg={isSelected ? selectedButtonBg : undefined}
                    onClick={() => handleSelect(option)}
                    leftIcon={isSelected ? <Icon as={MdCheckCircle} color="green.500" /> : undefined}
                  >
                    {option.label}
                  </Button>
                </Flex>
              )
            })}
          </Box>
        )}
      </FormControl>
    </Box>
  )
}
