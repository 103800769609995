/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Divider, Grid, GridItem, Heading, useToast } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'
import { DeepMap, FieldError, FieldValues, UseFormSetValue } from 'react-hook-form'
import { DropzoneImageUpload } from '../../../../../components/DropzoneImageUpload'
import {
  Autocomplete,
  AutocompleteAsync,
  AutocompleteMulti,
  Input,
  InputNumberMask,
  Select,
  SelectOption,
  TextArea,
} from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import useQueryParamUpdater from '../../../../../hooks/useQueryParamUpdater'
import { getFreightByNumber } from '../../../../../services/endpoints/freights/getFreightByNumber'
import { LoadingRequestTypeFormatted } from '../../../../../services/endpoints/freights/loading-request/GetLoadingRequest'
import { fetchMotorist } from '../../../../../services/endpoints/motorists/getMotorist'
import { searchMotoristByName } from '../../../../../services/endpoints/motorists/searchMotoristByName'
import { fetchOwner } from '../../../../../services/endpoints/owners/getOwner'
import { searchOwnerByName } from '../../../../../services/endpoints/owners/searchOwnerByName'
import { bankList } from '../../../../../services/utils/bankList'
import formatCPFAndCNPJ from '../../../../../services/utils/formatCPFAndCNPJ'

type CreateOrUpdateLoadingRequestFormType = {
  errors: DeepMap<FieldValues, FieldError>
  setValue: UseFormSetValue<FieldValues>
  initial_value?: LoadingRequestTypeFormatted
}

type CustomFormValuesType = {
  client?: {
    label: string
  }
  ctes?: SelectOption[]
  km_travelled?: string
  vehicle_categories?: SelectOption[]
  motorist?: {
    motorist_infos: {
      label: string
      value: string
    }
    license_plates?: SelectOption[]
  }
  beneficiary?: {
    beneficiary_infos: {
      label: string
      value: string
    }
    document: string
    payment_infos: {
      bank?: SelectOption
      agency?: string
      account_type?: string
      account?: string
    }
  }
}

export function CreateOrUpdateLoadingRequestForm({
  errors,
  setValue,
  initial_value,
}: CreateOrUpdateLoadingRequestFormType): JSX.Element {
  const toast = useToast()
  const [paymentMethod, setPaymentMethod] = useState('')
  const [is_efrete, setIsEfrete] = useState('no')
  const [freightNumber, setFreightNumber] = useState<string>('')
  const [customFormValues, setCustomFormValues] = useState<CustomFormValuesType>()
  const { getParams } = useQueryParamUpdater<{
    freight_number?: string
  }>()

  const getFormData = useCallback(
    async (freight_number?: string): Promise<void> => {
      let freight
      let motorist
      let anttOwner

      if (freight_number) {
        toast({
          title: 'Buscando dados do frete!',
          status: 'info',
          position: 'top',
          isClosable: true,
          duration: 3000,
        })

        freight = await getFreightByNumber(freight_number)

        if (freight) {
          setValue('freight_id', freight.id)
          motorist = freight.motorist
          anttOwner = freight.vehicle?.antt_owner

          toast({
            title: 'Frete encontrado com sucesso!',
            status: 'success',
            position: 'top',
            isClosable: true,
            duration: 3000,
          })
        }
      } else {
        freight = initial_value?.freight
        motorist = initial_value?.motorist
        anttOwner = initial_value?.beneficiary
        setPaymentMethod(initial_value?.pix ? 'pix' : 'account')
      }

      if (freight) {
        const customFormValuesData: CustomFormValuesType = {
          client: {
            label: freight.client.name,
          },
        }

        if (freight.ctes && freight.ctes.length > 0) {
          const { ctes } = freight
          const ctesFormated = ctes.map(cte => ({ label: String(cte.nct), value: cte.id }))
          Object.assign(customFormValuesData, {
            ctes: ctesFormated,
          })
        }

        if (freight.distance && freight.distance_to_route) {
          const distance = Number(freight.distance || 0)
          const distanceToRoute = Number(freight.distance_to_route || 0)

          Object.assign(customFormValuesData, {
            km_travelled: `${Number(distance - distanceToRoute).toFixed(2)} Km`,
          })
        } else {
          Object.assign(customFormValuesData, {
            km_travelled: `0 Km`,
          })
        }

        if (freight.vehicle_categories && freight.vehicle_categories.length > 0) {
          const vehicleCategories = freight.vehicle_categories.map(category => ({
            label: String(category.name),
            value: String(category.id),
          }))

          Object.assign(customFormValuesData, {
            vehicle_categories: vehicleCategories,
          })
        }

        if (motorist) {
          const motoristVehicleLiscensePlates = motorist.vehicles.map(vehicle => ({
            label: String(vehicle.license_plate),
            value: String(vehicle.id),
          }))

          Object.assign(customFormValuesData, {
            motorist: {
              motorist_infos: {
                label: motorist.name,
                value: motorist.id,
              },
              license_plates: motoristVehicleLiscensePlates,
            },
          })
        } else {
          toastError({ toast, error: 'Frete não possui motorista' })
        }

        if (anttOwner) {
          const anttOwnerDocument = anttOwner.type === 'pf' ? anttOwner.cpf : anttOwner.cnpj

          Object.assign(customFormValuesData, {
            beneficiary: {
              beneficiary_infos: {
                label: `${anttOwner.name}`,
                value: anttOwner.id,
              },
              document: formatCPFAndCNPJ(anttOwnerDocument),
              payment_infos: {
                agency: initial_value?.agency || anttOwner.agency,
                account: initial_value?.account || anttOwner.account,
                account_type: initial_value?.account_type || anttOwner.account_type,
                bank: {
                  value: initial_value?.bank || anttOwner.bank,
                  label: initial_value?.bank || anttOwner.bank,
                },
              },
            },
          })
        } else {
          toastError({ toast, error: 'Responsável pela ANTT não encontrado' })
        }

        setCustomFormValues(customFormValuesData)
      }
    },
    [initial_value, setValue, toast],
  )

  useEffect(() => {
    if (
      (freightNumber && freightNumber.length >= 4) ||
      (initial_value && initial_value.freight?.freight_number)
    ) {
      getFormData(freightNumber)
    }
  }, [freightNumber, toast, initial_value, getFormData])

  useEffect(() => {
    const freightNumberQuery = getParams('freight_number')

    if (freightNumberQuery) {
      setFreightNumber(freightNumberQuery)
    }
  }, [getParams, setValue])

  return (
    <>
      <Heading size="md" fontWeight="normal" mb="5">
        Informações do frete
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            label="Número do frete"
            name="freight_number"
            error={errors.freight_number}
            placeholder="Número do frete"
            type="number"
            autoFocus
            setValue={setValue}
            isDisabled={!!initial_value?.freight?.freight_number}
            onChange={e => setFreightNumber(e.target.value)}
            initialValue={initial_value?.freight?.freight_number || freightNumber}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]} mr={2}>
          <AutocompleteAsync
            label="Motorista"
            name="motorist"
            error={errors.motorist}
            placeholder="Motorista"
            setValue={setValue}
            loadOptions={searchMotoristByName}
            onSelectOption={async motorist => {
              if (motorist.value) {
                const motoristData = await fetchMotorist(String(motorist.value))

                if (motoristData) {
                  const motoristVehicleLiscensePlates = motoristData.vehicles.map(vehicle => ({
                    label: String(vehicle.license_plate),
                    value: String(vehicle.id),
                  }))

                  setCustomFormValues(prevState => {
                    return {
                      ...prevState,
                      motorist: {
                        motorist_infos: {
                          label: motoristData.name,
                          value: motoristData.id,
                        },
                        license_plates: motoristVehicleLiscensePlates,
                      },
                    }
                  })
                }
              }
            }}
            initialValue={customFormValues?.motorist?.motorist_infos}
            isDisabled={!customFormValues}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="Placa do veículo (Motorista)"
            name="motorist_vehicle_id"
            error={errors.motorist_vehicle_id}
            options={
              customFormValues?.motorist?.license_plates?.length
                ? [{ label: '', value: '' }, ...customFormValues?.motorist?.license_plates]
                : []
            }
            setValue={setValue}
            initialValue={initial_value?.motorist_vehicle?.id}
            isDisabled={!customFormValues}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]} mr={2}>
          <Input
            title="Cliente"
            label="Cliente"
            name="client"
            error={errors.client}
            placeholder="Cliente"
            setValue={setValue}
            initialValue={customFormValues?.client?.label}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="CTe"
            name="cte_id"
            error={errors.cte_id}
            setValue={setValue}
            options={
              customFormValues?.ctes?.length
                ? [{ label: '', value: '' }, ...customFormValues?.ctes]
                : [{ label: 'Frete não possui CTe', value: '' }]
            }
            isDisabled={!customFormValues}
            initialValue={initial_value?.cte_id}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="Desconto Buonny"
            name="discount_buonny"
            error={errors.discount_buonny}
            setValue={setValue}
            options={[
              { label: 'Não', value: '0' },
              { label: 'R$ 35', value: '35' },
              { label: 'R$ 45', value: '45' },
            ]}
            isDisabled={!customFormValues}
            initialValue={String(initial_value?.discount_buonny)}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            label="Km Percorrido"
            name="km_travelled"
            error={errors.km_travelled}
            placeholder="Km Percorrido"
            initialValue={customFormValues?.km_travelled}
            setValue={setValue}
            isDisabled
          />
        </GridItem>

        <GridItem colSpan={[12, 4, 5]}>
          <AutocompleteMulti
            name="vehicle_categories"
            label="Tipo de veículos"
            error={errors.vehicle_categories}
            options={[]}
            setValue={setValue}
            isDisabled
            initialValue={
              customFormValues?.vehicle_categories &&
              customFormValues?.vehicle_categories.length > 0 &&
              customFormValues?.vehicle_categories.every(category => category.label !== 'undefined')
                ? customFormValues?.vehicle_categories
                : undefined
            }
          />
        </GridItem>
      </Grid>

      <Divider my="4" />

      <Heading size="md" fontWeight="normal" mb="5">
        Informações solicitação de carregamento
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <Input
            label="Motivo"
            name="reason"
            error={errors.reason}
            placeholder="Motivo"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.reason}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 4]} mr={2}>
          <Input
            label="Tipo"
            name="type"
            error={errors.type}
            placeholder="Tipo"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.type}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 2]} mr={2}>
          <InputNumberMask
            label="Valor Total"
            name="total_value"
            error={errors.total_value}
            placeholder="Valor Total"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={Number(initial_value?.total_value)}
            isCashBRL
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 2]} mr={2}>
          <InputNumberMask
            label="Valor Bruto"
            name="total_gross"
            error={errors.total_gross}
            placeholder="Valor Bruto"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.total_gross}
            isCashBRL
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]}>
          <TextArea
            name="origin"
            h="130"
            error={errors.origin}
            label="Origem"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.origin}
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 6]}>
          <TextArea
            name="destination"
            h="130"
            error={errors.destination}
            label="Destino"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.destination}
          />
        </GridItem>
      </Grid>

      <Divider my="4" />

      <Heading size="md" fontWeight="normal" mb="5">
        Informações de pagamento
      </Heading>

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 6]} mr={2}>
          <AutocompleteAsync
            label="Nome Favorecido"
            name="beneficiary"
            error={errors.beneficiary}
            placeholder="Nome Favorecido"
            setValue={setValue}
            isDisabled={!customFormValues}
            loadOptions={searchOwnerByName}
            onSelectOption={async beneficiary => {
              if (beneficiary.value) {
                const ownerData = await fetchOwner(String(beneficiary.value))

                if (ownerData) {
                  const anttOwnerDocument = ownerData.type === 'pf' ? ownerData.cpf : ownerData.cnpj

                  setCustomFormValues(prevState => {
                    return {
                      ...prevState,
                      beneficiary: {
                        beneficiary_infos: {
                          label: ownerData.name,
                          value: ownerData.id,
                        },
                        document: formatCPFAndCNPJ(anttOwnerDocument)!,
                        payment_infos: {
                          agency: ownerData.agency,
                          account: ownerData.account,
                          account_type: ownerData.account_type,
                          bank: {
                            value: ownerData.bank || '',
                            label: ownerData.bank || '',
                          },
                        },
                      },
                    }
                  })
                }
              }
            }}
            initialValue={customFormValues?.beneficiary?.beneficiary_infos}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Input
            label="Documento Favorecido"
            name="beneficiary_document"
            error={errors.beneficiary_document}
            placeholder="Documento Favorecido"
            setValue={setValue}
            isDisabled
            initialValue={customFormValues?.beneficiary?.document}
            isRequired
          />
        </GridItem>

        <GridItem colSpan={[12, 6, 3]} mr={2}>
          <Select
            label="E-Frete"
            name="e_frete"
            setValue={setValue}
            error={errors.e_frete}
            options={[
              {
                label: 'Não',
                value: 'no',
              },
              {
                label: 'Sim',
                value: 'yes',
              },
            ]}
            onSelectOption={option => {
              setIsEfrete(option.value)
              setPaymentMethod('')
              setValue('payment_type', '')
            }}
            isDisabled={!customFormValues}
            initialValue={initial_value?.e_frete ? 'yes' : 'no'}
          />
        </GridItem>

        {is_efrete === 'no' && (
          <GridItem colSpan={[12, 6, 3]} mr={2}>
            <Select
              label="Tipo de pagamento"
              name="payment_type"
              error={errors.payment_type}
              setValue={setValue}
              options={[
                {
                  label: '',
                  value: '',
                },
                {
                  label: 'Conta bancaria',
                  value: 'account',
                },
                {
                  label: 'Pix',
                  value: 'pix',
                },
              ]}
              onSelectOption={option => {
                setPaymentMethod(option.value)
              }}
              initialValue={paymentMethod}
              isDisabled={!customFormValues}
            />
          </GridItem>
        )}

        {paymentMethod === 'pix' && (
          <GridItem colSpan={[12, 6, 9]} mr={2}>
            <Input
              name="pix"
              error={errors.pix}
              label="Pix"
              setValue={setValue}
              isDisabled={!customFormValues}
              initialValue={initial_value?.pix}
            />
          </GridItem>
        )}

        {paymentMethod === 'account' && (
          <>
            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Select
                name="account_type"
                error={errors.account_type}
                label="Tipo de conta"
                setValue={setValue}
                options={[
                  { label: '', value: '' },
                  { label: 'Corrente', value: 'current' },
                  { label: 'Poupança', value: 'savings' },
                  { label: 'Outros', value: 'others' },
                ]}
                initialValue={customFormValues?.beneficiary?.payment_infos.account_type}
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 6]}>
              <Autocomplete
                name="bank"
                error={errors.bank}
                label="Banco"
                setValue={setValue}
                options={[...bankList]}
                initialValue={
                  customFormValues?.beneficiary?.payment_infos.bank
                    ? customFormValues?.beneficiary?.payment_infos.bank
                    : undefined
                }
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Input
                name="agency"
                error={errors.agency}
                label="Agencia"
                setValue={setValue}
                initialValue={customFormValues?.beneficiary?.payment_infos.agency}
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              <Input
                name="account"
                error={errors.account}
                label="Conta"
                setValue={setValue}
                initialValue={customFormValues?.beneficiary?.payment_infos.account}
                isDisabled={!customFormValues}
              />
            </GridItem>

            <GridItem colSpan={[12, 3]}>
              <Input
                name="account_digit"
                error={errors.account_digit}
                label="Cód. Banco"
                setValue={setValue}
                initialValue={initial_value?.account_digit}
                isDisabled={!customFormValues}
              />
            </GridItem>
          </>
        )}
      </Grid>

      {!initial_value && (
        <>
          <Divider my="4" />
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[12, 6, 12]}>
              <DropzoneImageUpload
                name="attachment_file"
                setValue={setValue}
                displayMode="list"
                multiple
                label="Anexos"
              />
            </GridItem>
          </Grid>
        </>
      )}

      <Divider my="4" />

      <Grid templateColumns="repeat(12, 1fr)" gap="3">
        <GridItem colSpan={[12, 6, 12]}>
          <TextArea
            name="observation"
            h="150"
            error={errors.observation}
            label="Observação"
            setValue={setValue}
            isDisabled={!customFormValues}
            initialValue={initial_value?.observation}
          />
        </GridItem>
      </Grid>
    </>
  )
}
