import { useAuth } from '../contexts/AuthContext'
import { UserRolesOptions } from '../services/types/AuthorizationModulesTypes'

interface useCanParams {
  roles?: UserRolesOptions[]
  accounts?: string[]
}

export function useCan({ roles, accounts }: useCanParams): boolean {
  const { user } = useAuth()
  if (user) {
    if (roles?.includes(user.role) || user.role === 'super') {
      return true
    }
    if (accounts?.includes(user.email)) {
      return true
    }
  }

  return false
}
