import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResArrayApiType } from '../../types/ResponseApiType'

interface TollTagsTransportersParams {
  plate?: string
}

interface TollTagsTransportersReturn {
  provider: string
  tag: string
  categoria: {
    id: number
    codigo: number
    nome: string
    numeroEixo: number
    rodagem: string
  }
}

export async function getTollTagsTransporters(
  params: TollTagsTransportersParams,
): Promise<TollTagsTransportersReturn[] | undefined> {
  if (params.plate && params.plate.length > 0) {
    const { data: response } = await apiServer.get<ResArrayApiType<TollTagsTransportersReturn>>(
      `/truckpad/toll/tags/transporters`,
      {
        params,
      },
    )

    return response.data
  }
}

export function useTollTagsTransporters(
  params: TollTagsTransportersParams,
): UseQueryResult<TollTagsTransportersReturn[] | undefined, Error> {
  return useQuery(['get_toll_tags', params], () => getTollTagsTransporters(params))
}
