import { Badge, Box, Divider, Flex, Icon, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { RiRoadMapLine } from 'react-icons/ri'
import { QualpRoute } from '../../../../../../services/endpoints/qualp/postRoutePlanner'
import parseCurrency from '../../../../../../services/utils/parseCurrency'
import { calculateTotalToll } from '../../../services/calculate-total-toll'
import { formatRouteDurationToReadable } from '../../../services/format-duration'
import { RoutePlannerContext } from '../../ShowRoutePlanner'

type RouteProps = {
  route: QualpRoute
  index: number
}

export function Route({ route, index }: RouteProps): JSX.Element {
  const { state, dispatch } = useContext(RoutePlannerContext)

  return (
    <>
      <Box
        key={route?.distance}
        bg={state.theme.cardBackground}
        mt={4}
        p={4}
        rounded="md"
        cursor="pointer"
        borderWidth="2px"
        borderColor={state.route && state.route.id === index ? 'orange.500' : 'transparent'}
        _hover={{ borderColor: 'orange.500' }}
        onClick={() => {
          if (!state.route || (state.route && state.route.id !== index)) {
            dispatch({ type: 'set_route', payload: { id: index, route } })
          }
        }}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Flex flexDirection="row" alignItems="center">
            <Flex>
              <Icon
                as={RiRoadMapLine}
                bg={state.theme.iconBackground}
                p={2}
                fontSize={40}
                borderRadius="base"
              />
            </Flex>

            <Flex ml={4} flexDirection="column">
              <Text fontWeight="bold">
                #{index + 1} - {route?.distance.toLocaleUpperCase()}
              </Text>
              <Text fontSize="md" textColor="gray.400">
                {formatRouteDurationToReadable(route.duration)}
              </Text>
            </Flex>
          </Flex>

          <Box>
            <Badge fontSize="large" colorScheme="green">
              {route?.tolls && route.tolls.length && route.tolls.length > 0
                ? parseCurrency(calculateTotalToll(route.tolls, state.form.axes_number))
                : 'R$ 0,00'}
            </Badge>
          </Box>
        </Flex>

        {route?.tags && route.tags.length > 0 && (
          <Flex flexDirection="column">
            <Divider mt={1} mb={2} />
            <Flex>
              {route.tags.map(tag => (
                <Text mr={2}>
                  <Badge fontSize="12px" colorScheme={tag.color} py={1} px={2}>
                    {tag.label}
                  </Badge>
                </Text>
              ))}
            </Flex>
          </Flex>
        )}
      </Box>
    </>
  )
}
