import { Flex, Box, Heading, Divider, Button, Icon, useDisclosure } from '@chakra-ui/react'
import { useContext } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { AddStepRoutePlannerModal } from '../../modals/AddStepRoutePlannerModal'
import { RoutePlannerContext } from '../../ShowRoutePlanner'
import { TimelineStepCity } from '../TimelineStepCity'
import { TimelineStepWarpper } from '../TimelineStepWarpper'

export function StepsManager(): JSX.Element {
  const { state } = useContext(RoutePlannerContext)

  const {
    isOpen: isOpenAddStepRoutePlannerModal,
    onClose: onCloseAddStepRoutePlannerModal,
    onOpen: onOpenAddStepRoutePlannerModal,
  } = useDisclosure()

  const { freight } = state
  const { origin, destination } = freight

  return (
    <>
      <Flex p={4} bg={state.theme.cardBackground} rounded="md" flexDirection="column">
        <Flex justifyContent="space-between">
          <Heading fontSize="2xl">Definição de rota</Heading>

          {!state.qualpResult && !state.initialRoute && (
            <Box>
              <Button size="sm" onClick={onOpenAddStepRoutePlannerModal}>
                <Icon as={AiOutlinePlus} mr="1" />
                Adicionar parada
              </Button>
            </Box>
          )}
        </Flex>

        <Divider my={2} />

        <TimelineStepWarpper>
          <TimelineStepCity label="origin" city={origin} />

          {state.steps.map(step => (
            <TimelineStepCity label="step" stepCity={step} />
          ))}

          <TimelineStepCity label="destination" city={destination} />
        </TimelineStepWarpper>
      </Flex>

      {isOpenAddStepRoutePlannerModal && (
        <AddStepRoutePlannerModal
          isOpen={isOpenAddStepRoutePlannerModal}
          onClose={onCloseAddStepRoutePlannerModal}
        />
      )}
    </>
  )
}
