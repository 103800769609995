import { SimpleGrid, SimpleGridProps, useColorModeValue } from '@chakra-ui/react'

export function TimelineStepWarpper(props: SimpleGridProps): JSX.Element {
  const borderColor = useColorModeValue('gray.300', 'gray.600')

  return (
    <SimpleGrid
      columns={1}
      ml="3"
      pl="3"
      gridGap="2"
      borderLeftWidth="2px"
      borderLeftStyle="dashed"
      borderLeftColor={borderColor}
      {...props}
    />
  )
}
