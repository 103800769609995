import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaArrowRight } from 'react-icons/fa'
import { RiUser2Fill } from 'react-icons/ri'
import { Ability } from '../../../components/ability'
import { Select } from '../../../components/form/Select'
import { TextArea } from '../../../components/form/TextArea'
import { toastError } from '../../../config/error/toastError'
import { queryClient } from '../../../config/react-query'
import { useForceStatusRiskAnalysis } from '../../../services/endpoints/risk-analysis/forceStatusRiskAnalysis'
import { riskAnalysisStatusOptions } from '../../../services/endpoints/risk-analysis/getOneRiskAnalysis'
import { useAppSelector } from '../../../store'

export function ChangeStatusModal(): JSX.Element {
  const [reason, setReason] = useState<string>()
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { riskAnalysis } = useAppSelector(state => state.riskAnalysisSlice)
  const toast = useToast()
  const [status, setStatus] = useState<
    'awaiting' | 'awaiting_gr' | 'to_correct' | 'to_check' | 'disapproved' | 'canceled' | 'approved'
  >()
  const { setValue, setError, formState } = useForm({})
  const { mutateAsync: mutateAsyncForceStatus, isLoading } = useForceStatusRiskAnalysis()

  const handleChangeStatus = async () => {
    try {
      if (status && riskAnalysis && reason) {
        if (reason.trim().length <= 5) {
          setError('reason', {
            message: 'Observação muito curta! Deve conter mais de 5 caracteres',
          })
          return
        }
        setError('reason', { shouldFocus: undefined })
        await mutateAsyncForceStatus({
          id: riskAnalysis.id,
          status,
          reason,
        })

        toast({
          title: 'Alteração realizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 8000,
        })
        onClose()
        queryClient.invalidateQueries('is-block-entity')
      }
    } catch (error) {
      toastError({ toast, error })
    }
  }

  useEffect(() => {
    setStatus(undefined)
    setReason(undefined)
  }, [isOpen])

  return (
    <Ability module="operation" action="force-status-risk-analysis">
      <Button rightIcon={<RiUser2Fill />} minW="140" size="sm" onClick={onOpen} colorScheme="red">
        Forçar status
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
        }}
        closeOnOverlayClick={false}
        size="md"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalHeader>Mudar status</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid minChildWidth="300px" spacing={2}>
              <Select
                name="status"
                label="Status"
                setValue={setValue}
                onSelectOption={op => {
                  setStatus(
                    op.value as
                      | 'awaiting'
                      | 'awaiting_gr'
                      | 'to_correct'
                      | 'to_check'
                      | 'disapproved'
                      | 'canceled'
                      | 'approved',
                  )
                }}
                options={riskAnalysisStatusOptions}
              />
              <Text>Informe o motivo</Text>
              <TextArea
                setValue={setValue}
                name="reason"
                onChange={e => setReason(e.target.value)}
                error={formState.errors.reason}
              />
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button
              rightIcon={<FaArrowRight />}
              colorScheme="green"
              isLoading={isLoading}
              isDisabled={!status || !reason}
              onClick={() => {
                handleChangeStatus()
              }}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Ability>
  )
}
