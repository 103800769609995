import { useColorModeValue } from '@chakra-ui/react'
import { createContext, useEffect, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import {
  initialValueRoutePlannerState,
  RoutePlannerAction,
  routePlannerReducer,
  RoutePlannerState,
} from './reducer'
import { RoutePlanner } from './RoutePlanner'

type RequestParams = {
  freight_id: string
}

type ContextType = {
  state: RoutePlannerState
  dispatch: React.Dispatch<RoutePlannerAction>
}

export const RoutePlannerContext = createContext<ContextType>({
  state: initialValueRoutePlannerState,
  dispatch: () => null,
})

export default function ShowRoutePlanner(): JSX.Element {
  const { freight_id } = useParams<RequestParams>()
  const [state, dispatch] = useReducer(routePlannerReducer, initialValueRoutePlannerState)
  const cardBackground = useColorModeValue('white', 'gray.800')
  const iconBackground = useColorModeValue('gray.100', 'gray.700')

  useEffect(() => {
    dispatch({ type: 'set_card_background', payload: cardBackground })
    dispatch({ type: 'set_icon_background', payload: iconBackground })
  }, [cardBackground, iconBackground])

  return (
    <RoutePlannerContext.Provider value={{ state, dispatch }}>
      <RoutePlanner freight_id={freight_id} />
    </RoutePlannerContext.Provider>
  )
}
