/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Badge,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react'
import { useEffect } from 'react'
import { toastError } from '../../../../../../config/error/toastError'
import { Pagination } from '../../../../../../layout/Pagination'
import {
  FilterLoadingRequestsType,
  useGetAllLoadingRequest,
} from '../../../../../../services/endpoints/freights/loading-request/GetAllLoadingRequest'
import { getLoadingRequestColorByStatus } from '../../../../../../services/utils/getLoadingRequestColorByStatus'
import { loadingRequestStatusLegend } from '../../../../../../services/utils/loadingRequestStatusLegend'
import { formatToShortName } from '../../../../../freights-in-progress'

type LoadingRequestTableListProps = {
  handleChangePage?: (newPage: number) => void
  filters?: FilterLoadingRequestsType
  initialDate?: string | undefined
  finalDate?: string | undefined
  hasPagination?: boolean
}

export const titleColumnsLoadingRequest = [
  'Frete',
  'Status',
  'Tipo',
  'Motorista',
  'Valor total',
  'Responsável',
  'Data',
]

export function LoadingRequestTableList({
  handleChangePage,
  filters,
  initialDate,
  finalDate,
  hasPagination,
}: LoadingRequestTableListProps): JSX.Element {
  const toast = useToast()
  const hoverBgLineTable = useColorModeValue('gray.200', 'gray.700')

  const { data, isLoading, isError, error } = useGetAllLoadingRequest({
    initial_date: filters?.initial_date || initialDate,
    final_date: filters?.final_date || finalDate,
    ...filters,
  })

  useEffect(() => {
    if (isError) {
      toastError({ toast, error })
    }
  }, [toast, isError, error])

  return (
    <>
      {!isLoading ? (
        data && data.data && data.data.length > 0 ? (
          <>
            <Table size="sm">
              <Thead>
                <Tr>
                  {titleColumnsLoadingRequest.map(title => (
                    <Th key={title}>{title}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {data.data.map(loadingRequest => (
                  <Tr
                    key={loadingRequest.id}
                    cursor="pointer"
                    _hover={{ bg: hoverBgLineTable, transition: '0.3s' }}
                    onClick={() => {
                      window.open(`/loading-requests/show/${loadingRequest.id}`, '_blank')?.focus()
                    }}
                  >
                    <Td>#{loadingRequest?.freight?.freight_number}</Td>
                    <Td>
                      <Tooltip
                        label={
                          loadingRequest.reject_reason || loadingRequestStatusLegend(loadingRequest.status)
                        }
                        hasArrow
                        placement="top"
                        arrowSize={15}
                      >
                        <Badge
                          px="2"
                          py="1"
                          colorScheme={getLoadingRequestColorByStatus(loadingRequest.status)}
                          w="100%"
                          textAlign="center"
                        >
                          {loadingRequest.formatted_status}
                        </Badge>
                      </Tooltip>
                    </Td>
                    <Td>
                      {loadingRequest.type.length > 10 ? (
                        <Tooltip label={loadingRequest.type} hasArrow placement="top" arrowSize={15}>
                          <Text>
                            {String(loadingRequest.type).substring(0, 10)}
                            {loadingRequest.type.length > 10 ? '...' : ''}
                          </Text>
                        </Tooltip>
                      ) : (
                        loadingRequest.type
                      )}
                    </Td>
                    <Td>
                      <Tooltip label={loadingRequest.motorist?.name} hasArrow placement="top" arrowSize={15}>
                        {loadingRequest?.motorist?.name ? (
                          <Text>{formatToShortName(loadingRequest?.motorist?.name)}</Text>
                        ) : null}
                      </Tooltip>
                    </Td>
                    <Td>{loadingRequest.total_value_formatted}</Td>
                    <Td>{formatToShortName(loadingRequest.creator?.name || '')}</Td>
                    <Td>{loadingRequest.created_at}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>

            {hasPagination && handleChangePage && (
              <Pagination
                currentPage={filters?.page || 1}
                totalCountOfRegisters={data?.total ?? 0}
                registersPerPage={15}
                onPageChange={handleChangePage}
                pt="4"
                pb="1"
              />
            )}
          </>
        ) : isError ? (
          <Text textAlign="center">Erro ao carregar as ordens de carregamento</Text>
        ) : (
          <Text textAlign="center">Nenhuma solicitação de carregamento encontrada</Text>
        )
      ) : (
        <Flex justifyContent="center" alignItems="center">
          <Spinner mr={2} />
          <Text>Carregando dados</Text>
        </Flex>
      )}
    </>
  )
}
