import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { Authorization, ResponseApiType } from '../../types'
import { UserRolesOptions } from '../../types/AuthorizationModulesTypes'

type Input = { roles: UserRolesOptions[] }
type Output = Authorization

export async function getPermissionsByRoles(params: Input): Promise<Output> {
  const { data } = await apiServer.get<ResponseApiType<Output[]>>('/authorization/permissions/roles', {
    params,
  })
  return data.data[0]
}

export function useGetPermissionsByRoles(params: Input): UseQueryResult<Output, unknown> {
  return useQuery(['getPermissionsByRoles', params], () => getPermissionsByRoles(params), {
    enabled: !!params.roles[0],
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 60 * 60 * 2, // 2 hours
  })
}
