import { Box, Text, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

type RouteAnalysisGraphicProps = {
  data:
    | {
        name: string
        [key: string]: unknown
      }[]
    | undefined
}

export function RouteAnalysisGraphic({ data }: RouteAnalysisGraphicProps): JSX.Element {
  const [dataKeys, setDataKeys] = useState<string[]>([])
  const bg = useColorModeValue('white', 'gray.800')
  const tooltipBgColor = useColorModeValue('white', '#27272a')
  const tooltipTextColor = useColorModeValue('black', 'white')

  const isValidGraphic = !!(data && data.length && data.length > 0)

  function getKeys(dataArray: any): string[] {
    const keys = dataArray[0] ? Object.keys(dataArray[0]) : []
    return keys.filter(key => key !== 'name')
  }

  const getHexColorFromIndex = (index: number) => {
    const colors = [
      '#e6194b',
      '#3cb44b',
      '#4363d8',
      '#f58231',
      '#911eb4',
      '#46f0f0',
      '#f032e6',
      '#bcf60c',
      '#fabebe',
    ]
    return colors[index % colors.length]
  }

  useEffect(() => {
    if (data) {
      const keys = getKeys(data)
      setDataKeys(keys)
    }
  }, [data])

  return (
    <Box bg={bg} p="6" borderRadius="8" shadow="md" mb="8">
      {isValidGraphic ? (
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={data} accessibilityLayer>
            <CartesianGrid />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip contentStyle={{ backgroundColor: tooltipBgColor, color: tooltipTextColor }} />
            <Legend />
            {dataKeys.map((key, index) => (
              <Line type="bump" dataKey={key} stroke={getHexColorFromIndex(index)} />
            ))}
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Text align="center" color="gray.400" mb={1}>
          Nenhum dado encontrado
        </Text>
      )}
    </Box>
  )
}
