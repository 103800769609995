/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Flex, Icon, Spinner, Text, useColorModeValue, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useContext, useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaRegTrashCan } from 'react-icons/fa6'
import { IoArrowBackOutline } from 'react-icons/io5'
import { TbMapSearch, TbRoute } from 'react-icons/tb'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'

import { useGetOneFreight } from '../../../../services/endpoints/freights'
import { usePostRoutePlannerQualp } from '../../../../services/endpoints/qualp/postRoutePlanner'
import {
  GetRoutePointsReturn,
  useGetRoutePoints,
} from '../../../../services/endpoints/truckpad/find-route-points'
import { usePostRoutePoints } from '../../../../services/endpoints/truckpad/usePostRoutePoints'
import { addTagsAtRoute } from '../services/add-tags-at-route'
import { calculateTotalToll } from '../services/calculate-total-toll'
import { decodePolyline } from '../services/decode-polyline-service'
import { HandleRoutesForm } from './components/HandleRoutesForm'
import { RoutePlannerHeader } from './components/Header'
import { InitialRoute } from './components/InitialRoute'
import { Route } from './components/Route'
import { StepsManager } from './components/StepsManager'
import { RoutePlannerCity } from './reducer'
import { RoutePlannerMap } from './RoutePlannerMap'
import { RoutePlannerContext } from './ShowRoutePlanner'

type RoutePlannerProps = {
  freight_id: string
}

type CreateRoutePlannerFormData = {
  vehicleCategory: string
  truckCategory: string
  freight_type: string
  cargoType: string
  typeRoute: string
  alternativeRoutes: string
  optimizedRoute: string
}

type Point = {
  latitude: number
  longitude: number
  stops: string | null
}

const routePlannerFormSchema = yup.object().shape({
  vehicleCategory: yup.string().required('Campo obrigatório'),
  truckCategory: yup.mixed().when('vehicleCategory', {
    is: 'truck',
    then: yup.mixed().required('Campo obrigatório'),
  }),
  freight_type: yup.string().required('Campo obrigatório'),
  cargoType: yup.string().required('Campo obrigatório'),
  typeRoute: yup.string().required('Campo obrigatório'),
  alternativeRoutes: yup.mixed().required('Campo obrigatório'),
})

export function RoutePlanner({ freight_id }: RoutePlannerProps): JSX.Element {
  const toast = useToast()
  const bg = useColorModeValue('gray.100', 'gray.900')
  const history = useHistory()
  const { state, dispatch } = useContext(RoutePlannerContext)

  const { data: freight, isLoading: isLoadingFreight } = useGetOneFreight({
    freight_id,
    relations: ['origin', 'destination', 'cargo', 'vehicle_categories'],
  })

  const {
    mutateAsync: createRoutePlannerQualp,
    isLoading: isLoadingRoutePlannerQualp,
    error: errorRoutePlannerQualp,
  } = usePostRoutePlannerQualp({
    onSuccess: async () => {
      toast({
        title: 'Requisição feita com sucesso!',
        description: 'Dados do pedágio consultados com sucesso.',
        status: 'success',
        duration: 10000,
        position: 'top',
        isClosable: true,
      })
    },
    onError: () => {
      toastError({ toast, error: errorRoutePlannerQualp })
    },
  })

  const { mutateAsync: createRoutePoints, isLoading: isLoadingCreateRoutePoints } = usePostRoutePoints({
    onSuccess: () => {
      toast({
        title: 'Rota salva com sucesso!',
        status: 'success',
        duration: 4000,
        position: 'top',
        isClosable: true,
      })

      queryClient.invalidateQueries('find-route-points')
      queryClient.invalidateQueries('check-pendencies')
    },
  })

  const { data: routePointsData, isLoading: isLoadingRoutePoints } = useGetRoutePoints({ freight_id })

  const { setValue, formState, handleSubmit } = useForm({
    resolver: yupResolver(routePlannerFormSchema),
  })

  const handleCreateRoutePlanner: SubmitHandler<CreateRoutePlannerFormData> = async data => {
    const response = await createRoutePlannerQualp({
      optimized_route: data.optimizedRoute === 'yes',
      alternative_routes: data.alternativeRoutes,
      type_route: data.typeRoute,
      vehicle_category: data.vehicleCategory,
      cargo_type: data.cargoType,
      freight_category: data.freight_type,
      origin: freight?.origin,
      destination: freight?.destination,
      steps: state.steps,
      optimized_route_destination: 'last',
      calculate_return: false,
      avoid_locations: false,
      avoid_locations_key: '',
      axes_number: data.truckCategory,
    })

    dispatch({
      type: 'set_form',
      payload: {
        axes_number: Number(data.truckCategory),
      },
    })

    const addTagsAtRoutes = addTagsAtRoute(response, Number(data.truckCategory))

    dispatch({ type: 'set_qualp_result', payload: addTagsAtRoutes })
  }

  async function handleSaveRoute(): Promise<void> {
    if (!state.route || !state.route.route) {
      toastError({ toast, error: 'Nenhuma rota selecionada' })
      return
    }

    let tollValue = 0

    if (state.route.route.tolls && state.route.route.tolls.length > 0) {
      tollValue = calculateTotalToll(state.route.route.tolls, state.form.axes_number)
    }

    const coordinates = decodePolyline(state.route.route.polyline)
    const routeDistance = state.route.route.distance

    const points: Point[] = coordinates.map((point: { lat: number; lng: number }) => ({
      latitude: point.lat,
      longitude: point.lng,
      stops: null,
    }))

    state.steps.forEach(stop => {
      points.push({
        latitude: Number(stop.lat),
        longitude: Number(stop.lng),
        stops: JSON.stringify(stop),
      })
    })

    if (freight_id) {
      await createRoutePoints({
        points,
        freight_id,
        toll_value: tollValue,
        provider: 'qualp',
        distance: routeDistance,
        duration: state.route.route.duration,
        tags: JSON.stringify(state.route.route.tags),
      })
    }
  }

  function handleDeleteInitialRoute(): void {
    dispatch({ type: 'set_initial_route', payload: null })
  }

  useEffect(() => {
    if (freight && freight.id) {
      dispatch({ type: 'set_freight', payload: freight })
    }
  }, [freight, dispatch])

  useEffect(() => {
    if (state.qualpResult && state.qualpResult.routes.length > 0) {
      let bestRouteIndex = 0
      let maxTags = 0

      state.qualpResult.routes.forEach((route: any, index: any) => {
        const tagCount = route.tags?.length || 0
        if (tagCount > maxTags) {
          maxTags = tagCount
          bestRouteIndex = index
        }
      })

      const bestRoute = {
        id: bestRouteIndex,
        route: state.qualpResult.routes[bestRouteIndex],
      }

      dispatch({ type: 'set_route', payload: bestRoute })
    }
  }, [state.qualpResult, dispatch])

  useEffect(() => {
    if (routePointsData && routePointsData.length && routePointsData.length > 0) {
      const initialRoutePoint = routePointsData[0]
      const points = routePointsData
        .filter(point => !point.stops)
        .map(point => {
          return {
            lat: point.latitude,
            lng: point.longitude,
          }
        })

      if (initialRoutePoint && initialRoutePoint.tags) {
        dispatch({
          type: 'set_initial_route',
          payload: {
            ...initialRoutePoint,
            tags: JSON.parse(initialRoutePoint.tags),
            polyline: points,
          },
        })
      } else {
        dispatch({
          type: 'set_initial_route',
          payload: {
            ...initialRoutePoint,
            polyline: points,
            tags: [],
          },
        })
      }

      const getStops = routePointsData.filter(point => point.stops)

      const stops: RoutePlannerCity[] = []

      getStops.forEach((stop: GetRoutePointsReturn) => {
        if (stop.stops) {
          stops.push(JSON.parse(stop.stops))
        }
      })

      dispatch({ type: 'set_steps', payload: stops })
    }
  }, [dispatch, routePointsData])

  return !isLoadingFreight ? (
    <Flex h="100vh" overflow="hidden">
      <Box w="25%" p={4} h="100%" background={bg} minW="500px" overflow="auto">
        <RoutePlannerHeader />

        <StepsManager />

        {!state.qualpResult &&
          !state.initialRoute &&
          (isLoadingRoutePlannerQualp ? (
            <Flex
              justifyContent="center"
              bg={state.theme.cardBackground}
              rounded="md"
              alignItems="center"
              mt={4}
              p={2}
            >
              <Text display="flex" alignItems="center" fontSize="lg">
                Carregando rotas <Spinner size="sm" ml="2" />
              </Text>
            </Flex>
          ) : (
            <form onSubmit={handleSubmit(handleCreateRoutePlanner)} noValidate>
              <HandleRoutesForm setValue={setValue} errors={formState.errors} />

              <Flex mt={4}>
                <Button
                  w="100%"
                  type="submit"
                  size="md"
                  colorScheme="green"
                  isLoading={isLoadingRoutePlannerQualp}
                  disabled={isLoadingRoutePlannerQualp}
                >
                  <Icon as={TbMapSearch} mr="1" />
                  Consultar rotas disponíveis
                </Button>
              </Flex>
            </form>
          ))}

        {!state.initialRoute && state.qualpResult && state.qualpResult.routes && (
          <>
            {state.qualpResult && state.qualpResult.routes && state.qualpResult.routes.length > 0 && (
              <>
                {isLoadingCreateRoutePoints ? (
                  <Flex
                    justifyContent="center"
                    bg={state.theme.cardBackground}
                    rounded="md"
                    alignItems="center"
                    mt={4}
                    p={2}
                  >
                    <Text display="flex" alignItems="center" fontSize="lg">
                      Salvando rota <Spinner size="sm" ml="2" />
                    </Text>
                  </Flex>
                ) : (
                  <>
                    {state.qualpResult.routes.map((route, index) => (
                      <>
                        <Route route={route} index={index} />
                        <Button
                          size="md"
                          colorScheme="green"
                          mt={4}
                          w="100%"
                          onClick={async () => {
                            await handleSaveRoute()
                          }}
                        >
                          <Icon as={TbRoute} mr="1" />
                          Selecionar rota
                        </Button>
                      </>
                    ))}
                  </>
                )}
              </>
            )}
          </>
        )}

        {state.initialRoute && (
          <>
            {isLoadingRoutePoints ? (
              <Flex
                justifyContent="center"
                bg={state.theme.cardBackground}
                rounded="md"
                alignItems="center"
                mt={4}
                p={2}
              >
                <Text display="flex" alignItems="center" fontSize="lg">
                  Carregando rota <Spinner size="sm" ml="2" />
                </Text>
              </Flex>
            ) : (
              <>
                <InitialRoute route={state.initialRoute} />
                <Button size="md" colorScheme="red" mt={4} w="100%" onClick={handleDeleteInitialRoute}>
                  <Icon as={FaRegTrashCan} mr="1" />
                  Deletar rota
                </Button>
              </>
            )}
          </>
        )}

        {!isLoadingRoutePlannerQualp && (
          <Box>
            <Button
              size="md"
              colorScheme="orange"
              mt={4}
              w="100%"
              onClick={() => {
                history.goBack()
              }}
            >
              <Icon as={IoArrowBackOutline} mr="1" />
              Voltar a tela de pendências
            </Button>
          </Box>
        )}
      </Box>

      <Box w="100%" h="100vh">
        {state.freight && state.freight.origin && state.freight.destination && (
          <RoutePlannerMap
            polyline={state.route.route?.polyline}
            initialRoutePolyline={state.initialRoute?.polyline}
          />
        )}
      </Box>
    </Flex>
  ) : (
    <Flex bg={bg} alignItems="center" justifyContent="center" h="100vh">
      <Text display="flex" alignItems="center" fontSize="lg">
        Carregando frete <Spinner size="sm" ml="2" />
      </Text>
    </Flex>
  )
}
