/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'
import { FormEvent, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { IoPush } from 'react-icons/io5'
import { RiFilter2Line, RiSearchLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { Ability } from '../../../../../components/ability'
import { AutocompleteAsync, Input, Select, SelectOption } from '../../../../../components/form'
import { ReportToExcel } from '../../../../../components/reports/ReportToExcel'
import Filter from '../../../../../components/UI/Filter'
import useQueryParamUpdater from '../../../../../hooks/useQueryParamUpdater'
import { Layout } from '../../../../../layout'
import {
  FilterLoadingRequestsType,
  listLoadingRequest,
} from '../../../../../services/endpoints/freights/loading-request/GetAllLoadingRequest'
import { searchMotoristByName } from '../../../../../services/endpoints/motorists/searchMotoristByName'
import { searchOwnerByName } from '../../../../../services/endpoints/owners/searchOwnerByName'
import { getAllUsers } from '../../../../../services/endpoints/users/getAllUsers'
import { LoadingRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { LoadingRequestTableList, titleColumnsLoadingRequest } from '../components/table-list'

type FormFiltersLoadingRequests = {
  freight_number?: string
  status?: LoadingRequestStatusEnum
  beneficiary_id?: SelectOption
  creator_id?: SelectOption
  initial_date?: string
  final_date?: string
  motorist_id?: SelectOption
  cf_number?: string
  e_frete?: string
}

export function ListLoadingRequests(): JSX.Element {
  const [initialDate, setInitialDate] = useState<string>()
  const [finalDate, setFinalDate] = useState<string>()
  const [filters, setFilters] = useState<FilterLoadingRequestsType>({} as FilterLoadingRequestsType)
  const bg = useColorModeValue('white', 'gray.800')

  const { updateQueryParams, getParams, addPage } = useQueryParamUpdater<{
    freight_number?: string
    status?: LoadingRequestStatusEnum
    initial_date?: string
    final_date?: string
    motorist_label?: string
    motorist_value?: string
    beneficiary_label?: string
    beneficiary_value?: string
    creator_label?: string
    creator_value?: string
    cf_number?: string
    e_frete?: string
  }>()

  const { isOpen: onShowFilter, onToggle: onToggleFilter } = useDisclosure({
    defaultIsOpen: true,
  })

  const { handleSubmit, setValue } = useForm({})

  const handleFilterLoadingRequests: SubmitHandler<FormFiltersLoadingRequests> = data => {
    const formattedFilters: FilterLoadingRequestsType = {
      beneficiary_id: data.beneficiary_id,
      status: data.status,
      freight_number: data.freight_number,
      creator_id: data.creator_id,
      initial_date: data.initial_date,
      final_date: data.final_date,
      motorist_id: data.motorist_id,
      cf_number: data.cf_number,
      e_frete: data.e_frete,
    }

    setFilters(formattedFilters)

    updateQueryParams({
      freight_number: data.freight_number || undefined,
      status: data.status || undefined,
      initial_date: data.initial_date || undefined,
      final_date: data.final_date || undefined,
      motorist_label: data.motorist_id?.label || undefined,
      motorist_value: data.motorist_id?.value || undefined,
      beneficiary_label: data.beneficiary_id?.label || undefined,
      beneficiary_value: data.beneficiary_id?.value || undefined,
      creator_label: data.creator_id?.label || undefined,
      creator_value: data.creator_id?.value || undefined,
      cf_number: data.cf_number || undefined,
      e_frete: data.e_frete || undefined,
    })
  }

  const handleChangePage = (newPage: number) => {
    addPage(newPage)
    setFilters(prev => ({ ...prev, page: newPage }))
  }

  function handleInitialDate(event: FormEvent<HTMLInputElement>): void {
    setInitialDate(event.currentTarget.value)
  }

  function handleFinalDate(event: FormEvent<HTMLInputElement>): void {
    setFinalDate(event.currentTarget.value)
  }

  useEffect(() => {
    const freightNumberQuery = getParams('freight_number')
    const initialDateQuery = getParams('initial_date')
    const finalDateQuery = getParams('final_date')
    const statusQuery = getParams('status')
    const motoristLabelQuery = getParams('motorist_label')
    const motoristValueQuery = getParams('motorist_value')
    const beneficiaryLabelQuery = getParams('beneficiary_label')
    const beneficiaryValueQuery = getParams('beneficiary_value')
    const creatorLabelQuery = getParams('creator_label')
    const creatorValueQuery = getParams('creator_value')
    const cfNumberQuery = getParams('cf_number')
    const efreteQuery = getParams('e_frete')

    if (
      freightNumberQuery ||
      statusQuery ||
      initialDateQuery ||
      finalDateQuery ||
      motoristValueQuery ||
      beneficiaryValueQuery ||
      creatorValueQuery ||
      motoristLabelQuery ||
      beneficiaryLabelQuery ||
      creatorLabelQuery ||
      cfNumberQuery ||
      efreteQuery
    ) {
      setValue('freight_number', freightNumberQuery || '')
      setValue('initial_date', initialDateQuery || '')
      setValue('final_date', finalDateQuery || '')
      setValue('status', statusQuery || '')
      setValue('motorist_id', { label: motoristLabelQuery || '', value: motoristValueQuery || '' })
      setValue('beneficiary_id', { label: beneficiaryLabelQuery || '', value: beneficiaryValueQuery || '' })
      setValue('creator_id', { label: creatorLabelQuery || '', value: creatorValueQuery || '' })
      setValue('cf_number', cfNumberQuery || '')
      setValue('e_frete', efreteQuery || '')

      handleSubmit(handleFilterLoadingRequests)()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout>
      <Flex alignItems="center" justifyContent="space-between" mb={2}>
        <Heading fontSize="2xl">Despesas Extras (OC)</Heading>

        <Box>
          <Button
            as={Link}
            to="/loading-requests/create"
            colorScheme="orange"
            leftIcon={<Icon as={IoPush} />}
          >
            Nova despesa extra
          </Button>
          <Button
            leftIcon={<RiFilter2Line />}
            variant="outline"
            colorScheme="blue"
            ml={2}
            onClick={onToggleFilter}
          >
            Filtros
          </Button>
        </Box>
      </Flex>

      <Filter showFilter={onShowFilter} key={1}>
        <form onSubmit={handleSubmit(handleFilterLoadingRequests)} noValidate>
          <Grid templateColumns="repeat(12, 1fr)" gap="3">
            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Input
                label="Número do frete"
                name="freight_number"
                placeholder="Filtrar pelo número"
                setValue={setValue}
                initialValue={filters?.freight_number}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]}>
              <Select
                label="Status"
                name="status"
                setValue={setValue}
                options={[
                  {
                    label: '',
                    value: '',
                  },
                  {
                    label: 'Aguardando Análise',
                    value: 'awaiting_analysis',
                  },
                  {
                    label: 'CO Reprovado',
                    value: 'rejected',
                  },
                  {
                    label: 'Aguardando CF',
                    value: 'awaiting_cf',
                  },
                  {
                    label: 'Aguardando Pagamento',
                    value: 'awaiting_payment',
                  },
                  {
                    label: 'Pago',
                    value: 'paid',
                  },
                  {
                    label: 'Finalizado',
                    value: 'finished',
                  },
                ]}
                initialValue={filters?.status}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Input
                label="Data inicial"
                name="initial_date"
                type="date"
                initialValue={filters.initial_date || initialDate}
                onBlur={handleInitialDate}
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[8, 3]}>
              <Input
                label="Data final"
                name="final_date"
                type="date"
                initialValue={filters.final_date || finalDate}
                onBlur={handleFinalDate}
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Input
                label="Número CF"
                name="cf_number"
                placeholder="Filtrar pelo número CF"
                setValue={setValue}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <AutocompleteAsync
                label="Motorista"
                name="motorist_id"
                placeholder="Filtrar pelo motorista"
                setValue={setValue}
                loadOptions={searchMotoristByName}
                initialValue={filters?.motorist_id}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <AutocompleteAsync
                label="Favorecido"
                name="beneficiary_id"
                placeholder="Filtrar pelo favorecido"
                setValue={setValue}
                loadOptions={searchOwnerByName}
                initialValue={filters?.beneficiary_id}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <AutocompleteAsync
                label="Responsável"
                name="creator_id"
                placeholder="Filtrar pelo responsável"
                setValue={setValue}
                loadOptions={getAllUsers}
                initialValue={filters?.creator_id}
              />
            </GridItem>

            <GridItem colSpan={[12, 6, 3]} mr={2}>
              <Select
                label="E-Frete"
                name="e_frete"
                setValue={setValue}
                options={[
                  {
                    label: '',
                    value: '',
                  },
                  {
                    label: 'Sim',
                    value: 'true',
                  },
                  {
                    label: 'Não',
                    value: 'false',
                  },
                ]}
                initialValue={filters?.e_frete}
              />
            </GridItem>
          </Grid>

          <Flex
            justifyContent="flex-end"
            mt={2}
            gridGap={2}
            alignItems="center"
            direction={['column', 'row']}
          >
            <Ability module="spotHub" action="show-generate-report-extra-expenses">
              <ReportToExcel
                isLoading={false}
                documentTitle="Relatório de despesas extras"
                columnsTitle={titleColumnsLoadingRequest}
                onGenerateReport={async () => {
                  const report = await listLoadingRequest({
                    ...filters,
                    initial_date: filters.initial_date || initialDate,
                    final_date: filters.final_date || finalDate,
                    unpaged: true,
                  })
                  return report.data.map(oc => {
                    return [
                      oc.freight?.freight_number,
                      oc.formatted_status,
                      oc.type,
                      oc?.motorist?.name,
                      oc.cf_number,
                      oc.total_value_formatted,
                      oc.creator?.name,
                      oc.created_at,
                    ] as string[]
                  })
                }}
              />
            </Ability>

            <Button type="submit" size="md" colorScheme="blue" leftIcon={<Icon as={RiSearchLine} />}>
              Filtrar
            </Button>
          </Flex>
        </form>
      </Filter>

      <Box bg={bg} p={4} borderRadius="8" shadow="md">
        <LoadingRequestTableList
          handleChangePage={handleChangePage}
          filters={filters}
          initialDate={initialDate}
          finalDate={finalDate}
          hasPagination
        />
      </Box>
    </Layout>
  )
}
