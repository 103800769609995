import { addMinutes, format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { ResponseListApiType } from '../../types'
import { calculateTripDuration } from '../../utils/calculateTripDuration'
import parseCurrency from '../../utils/parseCurrency'
import {
  FiltersFreightsParams,
  FormattedAllFreights,
  getStatus,
  getStatusTabs,
} from '../freights/getFreights'

export interface FormattedAllFreightsTolls extends FormattedAllFreights {
  formatted_has_tag?: string
  formatted_tag_provider?: string
  formatted_client_name?: string
  cargo_delivery_estimate?: string
  formatted_origin_name?: string
  formatted_destination_name?: string
  formatted_toll_value?: string
  formatted_value_vpo?: string
  formatted_value_vpo_was_paid?: string
}

export type GetFreightsTollsResponse = {
  total: number
  data: FormattedAllFreightsTolls[]
}

export const calcValueVpo = (freight: Partial<FormattedAllFreightsTolls>): number | undefined => {
  const value_vpo =
    freight.cte?.mdfes &&
    freight.cte?.mdfes
      .filter(
        mdfe => mdfe.mdfe_events && mdfe.mdfe_events.every(mdfe_event => mdfe_event.type !== 'cancelamento'),
      )
      .reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.toll_value || 0), 0)
  return value_vpo
}

const formatValueVpo = (freight: Partial<FormattedAllFreightsTolls>): string => {
  const value_vpo = calcValueVpo(freight)
  return value_vpo ? parseCurrency(value_vpo) : 'Não'
}

export async function fetchAllFreightsTolls({
  status,
  page = 1,
  per_page = 10,
  ...params
}: FiltersFreightsParams): Promise<GetFreightsTollsResponse> {
  let parsedStatus: string | string[] | undefined

  if (status && typeof status === 'string') {
    parsedStatus = getStatusTabs(status)
  }

  const { data } = await apiServer.get<ResponseListApiType<FormattedAllFreightsTolls[]>>(
    'get-freights-tolls',
    {
      params: {
        status: parsedStatus,
        page,
        per_page,
        ...params,
      },
    },
  )

  const { total } = data.data

  const freights = data.data.data.map(freight => {
    const createdAt = new Date(freight.created_at)

    let link_motorist_at_hour
    let collect_cargo_at_hour
    let deliver_cargo_at_hour
    let formatted_link_motorist_at
    let formatted_received_at
    let formatted_finished_at
    let collect_cargo_at_pt_br
    let input_collect_cargo_at
    let formatted_delivered_cargo_at
    let formatted_denied_at

    if (freight.finished_at) {
      const finishedAt = new Date(String(freight.finished_at))
      formatted_finished_at = format(finishedAt, 'dd/MM/yyyy HH:mm')
    }

    if (freight.denied_at) {
      const disabledAt = new Date(String(freight.denied_at))
      formatted_denied_at = format(disabledAt, 'dd/MM/yyyy HH:mm')
    }

    if (freight.link_motorist_at) {
      const linkMotoristAt = new Date(freight.link_motorist_at)
      formatted_link_motorist_at = format(linkMotoristAt, "yyyy-MM-dd'T'HH:mm:ss")
      link_motorist_at_hour = format(linkMotoristAt, 'HH:mm:ss')
    }

    if (freight.collect_cargo_at) {
      const collectItemAt = new Date(freight.collect_cargo_at)
      input_collect_cargo_at = format(collectItemAt, "yyyy-MM-dd'T'HH:mm:ss")
      collect_cargo_at_pt_br = format(collectItemAt, 'dd/MM/yy HH:mm')
      collect_cargo_at_hour = format(collectItemAt, 'HH:mm:ss')
    }

    if (freight.delivered_cargo_at) {
      const deliverItemAt = new Date(freight.delivered_cargo_at)
      formatted_delivered_cargo_at = format(deliverItemAt, "yyyy-MM-dd'T'HH:mm:ss")
      deliver_cargo_at_hour = format(deliverItemAt, 'HH:mm:ss')
    }

    if (freight.received_at) {
      const receivedItemAt = new Date(freight.received_at)
      formatted_received_at = format(receivedItemAt, "yyyy-MM-dd'T'HH:mm:ss")
    }

    const value_vpo =
      freight.cte?.mdfes &&
      freight.cte?.mdfes
        .filter(
          mdfe =>
            mdfe.mdfe_events && mdfe.mdfe_events.every(mdfe_event => mdfe_event.type !== 'cancelamento'),
        )
        .reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.toll_value || 0), 0)

    let deliveryDate
    let tripDuration
    if (freight.distance) {
      const date = new Date()
      date.setHours(12)
      tripDuration = calculateTripDuration(freight.distance, 60, date)
      if (freight.collect_cargo_at) {
        deliveryDate = new Date(freight.collect_cargo_at)
        tripDuration = calculateTripDuration(freight.distance, 60, deliveryDate)
        deliveryDate = addMinutes(deliveryDate, tripDuration.totalTripDurationInMinutes)
      }
    }

    return {
      ...freight,
      formatted_tag_provider:
        freight.vehicle?.toll_tags && freight.vehicle.toll_tags.length > 0
          ? `${freight.vehicle.toll_tags.map(tag => tag.brand).join(' | ')} `
          : '',
      formatted_has_tag: freight.vehicle?.toll_tags && freight.vehicle.toll_tags.length > 0 ? 'Sim' : 'Não',
      formatted_client_name: freight.client?.nickname?.split(' ')[0],
      cargo_delivery_estimate: freight.delivered_cargo_at
        ? format(new Date(freight.delivered_cargo_at), 'dd/MM/yy')
        : tripDuration?.relativeTripDuration,
      formatted_origin_name: `${freight.origin?.name} - ${freight.origin?.state?.uf}`,
      formatted_destination_name: `${freight.destination?.name} - ${freight.destination?.state?.uf}`,
      formatted_toll_value:
        freight.toll_value && Number(freight.toll_value) > 0 ? parseCurrency(freight.toll_value) : 'N/A',
      formatted_value_vpo: formatValueVpo(freight),
      formatted_value_vpo_was_paid: value_vpo ? 'Sim' : 'Não',
      formatted_status: getStatus(freight.status),
      origin_city: `${freight.origin.name} - ${freight.origin.state.uf}`,
      destination_city: `${freight.destination.name} - ${freight.destination.state.uf}`,
      formatted_suggested_price: parseCurrency(freight.suggested_price),
      formatted_agreed_price: parseCurrency(freight.agreed_price),
      formatted_vehicle: `${freight.vehicle?.vehicle_category?.name} / ${freight.vehicle?.vehicle_body?.name}`,
      created_at: format(createdAt, 'dd/MM'),
      formatted_denied_at,
      created_at_hour: format(createdAt, 'HH:mm'),
      link_motorist_at_hour,
      collect_cargo_at_hour,
      deliver_cargo_at_hour,
      formatted_link_motorist_at,
      input_collect_cargo_at,
      collect_cargo_at_pt_br,
      formatted_delivered_cargo_at,
      formatted_received_at,
      formatted_finished_at,
    }
  })
  return { total, data: freights }
}

export function useGetFreightsTolls(params: FiltersFreightsParams): UseQueryResult<GetFreightsTollsResponse> {
  return useQuery(['get-freights-tolls', params], () => fetchAllFreightsTolls(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
    keepPreviousData: true,
  })
}
