import { Alert, AlertIcon, AlertTitle, Button, Flex, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { RiCaravanLine } from 'react-icons/ri'
import { toastError } from '../../../../config/error/toastError'
import { queryClient } from '../../../../config/react-query'
import { apiServer } from '../../../../services/api'
import { useIsBlockEntity } from '../../../../services/endpoints/blocked-entities/isBlockEntity'
import { useCreateAttachmentToTrailer } from '../../../../services/endpoints/trailers/trailer-attachments/createTrailerAttachment'
import { useUpdateAttachmentToTrailer } from '../../../../services/endpoints/trailers/trailer-attachments/updateTrailerAttachment'
import { useAttachTrailer } from '../../../../services/endpoints/vehicles/attachTrailerToVehicle'
import { useDetachTrailer } from '../../../../services/endpoints/vehicles/detachTrailerFromVehicle'
import { ResponseApiType, ResponseOcrCrlv } from '../../../../services/types'
import { TrailerType } from '../../../../services/types/TrailerType'
import { capitalizeWord } from '../../../../services/utils/capitalizeWord'
import { alterColorsGenderDatamex } from '../../../../services/utils/vehicleColorsDatamex'
import { useAppDispatch, useAppSelector } from '../../../../store'
import { setTrailer } from '../../../../store/slices/freightPendenciesSlice'
import { setCrlvTrailerFile, setCrlvTrailerImg, setIsNewImage } from '../../../../store/slices/ocrCrlvSlice'
import { DrawerWrapper } from '../components/DrawerWrapper'
import { VehicleSearch } from '../components/VehicleSearch'
import {
  TrailerForm,
  TrailerFormValues,
  trailerInitialValues,
  TrailerSchema,
} from '../../../../components/forms/trailer'
import { createCityInitialValue, createTrailerInitialValues } from '../utils'
import UploadCRLVModal from './UploadCRLV.modal'

type TrailerModalFormProps = {
  isOpen: boolean
  onClose: () => void
  trailerIndex?: number
  // vehicle?: VehicleResponseFormData
}

const TrailerModalForm = ({ isOpen, onClose, trailerIndex = 0 }: TrailerModalFormProps): JSX.Element => {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const { trailers, vehicle } = useAppSelector(state => state.freightPendenciesSlice)
  const [trailerLocalState, setTrailerLocalState] = useState<TrailerType | null>(null)
  const { crlvTrailerFile, isNewImage } = useAppSelector(state => state.ocrCrlvSlice)

  const { mutateAsync: createAttachmentTrailer } = useCreateAttachmentToTrailer({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do reboque criado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
      dispatch(setIsNewImage(false))
    },
  })
  const { mutateAsync: updateAttachmentTrailer } = useUpdateAttachmentToTrailer({
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Anexo do CRLV do reboque atualizado com sucesso!',
        position: 'top-right',
        duration: 1000 * 8,
        isClosable: true,
      })
      dispatch(setIsNewImage(false))
    },
  })

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    reset,
    control,
    setValue,
  } = useForm<TrailerFormValues>({
    mode: 'onChange',
    defaultValues: trailerInitialValues,
    resolver: yupResolver(TrailerSchema),
  })

  // Get data for blocked entities
  const { data: blockedEntity } = useIsBlockEntity({
    trailer_id: trailerLocalState?.id,
  })

  /**
   * Exibe uma mensagem de sucesso.
   */
  const showSuccessToast = (message: string) => {
    toast({
      status: 'success',
      title: message,
      position: 'top-right',
      duration: 8000,
      isClosable: true,
    })
  }

  const unlinkTrailerFromVehicleMutate = useDetachTrailer({
    onSuccess: () => {
      reset(trailerInitialValues)
      setTrailerLocalState(null)
      dispatch(setTrailer([{ id: 'placeholder' } as TrailerType]))
      queryClient.invalidateQueries('vehicle')
      queryClient.invalidateQueries('check-pendencies')
    },
  })
  const linkTrailerToVehicleMutate = useAttachTrailer({
    onSuccess: () => {
      queryClient.invalidateQueries('vehicle')
      queryClient.invalidateQueries('check-pendencies')
      showSuccessToast('Veículo vinculado ao motorista com sucesso!')
    },
  })

  const handleUnlinkTrailerFromVehicle = () => {
    if (trailerLocalState?.id && vehicle) {
      unlinkTrailerFromVehicleMutate.mutate({
        trailer_id: trailerLocalState.id,
        vehicle_id: vehicle?.id,
      })
    }
  }

  const handleLinkTrailerToVehicle = async (trailer_id: string): Promise<void> => {
    if (trailer_id && vehicle) {
      const oldTrailerId = vehicle?.trailers[0]?.id

      if (oldTrailerId === trailer_id) return

      if (oldTrailerId) {
        await unlinkTrailerFromVehicleMutate.mutateAsync({
          vehicle_id: vehicle?.id,
          trailer_id: oldTrailerId,
        })
      }
      await linkTrailerToVehicleMutate.mutateAsync({
        vehicle_id: vehicle?.id,
        trailer_id,
      })
    }
  }
  const onSubmit: SubmitHandler<TrailerFormValues> = async data => {
    if (blockedEntity) {
      toast({
        status: 'error',
        title: 'Edição bloqueada',
        description: 'Edição bloqueada. Solicite correção da análise de risco.',
        position: 'top-right',
        duration: 8000,
      })
      return
    }

    try {
      if (trailerLocalState?.id) {
        if (trailerLocalState.id === 'placeholder') return

        const { data: result } = await apiServer.put<ResponseApiType<TrailerType>>(
          `/update-trailer/${trailerLocalState.id}`,
          {
            ...data,
            license_uf: data.license_uf.toUpperCase(),
            city_id: data.city_id.value,
            brand: data.brand.toUpperCase(),
            chassi: data.chassi.toUpperCase(),
            license_plate: data.license_plate.toUpperCase(),
          },
        )
        showSuccessToast('trailer atualizado com sucesso!')
        await handleLinkTrailerToVehicle(trailerLocalState.id)

        if (result.statusCode === 200 && crlvTrailerFile?.name) {
          const attachment = trailers?.[trailerIndex].attachments?.find(item => item.type === 'crlv')
          if (attachment?.id) {
            await updateAttachmentTrailer({
              id: attachment.id,
              attachment_file: crlvTrailerFile,
              trailer_id: result.data.id,
            })
          } else {
            await createAttachmentTrailer({
              attachment_file: crlvTrailerFile,
              trailer_id: result.data.id,
              name: 'Crlv do reboque',
              type: 'crlv',
            })
          }
        }
      } else {
        const response = await apiServer.post<ResponseApiType<TrailerType>>(`/create-trailer`, {
          ...data,
          license_uf: data.license_uf.toUpperCase(),
          city_id: data.city_id.value,
          brand: data.brand.toUpperCase(),
          chassi: data.chassi.toUpperCase(),
          license_plate: data.license_plate.toUpperCase(),
        })
        const { data: newTrailer } = response.data

        showSuccessToast('trailer criado com sucesso!')
        await handleLinkTrailerToVehicle(newTrailer.id)

        if (crlvTrailerFile && newTrailer.id) {
          await createAttachmentTrailer({
            attachment_file: crlvTrailerFile,
            trailer_id: newTrailer.id,
            name: 'Crlv do reboque',
            type: 'crlv',
          })
        }
      }
      onClose()
      dispatch(setIsNewImage(false))
      dispatch(setCrlvTrailerFile({} as File))
    } catch (error) {
      toastError({ toast, error })
    } finally {
      reset(data)
      queryClient.invalidateQueries('vehicle')
      queryClient.invalidateQueries('check-pendencies')
    }
  }

  const handleClose = () => {
    if (isDirty) {
      toast({
        title: 'Alterações não salvas',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })

      return
    }

    if (isNewImage && crlvTrailerFile?.name) {
      toast({
        title: 'Alterações não salvas, nova CRLV',
        description: 'Por favor salve as alterações antes de fechar ou descarte as alterações.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      return
    }

    onClose()
  }

  const handleGetValuesByCRLV = (CRLV: Partial<ResponseOcrCrlv>) => {
    if (CRLV) {
      reset({
        license_plate: CRLV.licensePlate,
        antt: CRLV.licensePlate,
        release_year: CRLV.manufactureYear,
        model_year: CRLV.modelYear,
        brand: CRLV.brandName,
        axes: CRLV.axes ? Number(CRLV.axes) : undefined,
        capacity_kg: CRLV.capacity ? Number(CRLV.capacity) * 1000 : undefined,
        chassi: CRLV.chassis,
        color: CRLV.predominantColor,
        model: CRLV.brandModelVersion,
        renavam: CRLV.renavam,
      })
    }
  }

  const handleCreateInitialValues = useCallback(
    (trailer: TrailerType) => {
      const { attachments, city } = trailer

      const attach = attachments?.find(item => item.type === 'crlv')
      if (attach) {
        dispatch(setCrlvTrailerImg(attach?.attachment_file_url || 'placeholder.png'))
      } else {
        dispatch(setCrlvTrailerImg(''))
      }

      reset({
        license_plate: trailer.license_plate,
        renavam: trailer.renavam,
        brand: trailer.brand,
        color: capitalizeWord(alterColorsGenderDatamex(trailer.color)) || '',
        model: trailer.model,
        model_year: trailer.model_year?.toString() || '',
        chassi: trailer.chassi,
        release_year: trailer.release_year?.toString() || '',
        antt: trailer.license_plate,
        license_uf: trailer.license_uf,
        vehicle_body_id: trailer?.vehicle_body?.id,
        axes: trailer?.axes || undefined,
        capacity_kg: trailer?.capacity_kg || undefined,
        capacity_m3: trailer?.capacity_m3 || undefined,
        capacity_tara: trailer?.capacity_tara || undefined,
        has_insurance: !!trailer?.has_insurance,
        has_tracker: !!trailer?.has_tracker,
        ...(!!city && {
          city_id: createCityInitialValue(city),
        }),
      })
    },
    [reset, dispatch],
  )

  useEffect(() => {
    if (trailers && trailers.length > 0) {
      const trailer = trailers[trailerIndex]
      if (trailer.id === 'placeholder') return
      setTrailerLocalState(trailer)
      handleCreateInitialValues(trailer)
    } else {
      setTrailerLocalState(null)
    }
  }, [trailers, handleCreateInitialValues, trailerIndex])

  // watchers

  return (
    <>
      <DrawerWrapper
        isOpen={isOpen}
        onClose={handleClose}
        onForceClose={() => {
          onClose()
          dispatch(setIsNewImage(false))
          dispatch(setCrlvTrailerFile({} as File))
        }}
        title="Cadastrar reboque"
        onSave={() => handleSubmit(onSubmit)()}
        isSubmitting={isSubmitting}
        showForceClose={isDirty}
      >
        <VehicleSearch
          type="trailer"
          onTrailerFound={trailer => {
            setTrailerLocalState(trailer || null)
            reset(createTrailerInitialValues(trailer))
          }}
        />

        <UploadCRLVModal
          type="trailer"
          onSaveInformations={handleGetValuesByCRLV}
          onVehicleFound={data => {
            if (data) {
              setTrailerLocalState(data)
              handleCreateInitialValues(data)
            }
          }}
        />
        <Flex justifyContent="flex-end" pt="3" flexDirection={{ base: 'column', md: 'row' }}>
          {trailerLocalState && trailers?.find(t => t.id === trailerLocalState?.id) && (
            <Button
              size="sm"
              leftIcon={<RiCaravanLine />}
              colorScheme="red"
              isLoading={unlinkTrailerFromVehicleMutate.isLoading}
              onClick={handleUnlinkTrailerFromVehicle}
            >
              Desvincular reboque do veículo
            </Button>
          )}
        </Flex>
        {blockedEntity && (
          <Alert variant="subtle" status="error" borderRadius="md">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="sm">
              Edição bloqueada. Solicite correção da análise de risco
            </AlertTitle>
          </Alert>
        )}
        <TrailerForm
          control={control}
          setValue={setValue}
          disabled={blockedEntity}
          onVehicleFound={data => {
            if (data) {
              setTrailerLocalState(data)
              handleCreateInitialValues(data)
            }
          }}
        />
      </DrawerWrapper>
    </>
  )
}

export default TrailerModalForm
