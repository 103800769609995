import { Box, Icon, Text } from '@chakra-ui/react'
import { Marker } from 'react-leaflet'
import Leaflet from 'leaflet'
import ReactDomServer from 'react-dom/server'
import { IoLocationOutline } from 'react-icons/io5'
import { TbBusStop } from 'react-icons/tb'
import { BiSolidFlagCheckered } from 'react-icons/bi'
import { CityType } from '../../../../../../services/types'
import { RoutePlannerCity } from '../../reducer'

type MapMarkerProps = {
  type: 'origin' | 'destination' | 'step'
  city?: CityType
  stepCity?: RoutePlannerCity
}

export function MapMarker({ type, city, stepCity }: MapMarkerProps): JSX.Element {
  const mapMarker = Leaflet.divIcon({
    html: ReactDomServer.renderToString(
      <div
        style={{
          position: 'relative',
          backgroundColor: '#27272a',
          display: 'inline-block',
          padding: '8px',
          borderRadius: '4px',
          color: 'white',
          marginTop: '15px',
          marginLeft: '-16px',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: '-6px',
            left: '16px',
            width: '0',
            height: '0',
            borderLeft: '6px solid transparent',
            borderRight: '6px solid transparent',
            borderBottom: '6px solid #27272a',
          }}
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            as={
              type === 'origin'
                ? IoLocationOutline
                : type === 'destination'
                ? BiSolidFlagCheckered
                : TbBusStop
            }
            bg="#3f3f46"
            p={5}
            fontSize={30}
            borderRadius="4px"
          />
          <Box ml="5px">
            <Text
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold',
                fontSize: '10px',
              }}
            >
              {type === 'origin' ? 'Origem' : type === 'destination' ? 'Destino' : 'Parada'}:{' '}
              {city ? city.name : stepCity ? stepCity.cityName : 'Não informado'} -{' '}
              {city ? city.state.uf : stepCity ? stepCity.uf : ''}
            </Text>
            <Text
              style={{
                opacity: 0.6,
                whiteSpace: 'nowrap',
                fontSize: '10px',
              }}
            >
              {city ? city.lat : stepCity ? stepCity.lat : 'Não informado'},{' '}
              {city ? city.lng : stepCity ? stepCity.lng : 'Não informado'}
            </Text>
          </Box>
        </div>
      </div>,
    ),
    className: 'custom-map-icon',
  })

  return (
    <Marker
      icon={mapMarker}
      position={[Number(city ? city.lat : stepCity?.lat), Number(city ? city.lng : stepCity?.lng)]}
    />
  )
}
