import {
  Box,
  Flex,
  GridItemProps,
  Heading,
  Text,
  useColorModeValue,
  useMediaQuery,
  GridItem,
} from '@chakra-ui/react'
import { IMotoristFeedback } from '../../../services/endpoints/motorists/reportsFeedbacksMotorist'

type MetricCardProps = {
  label: string
  feedbacks: number
  freightsByPeriod: number
  filters?: { initial_date: string; final_date: string }
}
type MetricCardMediaProps = {
  label: string
  feedbacksByPeriod: IMotoristFeedback
  feedbacksTotal: number
  filters?: { initial_date: string; final_date: string }
}

type MetricCardSmallProps = {
  label: string
  value?: string
  variant?: 'positive' | 'negative' | 'neutral'
}

export const ResponsiveGridItem = ({ children, ...props }: GridItemProps): JSX.Element => {
  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  return <GridItem {...(isLargerThan800 ? props : { colSpan: 17 })}>{children}</GridItem>
}

const MetricCardSmall = ({ label, value, variant = 'neutral' }: MetricCardSmallProps): JSX.Element => {
  return (
    <Box aria-label={`${label} ${value} ${variant}`}>
      <Text fontSize="sm" color="gray.500">
        {label}
      </Text>
      <Text fontSize="sm" fontWeight="bold" whiteSpace="pre-wrap">
        {value}
      </Text>
    </Box>
  )
}

export const CardPercent = ({
  label,
  feedbacks,
  freightsByPeriod,
  filters,
}: MetricCardProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')

  const percent =
    freightsByPeriod === 0 ? '0%' : `${((Number(feedbacks) / Number(freightsByPeriod)) * 100).toFixed(1)}%`

  return (
    <Flex
      h="full"
      p="4"
      bg={bg}
      borderRadius="8"
      shadow="md"
      gridGap="8"
      border="2px solid gray"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex flexDir="column" borderLeft="1px" pl="1" borderColor="blackAlpha.300" mr={2}>
        <Heading size="sm" fontWeight="medium" my="2">
          {label}:
        </Heading>
        {filters && (
          <Box fontSize="sm" color="gray.400" textAlign="justify">
            {filters.initial_date} - {filters.final_date}
          </Box>
        )}

        <Flex flexDir="column" flex={1} justifyContent="flex-end">
          <Flex alignItems="flex-end">
            <Heading size="2xl">{feedbacks}</Heading>
          </Flex>
        </Flex>
      </Flex>

      <Flex spacing="2" flexDir="column">
        <MetricCardSmall label="Fretes finalizados" value={freightsByPeriod.toString()} />
        <MetricCardSmall label="Percentual" value={percent} />
      </Flex>
    </Flex>
  )
}

export const CardMedia = ({
  label,
  feedbacksByPeriod,
  feedbacksTotal,
  filters,
}: MetricCardMediaProps): JSX.Element => {
  const bg = useColorModeValue('white', 'gray.800')

  const sumFeedbacksByPeriod = feedbacksByPeriod?.data?.data?.reduce((acc = 0, feedback) => {
    return acc + Number(feedback.recommendation_score)
  }, 0)

  const media =
    feedbacksByPeriod?.data?.total === 0
      ? '0'
      : `${(Number(sumFeedbacksByPeriod) / Number(feedbacksByPeriod?.data?.total)).toFixed(1)}`

  return (
    <Flex
      h="full"
      p="4"
      bg={bg}
      borderRadius="8"
      shadow="md"
      gridGap="8"
      border="2px solid gray"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex flexDir="column" borderLeft="1px" pl="1" borderColor="blackAlpha.300" mr={2}>
        <Heading size="sm" fontWeight="medium" my="2">
          {label}:
        </Heading>
        {filters && (
          <Box fontSize="sm" color="gray.400" textAlign="justify">
            {filters.initial_date} - {filters.final_date}
          </Box>
        )}

        <Flex flexDir="column" flex={1} justifyContent="flex-end">
          <Flex alignItems="flex-end">
            <Heading size="2xl">{media}</Heading>
          </Flex>
        </Flex>
      </Flex>

      <Flex spacing="2" flexDir="column">
        <MetricCardSmall label="Avaliações no período" value={feedbacksByPeriod.data.total.toString()} />
        <MetricCardSmall label="Avaliações Totais" value={feedbacksTotal.toString()} />
      </Flex>
    </Flex>
  )
}
