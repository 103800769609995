/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMutation, UseMutationOptions } from 'react-query'
import { TagType } from '../../../pages/pricing/route-planner/show/reducer'
import { apiServer } from '../../api'

export interface IPostRoutePlannerInput {
  optimized_route: boolean
  optimized_route_destination: string
  calculate_return: boolean
  alternative_routes: string
  avoid_locations: boolean
  avoid_locations_key: string
  type_route: string
  vehicle_category: string
  axes_number: string
  cargo_type: string
  origin: any
  destination: any
  steps: any[]
  freight_category?: string
}

export interface IPostRoutePlannerApiInput {
  locations: any[]
  alternative_routes: string
  avoid_locations: boolean
  avoid_locations_key: string
  axes_number: string
  calculate_return: boolean
  cargo_type: string
  destination: any
  origin: any
  optimized_route: false
  optimized_route_destination: string
  steps: any[]
  type_route: string
  vehicle_category: string
}

export type QualpRoute = {
  distance: string
  distance_unpaved: string
  duration: string
  start_address: string
  end_address: string
  start_coordinate: string
  end_coordinate: string
  rotograma?: any
  tolls?: any[]
  freight_table?: any
  scales?: any[]
  polyline: string
  simplified_polyline: string
  tags: TagType[]
}

export type QualpDataResponseType = {
  distance: string
  unpaved_distance: {
    km: string
    percentage: string
  }
  duration: string
  start_address: string
  end_address: string
  start_coordinate: string
  end_coordinate: string
  tolls: Array<{
    id_api: string
    name: string
    tariff: Record<number, number>
    special_toll: boolean
  }>
  scales: Array<{
    id: number
    lat: number
    lng: number
    name: string
  }>
  qualp_link: string
  locations: string[]
  transaction_id: number
  selected_router: string
  calculate_return: boolean
  optimize_route: boolean
  routes: QualpRoute[]
}

type IPostRoutePlannerResponse = {
  message: string
  data: QualpDataResponseType
}

async function postRoutePlanner(data: IPostRoutePlannerInput): Promise<QualpDataResponseType> {
  const steps = data.steps.map(step => ({
    cityName: step.city,
    lat: step.lat,
    lng: step.lng,
  }))
  const locations = [data.origin, ...steps, data.destination]

  Object.assign(data, { locations })
  const { data: response } = await apiServer.post<IPostRoutePlannerResponse>('/plain-route', data)
  return response.data
}

export function usePostRoutePlannerQualp(
  options?: UseMutationOptions<QualpDataResponseType, unknown, IPostRoutePlannerInput, unknown>,
) {
  return useMutation(async (data: IPostRoutePlannerInput) => postRoutePlanner(data), options)
}
