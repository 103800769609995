import { Th, Tr } from '@chakra-ui/react'

export const freightsTollsTitleColumns = [
  'Frete',
  'Tem tag?',
  'Cliente',
  'Coleta',
  'P. Entrega',
  'Origem',
  'Destino',
  'Status',
  'Pedágio',
  'VPO',
  'VPO pago',
]
export function TrComponent(): JSX.Element {
  return (
    <Tr alignContent="center">
      {freightsTollsTitleColumns.map(title => (
        <Th key={title} whiteSpace="nowrap">
          {title}
        </Th>
      ))}
    </Tr>
  )
}
