import { AxiosResponse } from 'axios'
import { useMutation, UseMutationOptions, UseMutationResult } from 'react-query'
import { queryClient } from '../../../config/react-query'
import { apiServer } from '../../api'
import { Authorization, ResponseApiType } from '../../types'

type Input = { label: string; value: string }
type Output = Authorization[]

async function createRole(input: Input) {
  const output = await apiServer.post<ResponseApiType<Output>>('/authorization/role', { ...input })
  if (output.data.statusCode === 200) {
    queryClient.invalidateQueries('getActions')
    queryClient.invalidateQueries('getRolesOptions')
    queryClient.invalidateQueries('getAllPermissions')
    queryClient.invalidateQueries('getPermissionsByRoles')
    queryClient.invalidateQueries('updatePermissionsByRolesStorage')
    queryClient.invalidateQueries('updatePermissionsByUsersOnStorage')
  }
  return output
}

export function useCreateRole(
  options?: UseMutationOptions<AxiosResponse, unknown, Input, unknown>,
): UseMutationResult<AxiosResponse<any>, unknown, Input, unknown> {
  return useMutation(async (input: Input) => createRole(input), options)
}
