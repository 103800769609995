import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'
import { SelectOption } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { AdvanceRequestTypeFormatted } from '../../../../../services/endpoints/freights/getAdvanceRequest'
import { usePutAdvanceRequest } from '../../../../../services/endpoints/freights/putAdvanceRequest'
import { AdvanceRequestStatusEnum } from '../../../../../services/types/EnumTypes'
import { CreateOrUpdateAdvanceRequestForm } from '../forms/CreateOrUpdateAdvanceRequestForm'

type UpdateAdvanceRequestModalProps = {
  isOpen: boolean
  onClose: () => void
  initial_value: AdvanceRequestTypeFormatted
}

type RequestParams = {
  id: string
}

type UpdateAdvanceRequestType = {
  reason?: string
  account_digit?: string
  account?: string
  agency?: string
  account_type?: string
  bank?: SelectOption
  pix?: string
  e_frete?: string
  payment_type: string
  observation?: string
}

const UpdateAdvanceRequestSchema = yup.object().shape({
  reason: yup.string().required('Campo obrigatório'),
  payment_type: yup.string().when('e_frete', {
    is: 'no',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  pix: yup.string().when('payment_type', {
    is: 'pix',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account_type: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  bank: yup
    .object()
    .shape({})
    .when('payment_type', {
      is: 'account',
      then: yup.object().shape({}).required('Campo obrigatório'),
      otherwise: yup.object().shape({}),
    }),
  agency: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  account: yup.string().when('payment_type', {
    is: 'account',
    then: yup.string().required('Campo obrigatório'),
    otherwise: yup.string(),
  }),
  e_frete: yup.string().required('Campo obrigatório'),
  account_digit: yup.string(),
  observation: yup.string(),
})

export function UpdateAdvanceRequestModal({
  isOpen,
  onClose,
  initial_value,
}: UpdateAdvanceRequestModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue, formState } = useForm({
    resolver: yupResolver(UpdateAdvanceRequestSchema),
  })

  const { errors } = formState

  const { id } = useParams<RequestParams>()

  const { mutateAsync: updateAdvanceRequest, isLoading: isLoadingUpdateAdvanceRequest } =
    usePutAdvanceRequest({
      onSuccess: () => {
        toast({
          title: 'Solicitação de adiantamento atualizada com sucesso!',
          status: 'success',
          position: 'top-right',
          isClosable: true,
          duration: 4000,
        })

        onClose()
      },
      onError: error => {
        toastError({ toast, error })
      },
    })

  const handleUpdateAdvanceRequest: SubmitHandler<UpdateAdvanceRequestType> = async data => {
    const updateAdvanceRequestData = {
      status: AdvanceRequestStatusEnum.AWAITING_ANALYSIS,
      reason: data.reason,
      pix: data.payment_type === 'pix' ? data.pix : null,
      account_type: data.payment_type === 'account' ? data.account_type : null,
      bank: data.payment_type === 'account' ? data.bank?.label : null,
      agency: data.payment_type === 'account' ? data.agency : null,
      account: data.payment_type === 'account' ? data.account : null,
      account_digit: data.payment_type === 'account' ? data.account_digit : null,
      e_frete: data.e_frete === 'yes',
      observation: data.observation,
    }

    if (initial_value && initial_value.is_approved) {
      Object.assign(updateAdvanceRequestData, {
        status: AdvanceRequestStatusEnum.AWAITING_PAYMENT,
      })
    }

    await updateAdvanceRequest({
      id,
      data: updateAdvanceRequestData,
    })
  }

  return (
    <Modal
      size="5xl"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Atualizar solicitação de adiantamento</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(handleUpdateAdvanceRequest)} noValidate>
          <ModalBody>
            <CreateOrUpdateAdvanceRequestForm
              setValue={setValue}
              errors={errors}
              initial_value={initial_value}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" colorScheme="red" mr={3} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              type="submit"
              colorScheme="orange"
              isLoading={isLoadingUpdateAdvanceRequest}
              isDisabled={isLoadingUpdateAdvanceRequest}
            >
              Atualizar
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  )
}
