import { differenceInYears, format } from 'date-fns'
import { useQuery, UseQueryResult } from 'react-query'
import { SelectOption } from '../../../components/form'
import { getNextStatusQuotation } from '../../../pages/quotation/modals/buttons-actions-status/NextStatusQuotationButton'
import { getPreviousStatusQuotation } from '../../../pages/quotation/modals/buttons-actions-status/PreviousStatusQuotationButton'
import { apiServer } from '../../api'
import { ResponseApiType } from '../../types'
import RiskAnalysisType from '../../types/RiskAnalysisType'
import parseCurrency from '../../utils/parseCurrency'
import { getNextStatus, getPriceType, getStatus, getType } from '../freights/getFreights'
import { getGender, getMaritalStatus } from '../motorists/getMotorist'
import { colorStatusRiskAnalysis, formatStatusRiskAnalysis } from './getAllRiskAnalysis'

export interface RiskAnalysisOneFormatted extends RiskAnalysisType {
  color_status: string
}

export const riskAnalysisStatusOptions: SelectOption[] = [
  { label: '', value: '' },
  { label: 'Aguardando', value: 'awaiting' },
  { label: 'Aguardando GR', value: 'awaiting_gr' },
  { label: 'Reprovado', value: 'disapproved' },
  { label: 'A Corrigir', value: 'to_correct' },
  // { label: 'A Checar', value: 'to_check' },
  { label: 'A Aprovar', value: 'awaiting_approval' },
  { label: 'Aprovado', value: 'approved' },
  { label: 'Cancelado', value: 'canceled' },
]

export async function fetchRiskAnalysis(id: string): Promise<RiskAnalysisOneFormatted> {
  const { data: res } = await apiServer.get<ResponseApiType<RiskAnalysisOneFormatted>>(
    `/find-by-id-risk-analysis/${id}`,
  )

  const { freight } = res.data
  const createdAt = new Date(freight.created_at)
  const receivedAt = freight.received_at ? new Date(freight.received_at) : new Date()
  const linkMotoristAt = freight.link_motorist_at ? new Date(freight.link_motorist_at) : undefined
  const collectItemAt = freight.collect_cargo_at ? new Date(freight.collect_cargo_at) : undefined
  const deliverItemAt = freight.delivered_cargo_at ? new Date(freight.delivered_cargo_at) : undefined

  return {
    ...res.data,
    motorist: {
      ...res.data.motorist,
      age: res.data.motorist.birth && differenceInYears(new Date(), res.data.motorist.birth),
      formatted_gender: getGender(res.data.motorist.gender),
      formatted_marital_status: getMaritalStatus(res.data.motorist?.marital_status),
      formatted_rg_dispatch_date: res.data.motorist.rg_dispatch_date
        ? format(new Date(res.data.motorist.rg_dispatch_date), 'dd/MM/yyyy')
        : '',
    },
    freight: {
      ...freight,
      formatted_type: getType(freight.type),
      formatted_status: getStatus(freight.status),
      formatted_received_at: format(receivedAt, "yyyy-MM-dd'T'HH:mm:ss"),
      formatted_link_motorist_at: linkMotoristAt
        ? format(linkMotoristAt, "yyyy-MM-dd'T'HH:mm:ss")
        : undefined,
      input_collect_cargo_at: collectItemAt ? format(collectItemAt, "yyyy-MM-dd'T'HH:mm:ss") : undefined,
      formatted_delivered_cargo_at: deliverItemAt
        ? format(deliverItemAt, "yyyy-MM-dd'T'HH:mm:ss")
        : undefined,
      next_status: getNextStatus(freight.status),
      next_status_quotation: getNextStatusQuotation(freight.status),
      previous_status_quotation: getPreviousStatusQuotation(freight.status),
      origin_city: `${freight.origin?.name} - ${freight.origin.state.uf}`,
      destination_city: `${freight.destination?.name} - ${freight.destination?.state.uf}`,
      formatted_suggested_price: parseCurrency(freight.suggested_price),
      formatted_agreed_price: parseCurrency(freight.agreed_price),
      formatted_suggested_price_type: getPriceType(freight.suggested_price_type),
      formatted_vehicle: `${freight.vehicle?.vehicle_category?.name} / ${freight.vehicle?.vehicle_body?.name}`,
      formatted_round_trip: freight.is_round_trip ? 'Sim' : 'Não',
      formatted_has_toll: freight.has_toll ? 'Sim' : 'Não',
      created_at: format(createdAt, 'dd/MM'),
      created_at_hour: format(createdAt, 'HH:mm'),
      cargo_value: parseCurrency(freight.cargo?.value),
      cargo_dimensions: `${freight.cargo?.width} x ${freight.cargo?.height} x ${freight.cargo?.depth}`,
      cargo_weight: `${freight.cargo?.weight} kg`,
      is_cargo_complement: freight.cargo?.has_complement ? 'Sim' : 'Não',
      cargo_require_tracker: freight.cargo?.require_tracker ? 'Sim' : 'Não',
      formatted_origin_coords: {
        lat: String(freight?.origin_coords).split(',')[0],
        lng: String(freight?.origin_coords).split(',')[1],
      },
      formatted_destination_coords: {
        lat: String(freight?.destination_coords).split(',')[0],
        lng: String(freight?.destination_coords).split(',')[1],
      },
    },
    vehicle: {
      ...res.data.vehicle,
      formatted_has_insurance: res.data.vehicle.has_insurance ? 'Sim' : 'Não',
      formatted_has_tracker: res.data.vehicle.has_tracker ? 'Sim' : 'Não',
    },
    created_at: format(new Date(res.data.created_at), 'dd/MM/yy - HH:mm'),
    status: formatStatusRiskAnalysis(res.data.status),
    color_status: colorStatusRiskAnalysis(res.data.status),
  }
}

export function useGetOneRiskAnalysis(id: string): UseQueryResult<RiskAnalysisOneFormatted> {
  return useQuery(['one-risk-analysis', id], () => fetchRiskAnalysis(id), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    refetchOnWindowFocus: 'always',
    retry: false,
  })
}
