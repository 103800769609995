import { useQuery, UseQueryResult } from 'react-query'
import { apiServer } from '../../api'
import { FreightType } from '../../types'

export interface IMotoristFeedback {
  statusCode: number
  data: {
    total: number
    data: [
      {
        id: string
        freight_id: string
        motorist_id: string
        reason: string
        recommendation_score: number
        updated_at: string
        created_at: string
        freight: FreightType
      },
    ]
  }
}
export interface FiltersReportMotoristsFeedbacks {
  initialDate: string
  finalDate: string
  freight_number?: string
  analyst_id?: string
  page?: number
  per_page?: number
  unpaged?: boolean
  onlyTotal?: boolean
}

export async function fetchReportsFeedbacksMotorist({
  initialDate,
  finalDate,
  ...params
}: FiltersReportMotoristsFeedbacks): Promise<IMotoristFeedback> {
  const { data: reports } = await apiServer.get<IMotoristFeedback>(`/list-nps-motorist`, {
    params: {
      initial_date: `${initialDate}T00:00:00`,
      final_date: `${finalDate}T23:59:59`,
      ...params,
    },
  })
  return reports
}

export function useReportsFeedbacksMotorist(
  params: FiltersReportMotoristsFeedbacks,
): UseQueryResult<IMotoristFeedback> {
  return useQuery(['reports-feedbacks-motorist', params], () => fetchReportsFeedbacksMotorist(params), {
    staleTime: 1000 * 60 * 10, // 10 minutes,
    retry: false,
  })
}
