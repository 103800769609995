import { Alert, AlertDescription, AlertIcon, AlertTitle, Button, Flex } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import { InputIsNeedTag, isNeedTag } from '../../services/endpoints/vehicles/toll-tags/toll-tags-autocomplete'

export function AlertIsNeedTag({ freight, vehicle }: InputIsNeedTag): JSX.Element {
  const history = useHistory()
  return (
    <>
      {freight && vehicle && isNeedTag({ vehicle, freight }) && (
        <Alert
          variant="subtle"
          status="warning"
          borderRadius="md"
          justifyContent="space-between"
          flexDirection={['column', 'row']}
          gridGap="2"
        >
          <Flex alignItems="center">
            <AlertIcon />
            <AlertTitle mr={2} fontSize="lg">
              Atenção: A rota exige pagamento de pedágio, e o veículo não possui tag vinculada! Verifique se o
              veículo possui tag e, caso sim, vincule no formulário de veículos.
            </AlertTitle>
          </Flex>
          <AlertDescription>
            <Button
              colorScheme="orange"
              ml="4"
              size="sm"
              onClick={() => {
                history.push(
                  `/freights/show/${freight.id}/pendencies?motorist_id=${freight.motorist_id}&vehicle_id=${vehicle.id}`,
                )
              }}
            >
              Vincular tags
            </Button>
          </AlertDescription>
        </Alert>
      )}
    </>
  )
}
