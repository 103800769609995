import {
  Button,
  Grid,
  GridItem,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'
import { useContext } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { AiOutlinePlus } from 'react-icons/ai'
import { AutocompleteAsync } from '../../../../../components/form'
import { toastError } from '../../../../../config/error/toastError'
import { searchCitiesByName } from '../../../../../services/endpoints/cities/searchCities'
import { RoutePlannerCity } from '../reducer'
import { RoutePlannerContext } from '../ShowRoutePlanner'

type AddStepRoutePlannerModalProps = {
  isOpen: boolean
  onClose: () => void
}

type AddStepRoutePlannerFormData = {
  city: RoutePlannerCity
}

export function AddStepRoutePlannerModal({ isOpen, onClose }: AddStepRoutePlannerModalProps): JSX.Element {
  const toast = useToast()
  const { handleSubmit, setValue } = useForm({})
  const { state, dispatch } = useContext(RoutePlannerContext)

  const handleAddNewStopCity: SubmitHandler<AddStepRoutePlannerFormData> = async data => {
    if (!data.city) {
      toastError({ toast, error: 'Selecione uma cidade' })
      return
    }

    dispatch({ type: 'set_steps', payload: [...state.steps, data.city] })

    toast({
      title: 'Parada adicionada com sucesso!',
      status: 'success',
      position: 'top',
      duration: 3000,
      isClosable: true,
    })

    onClose()
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      onClose={() => {
        onClose()
      }}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Adicionar parada</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <form onSubmit={handleSubmit(handleAddNewStopCity)} noValidate>
            <Grid templateColumns="repeat(12, 1fr)" gap="4" mt="-15px">
              <GridItem colSpan={[12, 4, 12]}>
                <AutocompleteAsync
                  isRequired
                  name="city"
                  placeholder="Selecione uma cidade"
                  loadOptions={searchCitiesByName}
                  setValue={setValue}
                  autoFocus
                />
              </GridItem>

              <GridItem colSpan={[12, 4, 12]} mb={4}>
                <Button colorScheme="blue" w="100%" type="submit">
                  <Icon as={AiOutlinePlus} mr="1" />
                  Adicionar parada
                </Button>
              </GridItem>
            </Grid>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
